import React from 'react';
import { Callout } from '@vp/swan';
import PropTypes from 'prop-types';

const OrderCategoryTag = ({ tagName, isTenant, key }) => (
  <Callout
    backgroundColor={isTenant ? 'light-gray' : 'light-blue'}
    size="mini"
    m={1}
    key={key}
  >
    {tagName.toUpperCase()}
  </Callout>
);

OrderCategoryTag.propTypes = {
  tagName: PropTypes.string.isRequired,
  isTenant: PropTypes.bool,
  key: PropTypes.string.isRequired,
};

export default OrderCategoryTag;
