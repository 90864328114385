import { ENVIRONMENT } from './environment';

export const ORDER_PLACED_ANONYMOUSLY = 'anon';
export const RANGED_QUANTITY = 'ranged quantity';
export const PPAG_UX_MOD = 'ppag ux mod';
export const ITEM_GROUP = 'item group';
export const FAILS_DOC_CONVERSION = 'fails doc conversion';
export const LEGACY_ORDER = 'legacy order';
export const MAILING_SERVICE_FEE = 'mailing service fee';
export const RETIRED_ITEMS = 'retired items';
export const REORDER_DISABLED = 'reorder disabled';
export const DIGITAL_ITEM = 'digital item';
export const MIGRATED = 'migrated';
export const CHECKS = 'checks';
export const SUBSCRIPTION = 'subscription';
export const ZERO_DOLLAR = 'zero dollar';

export const orderCatalogOptions = {
  VPDEV_JV3CLR26: {
    orderNumber: 'VPDEV_JV3CLR26',
    tenant: 'VP-US-DEV',
    environment: ENVIRONMENT.STAGING,
    tags: [ORDER_PLACED_ANONYMOUSLY, DIGITAL_ITEM],
  },
  VPDEV_0P498R7X: {
    orderNumber: 'VPDEV_0P498R7X',
    tenant: 'VP-US-DEV',
    environment: ENVIRONMENT.STAGING,
    tags: [ORDER_PLACED_ANONYMOUSLY],
  },
  VPDEV_6CLNP4TH: {
    orderNumber: 'VPDEV_6CLNP4TH',
    tenant: 'VP-US-DEV',
    environment: ENVIRONMENT.STAGING,
    tags: [RANGED_QUANTITY, ITEM_GROUP],
  },
  VPDEV_381HV2PX: {
    orderNumber: 'VPDEV_381HV2PX',
    tenant: 'VP-US-DEV',
    environment: ENVIRONMENT.STAGING,
    tags: [RANGED_QUANTITY, ITEM_GROUP, PPAG_UX_MOD],
  },
  VPDEV_4633C4K2: {
    orderNumber: 'VPDEV_4633C4K2',
    tenant: 'VP-US-DEV',
    environment: ENVIRONMENT.STAGING,
    tags: [MAILING_SERVICE_FEE],
  },
  VPDEV_JK06B04J: {
    orderNumber: 'VPDEV_JK06B04J',
    tenant: 'VP-US-DEV',
    environment: ENVIRONMENT.STAGING,
    tags: [CHECKS],
  },
  VP_LKP2S1RG: {
    orderNumber: 'VP_LKP2S1RG',
    tenant: 'VP-US-PROD',
    environment: ENVIRONMENT.PRODUCTION,
    tags: [ITEM_GROUP],
  },
  'VQBHP-61A63-2M5': {
    orderNumber: 'VQBHP-61A63-2M5',
    tenant: 'VP-US-PROD',
    environment: ENVIRONMENT.PRODUCTION,
    tags: [RETIRED_ITEMS],
  },
  'BDMMX-V6A26-5E4': {
    orderNumber: 'BDMMX-V6A26-5E4',
    tenant: 'VP-US-PROD',
    environment: ENVIRONMENT.PRODUCTION,
    tags: [REORDER_DISABLED],
  },
  'ZVQRB-D6A92-6Y3': {
    orderNumber: 'ZVQRB-D6A92-6Y3',
    tenant: 'VP-US-PROD',
    environment: ENVIRONMENT.PRODUCTION,
    tags: [MIGRATED, CHECKS],
  },
  'BRR7M-46A60-5S3': {
    orderNumber: 'BRR7M-46A60-5S3',
    tenant: 'VP-US-PROD',
    environment: ENVIRONMENT.PRODUCTION,
    tags: [FAILS_DOC_CONVERSION],
  },
  VP_SG467C2K: {
    orderNumber: 'VP_SG467C2K',
    tenant: 'VP-US-PROD',
    environment: ENVIRONMENT.PRODUCTION,
    tags: [SUBSCRIPTION],
  },
  '0NWXT-M6A30-2O8': {
    orderNumber: '0NWXT-M6A30-2O8',
    tenant: 'VP-US-PROD',
    environment: ENVIRONMENT.PRODUCTION,
    tags: [ZERO_DOLLAR, MIGRATED],
  },
  VP_M88FHWMW: {
    orderNumber: 'VP_M88FHWMW',
    tenant: 'VP-US-PROD',
    environment: ENVIRONMENT.PRODUCTION,
    tags: [ZERO_DOLLAR],
  },
};
