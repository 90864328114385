import React, { useState } from 'react';
import { CSVDownload, CSVLink } from 'react-csv';
import { Button, PopoverLauncher, Popover, PopoverContent } from '@vp/swan';
import PropTypes from 'prop-types';
import { refresh } from '../../../api/backendOrderRemediationToolService';
import partitionResubmittableOrders from '../resubmission/partitionResubmittableOrders';
import lineItemStatesDisplay from './lineItemStatesDisplay';

const getExportData = (data, headers) => {
  const dataToExport = [];
  if (data.length > 0) {
    data.forEach(function(order) {
      const newData = {};
      headers.forEach(function(header) {
        newData[header] = order[header];
      });
      dataToExport.push(newData);
    });
  }
  return dataToExport;
};

const CSVExporter = ({ headers, data, exportFile }) => {
  const dataToExport = getExportData(data, headers);

  return (
    <CSVLink
      headers={headers}
      data={dataToExport}
      filename={exportFile}
      enclosingCharacter=""
    >
      <Button skin="primary">Export Failures</Button>
    </CSVLink>
  );
};

/* Returns formatted array to export results for CSV
 * if an error flag is defined we surface the error on the csv in an 'error' column
 * else we add a column for each the itemStates (from MCP and OMS) and display the item states for an order
 */
const getResultsExportData = (data, headers) => {
  const dataToExport = [];
  if (data.length > 0) {
    data.forEach(function(order) {
      if (order.lineItems) {
        order.lineItems.forEach(function(lineItem) {
          const newData = {};
          headers.forEach(function(header) {
            newData[header] = order[header]
              ? order[header]
              : lineItemStatesDisplay(lineItem[header]);
          });
          if (order.error) {
            newData.error = order.error.message;
          }
          dataToExport.push(newData);
        });
      } else {
        const newData = {};
        headers.forEach(function(header) {
          newData[header] = order[header];
        });
        if (order.error) {
          newData.error = order.error.message;
        }
        dataToExport.push(newData);
      }
    });
  }
  return dataToExport;
};

// helper function to get orders which failed resubmission (orders where resubmittableOrder is true, but an error exists)
function getFailures(orderData) {
  const failures = [];
  const { resubmittable } = partitionResubmittableOrders(orderData);
  for (const order of resubmittable) {
    if (order.error) {
      failures.push(order);
    }
  }
  return failures;
}

/*
 * Display text button to export results and onClick() make refresh call (grab latest data) to BORT
 * Process data from BORT so it can be exported to CSV
 */
export const TransactionDataCSVExporter = ({
  headers,
  transactionId,
  environment,
}) => {
  const [transactionData, setTransactionData] = useState(null);
  const [error, setError] = useState(false);

  async function getTransactionalOrderData() {
    setTransactionData(null);
    setError(false);
    try {
      const data = await refresh(environment, transactionId);
      setTransactionData(getResultsExportData(data.orderData, headers));
    } catch (exception) {
      setError(true);
    }
  }

  return (
    <>
      <Popover position="bottom">
        <PopoverLauncher>
          <Button onClick={() => getTransactionalOrderData()}>
            Export Results
          </Button>
        </PopoverLauncher>
        {error && <PopoverContent>Could not export results</PopoverContent>}
      </Popover>
      {transactionData != null && (
        <CSVDownload
          data={transactionData}
          headers={headers}
          enclosingCharacter=""
        />
      )}
    </>
  );
};

/*
 * Display text button to export failures and onClick() make refresh call (grab latest data) to BORT
 * Process data from BORT so it can be exported to CSV
 */
export const FailedTransactionDataCSVExporter = ({
  headers,
  transactionId,
  environment,
}) => {
  const [transactionData, setTransactionData] = useState(null);
  const [error, setError] = useState(false);

  async function getFailedTransactionalOrderData() {
    setTransactionData(null);
    setError(false);
    try {
      const data = await refresh(environment, transactionId);
      const failures = getFailures(data.orderData);
      setTransactionData(getExportData(failures, headers));
    } catch (exception) {
      setError(true);
    }
  }

  return (
    <>
      <Popover position="bottom">
        <PopoverLauncher>
          <Button onClick={() => getFailedTransactionalOrderData()}>
            Export Failures
          </Button>
        </PopoverLauncher>
        {error && <PopoverContent>Could not export failures</PopoverContent>}
      </Popover>
      {transactionData && (
        <CSVDownload
          data={transactionData}
          headers={headers}
          enclosingCharacter=""
        />
      )}
    </>
  );
};

CSVExporter.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.object),
  exportFile: PropTypes.string,
};

FailedTransactionDataCSVExporter.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string),
  transactionId: PropTypes.string,
  environment: PropTypes.string,
};

TransactionDataCSVExporter.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string),
  transactionId: PropTypes.string,
  environment: PropTypes.string,
};

export default CSVExporter;
