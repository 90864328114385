import React from 'react';
import { Column, Row, GridContainer, AlertBox } from '@vp/swan';
import PropTypes from 'prop-types';
import { ERROR_NO_UNRESOLVED_ORDERS } from '../resubmission/resubmissionErrorTypes';

const IncidentErrorPanel = ({ errorType }) => {
  let errorTitle = 'An unexpected error occurred!';
  if (errorType === ERROR_NO_UNRESOLVED_ORDERS) {
    errorTitle = `There were no unresolved orders in this incident, or the incident has already been resolved`;
  }
  return (
    <GridContainer>
      <Row>
        <Column>
          <AlertBox skin="error">{errorTitle}</AlertBox>
        </Column>
      </Row>
    </GridContainer>
  );
};

IncidentErrorPanel.propTypes = {
  errorType: PropTypes.string,
};

export default IncidentErrorPanel;
