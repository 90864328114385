import React, { useContext, useState, useEffect } from 'react';
import {
  TextInputWithButtonInset,
  TextInput,
  Icon,
  Button,
  FormField,
  FormInputGroup,
  StandardForm,
  FormHelper,
  FormLabel,
  FormError,
} from '@vp/swan';
import { OrderCopyContext } from '../../../../contexts/OrderCopyContext';

const SourceOrderSearch = () => {
  const {
    sourceOrderNumber,
    sourceOrderNumberValid,
    isOrderFetchError,
    updateSourceOrder,
  } = useContext(OrderCopyContext);

  const [orderNumberInput, setOrderNumberInput] = useState(sourceOrderNumber);

  // If the source order number is updated in the context by another component (i.e. catalog),
  // update the text input to mirror that order number
  useEffect(() => {
    setOrderNumberInput(sourceOrderNumber);
  }, [sourceOrderNumber]);

  const handleOnSubmit = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      updateSourceOrder({ newOrderNumber: orderNumberInput });
    }
  };

  const handleOnChange = input => {
    setOrderNumberInput(input);
  };

  const errorMessage = isOrderFetchError
    ? 'Order not found 🫣'
    : 'Order number is required! 🤡';

  return (
    <StandardForm skin="tight">
      <FormField>
        <FormLabel>Source order</FormLabel>
        <FormInputGroup>
          <TextInputWithButtonInset>
            <TextInput
              placeholder="Search for order number"
              value={orderNumberInput}
              onChange={event => {
                handleOnChange(event.target.value.trim());
              }}
              onKeyPress={handleOnSubmit}
              type="search"
              size="mini"
            />
            <Button onClick={handleOnSubmit}>
              <Icon iconType="search" alt="" />
            </Button>
          </TextInputWithButtonInset>
          <FormHelper>
            The source order can originate from either staging or prod
          </FormHelper>
          {(!sourceOrderNumberValid || isOrderFetchError) && (
            <FormError>{errorMessage}</FormError>
          )}
        </FormInputGroup>
      </FormField>
    </StandardForm>
  );
};

export default SourceOrderSearch;
