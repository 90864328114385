import React from 'react';
import { BasicResponsiveImage } from '@vp/swan';
import { PurcsModal } from './PurcsModal';

/*
 * Expected that this is rendered within an LineItemDataProvider to access item properties
 */
export const ItemPreviewDisplay = ({ lineItem }) => {
  const { productName, productKey, purcsData, livePreviewUrl } = lineItem;

  // https://gitlab.com/vistaprint-org/design-technology/scenes/purcs-react#required-parameters
  const usePurcs =
    purcsData &&
    (purcsData.workRevisionId ||
      (purcsData.previewInstructionsUri &&
        purcsData.optionSelections &&
        (purcsData.productKey || purcsData.mpvId || purcsData.mpvUrl)));

  return usePurcs ? (
    <PurcsModal
      purcsData={purcsData}
      productName={productName}
      productKey={productKey}
    />
  ) : (
    livePreviewUrl && (
      <BasicResponsiveImage
        aspectRatio={1}
        src={livePreviewUrl}
        alt="example image"
      />
    )
  );
};
