import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from '@reach/router';
import { GridContainer, PipeDivider, Row, Typography } from '@vp/swan';

import { Image } from 'react-bootstrap';
import EnvDropdown from '../EnvDropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import { IdentityPopover } from '../auth/IdentityPopover';
import Menu from './Menu';

const NavigationBar = () => (
  <GridContainer>
    <Navbar bg="light" expand="lg">
      <Row>
        <Menu />
      </Row>
      <Navbar.Brand>
        <Link to="/">
          <Image
            src="https://cms.cloudinary.vpsvc.com/image/upload/v1652889614/order_management/sort-ui/vistaprintLogo.svg"
            width={120}
            height={50}
          />
        </Link>
      </Navbar.Brand>
      <Navbar.Collapse>
        <PipeDivider />
        <Link to="/">
          <Typography component="p" mt={4} mb={4} ml={4}>
            <Navbar.Text>Self-service Order Remediation Tooling</Navbar.Text>
          </Typography>
        </Link>
      </Navbar.Collapse>
      <Navbar.Collapse className="justify-content-end">
        <Navbar.Text>
          <IdentityPopover />
        </Navbar.Text>
        <PipeDivider />
      </Navbar.Collapse>
      <EnvDropdown />
    </Navbar>
  </GridContainer>
);
export default NavigationBar;
