import React, { useContext } from 'react';
import { Box, SkeletonText } from '@vp/swan';
import { OrderCopyContext } from '../../../../contexts/OrderCopyContext';
import { InfoTypographyWithSkeleton } from '../../../shared/InfoTypography';

const TargetOrderForm = () => {
  const { targetOrderFormFields } = useContext(OrderCopyContext);

  return (
    <Box align="left">
      <SkeletonText>
        {Object.keys(targetOrderFormFields).map(fieldName => {
          const { label, value, isHidden } = targetOrderFormFields[fieldName];
          return (
            <InfoTypographyWithSkeleton
              label={label}
              valueToDisplay={value}
              hideEmptyValue={isHidden}
            />
          );
        })}
      </SkeletonText>
    </Box>
  );
};

export default TargetOrderForm;
