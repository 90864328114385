import { useQuery } from 'react-query';
import { orderDataFetch } from '../api/daliService';
import { ENVIRONMENT } from '../constants/environment';
import { GET_ORDER } from '../constants/reactQueryKeys';

const useOrderFetch = ({ orderNumber, orderEnvironment }) => {
  // If an environment isn't specified, default to production
  let environment = orderEnvironment || ENVIRONMENT.PRODUCTION;

  const {
    isLoading: isOrderFetchLoading,
    isError: isOrderFetchError,
    data: order,
  } = useQuery(
    [GET_ORDER, orderNumber],
    async () => orderDataFetch(environment, orderNumber),
    {
      // If the order wasn't found in production, try fetching it from staging
      retry: (failureCount, error) => {
        if (
          failureCount === 0 &&
          error.response.status === 404 &&
          environment === ENVIRONMENT.PRODUCTION
        ) {
          environment = ENVIRONMENT.STAGING;
          return true;
        }
        return false;
      },
      enabled: !!orderNumber,
    }
  );

  return { isOrderFetchLoading, isOrderFetchError, order };
};

export default useOrderFetch;
