import axios from 'axios';
import qs from 'query-string';
import regeneratorRuntime from 'regenerator-runtime';
import { buildStandardHeaders } from '../utils/serviceHelpers';
import { getAuthorizationHeaderAndShopperId } from '../components/auth/auth';
import { ENVIRONMENT } from '../constants/environment';

const prodBORTBaseUrl =
  process.env.PRODUCTION_BACKEND_ORDER_REMEDIATION_TOOLING_BASE_URL;
const stagingBORTBaseUrl =
  process.env.STAGING_BACKEND_ORDER_REMEDIATION_TOOLING_BASE_URL;

/**
 * Returns an object containing parameters to use for calling the BORT service.
 * In the future, consider adding timeouts as part of the configuration.
 * @param {*} environment The environment to send BORT service requests too.
 */
function fetchBORTConfigForEnvironment(environment) {
  if (!environment) {
    console.error(
      `Unable to derive service config for a null/empty environment, using STAGING environment as sensible fallback.`
    );
    return { baseUrl: stagingBORTBaseUrl };
  }
  switch (environment) {
    case 'vistaprint-production':
      return { baseUrl: prodBORTBaseUrl };
    case 'vistaprint-staging':
      return { baseUrl: stagingBORTBaseUrl };
    default:
      console.warn(
        `Unsupported environment type of ${environment}, using STAGING envirnoment as sensible fallback.`
      );
      return { baseUrl: stagingBORTBaseUrl };
  }
}

function ensureRequestWasSuccessfulOrThrow(response) {
  if (response && response.status === 200) {
    // noice!
  } else {
    throw new Error('request failed');
  }
}

export const healthCheck = async environment => {
  const bortConfig = fetchBORTConfigForEnvironment(environment);
  const axiosInstance = axios.create({
    baseURL: bortConfig.baseUrl,
  });
  return axiosInstance.get(`health`);
};

export const initiateResubmit = async (environment, resubmitRequests) => {
  const bortConfig = fetchBORTConfigForEnvironment(environment);
  const { idToken } = getAuthorizationHeaderAndShopperId();

  const axiosInstance = axios.create({
    headers: buildStandardHeaders(idToken),
    baseURL: bortConfig.baseUrl,
  });

  return axiosInstance
    .post(`resubmit/initiate`, resubmitRequests)
    .then(response => {
      ensureRequestWasSuccessfulOrThrow(response);
      return response.data;
    })
    .catch(err => {
      throw err;
    });
};

export const remediationHistory = environment => {
  const bortConfig = fetchBORTConfigForEnvironment(environment);
  const { idToken } = getAuthorizationHeaderAndShopperId();

  const axiosInstance = axios.create({
    headers: buildStandardHeaders(idToken),
    baseURL: bortConfig.baseUrl,
  });

  return axiosInstance
    .get(`/resubmit/remediationHistory`)
    .then(response => {
      ensureRequestWasSuccessfulOrThrow(response);
      return response.data;
    })
    .catch(err => {
      throw err;
    });
};

export const resubmit = (environment, transactionId) => {
  const bortConfig = fetchBORTConfigForEnvironment(environment);
  const { idToken } = getAuthorizationHeaderAndShopperId();

  const axiosInstance = axios.create({
    headers: buildStandardHeaders(idToken),
    baseURL: bortConfig.baseUrl,
  });

  return axiosInstance
    .post(`/resubmit/${transactionId}`, undefined)
    .then(response => {
      ensureRequestWasSuccessfulOrThrow(response);
      return response.data;
    })
    .catch(err => {
      throw err;
    });
};

export const refresh = (environment, transactionId) => {
  const bortConfig = fetchBORTConfigForEnvironment(environment);
  const { idToken } = getAuthorizationHeaderAndShopperId();

  const axiosInstance = axios.create({
    headers: buildStandardHeaders(idToken),
    baseURL: bortConfig.baseUrl,
  });

  return axiosInstance
    .post(`resubmit/${transactionId}/refresh`, undefined)
    .then(response => {
      ensureRequestWasSuccessfulOrThrow(response);
      return response.data;
    })
    .catch(err => {
      throw err;
    });
};

/**
 * Aggregate the milestones into common ones (mostly email is the only common one)
 * @param milestoneData
 * @returns {[]}
 */
const milestoneAgg = milestoneData => {
  const milestonesToFilter = [];

  const emailMilestone = {
    name: 'EMAIL',
    displayName: 'All Emails',
    iconType: 'EMAIL',
  };
  const emailFilterParams = [];

  milestoneData.forEach(milestone => {
    if (milestone.iconType === 'EMAIL') {
      emailFilterParams.push(milestone.name);
    } else {
      milestone.filterParams = [milestone.name];
      milestonesToFilter.push(milestone);
    }
  });

  emailMilestone.filterParams = emailFilterParams;

  milestonesToFilter.push(emailMilestone);

  return milestonesToFilter;
};

/**
 * Fetch order journey data
 * @param environment
 * @param searchOrderNumber
 * @param milestoneFilters milestones to filter  when searching for just milestones
 * @param searchObjectFilter "justEvents" or "justMilestones"
 * @returns {Promise<AxiosResponse<any>>}
 */
export const orderJourneyFetch = (
  environment,
  searchOrderNumber,
  milestoneFilters,
  searchObjectFilter
) => {
  const bortConfig = fetchBORTConfigForEnvironment(environment);
  const { idToken } = getAuthorizationHeaderAndShopperId();

  const axiosInstance = axios.create({
    headers: buildStandardHeaders(idToken),
    baseURL: bortConfig.baseUrl,
  });

  let url = `/journey/${searchOrderNumber}?`;
  if (searchObjectFilter === 'justEvents') {
    url = `/journey/${searchOrderNumber}/events?`;
  }

  return axiosInstance
    .get(url, {
      params: { milestonesToInclude: milestoneFilters },
      paramsSerializer: params => qs.stringify(params),
    })
    .then(response => {
      ensureRequestWasSuccessfulOrThrow(response);
      return response.data;
    })
    .catch(err => {
      throw err;
    });
};

export const fetchMilestones = environment => {
  const bortConfig = fetchBORTConfigForEnvironment(environment);
  const { idToken } = getAuthorizationHeaderAndShopperId();

  const axiosInstance = axios.create({
    headers: buildStandardHeaders(idToken),
    baseURL: bortConfig.baseUrl,
  });

  return axiosInstance
    .get(`/journey/milestoneTypes`, undefined)
    .then(response => {
      ensureRequestWasSuccessfulOrThrow(response);
      return milestoneAgg(response.data);
    })
    .catch(err => {
      throw err;
    });
};

export const copyOrder = async ({
  sourceOrder,
  targetShopperId,
  targetTenantId,
  targetEmailAddress,
}) => {
  // We will ALWAYS copy orders to the staging environment ONLY!
  const bortConfig = fetchBORTConfigForEnvironment(ENVIRONMENT.STAGING);
  const { idToken } = getAuthorizationHeaderAndShopperId();

  const axiosInstance = axios.create({
    headers: buildStandardHeaders(idToken),
    baseURL: bortConfig.baseUrl,
  });

  const orderCopyRequest = {
    sourceOrder,
    targetShopperId,
    targetTenantId,
    targetEmailAddress,
  };

  return axiosInstance
    .post(`/actions/copy`, orderCopyRequest)
    .then(response => {
      ensureRequestWasSuccessfulOrThrow(response);
      return response.data;
    })
    .catch(err => {
      throw err;
    });
};
