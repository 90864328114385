import get from 'lodash/get';
import first from 'lodash/first';
import dayjs from 'dayjs';

/* ******************************************** *
 * ** ORDERSTATUSES TRANSLATION GROUP KEYS FOR HUMAN READABLE ORDER AND ITEM STATUSES ** *
 * ******************************************** */

export const IN_PROGRESS = 'In Progress';
export const SHIPPED = 'Shipped';
export const CANCELLED = 'Cancelled';
export const FAILED = 'Failed';
export const CANCEL_REQUESTED = 'Cancel Requested';
export const COMPLETED = 'Completed';
export const REJECTED = 'Rejected';

const statusColorMap = {
  [IN_PROGRESS]: 'success',
  [SHIPPED]: 'success',
  [COMPLETED]: 'success',
  [CANCELLED]: 'error',
  [REJECTED]: 'error',
  [FAILED]: 'error',
};

/* ***************************** *
 * ** ORDER STATES TO STATUSES ** *
 * ***************************** */

const OPEN_ORDER = 'Open_order';
const PROCESSING_ORDER = 'Processing_order';
const FAILED_ORDER = 'Failed_order';
const COMPLETED_ORDER = 'Completed';
const CREATED = 'Created';

export const mapOrderStateToStatus = state => {
  const orderStatusMap = {
    [OPEN_ORDER]: IN_PROGRESS,
    [CREATED]: IN_PROGRESS,
    [PROCESSING_ORDER]: IN_PROGRESS,
    [FAILED_ORDER]: COMPLETED,
    [COMPLETED_ORDER]: COMPLETED,
  };
  return get(orderStatusMap, state);
};

/* ***************************** *
 * ** ITEM STATES TO STATUSES ** *
 * ***************************** */

// Shipped State
const ITEM_SHIPPED = 'Shipped';
// In Progress status states
const ITEM_INITIAL = 'Initial';
const ITEM_SENT_TO_MCP = 'SentToMCP';
// Cancel status states
const ITEM_CANCEL_SUCCESS = 'CancelSuccess';
const ITEM_CANCEL_FAILED = 'CancelFailed';
const ITEM_CANCEL_REQUESTED = 'CancelRequested';
// Failed status states
const ITEM_REJECTED = 'Rejected';
const ITEM_FAILED = 'FailedItem';

/**
 * @param {array} states containing state objects in the shape of: { quantity: int, state: string }
 * Return value will always be an array, even if there is only one state.
 */
export const mapItemStatesToStatuses = (states = [], isDeliverable = true) => {
  const itemStatusMap = {
    [ITEM_INITIAL]: IN_PROGRESS,
    [ITEM_SENT_TO_MCP]: IN_PROGRESS,
    [ITEM_CANCEL_REQUESTED]: CANCEL_REQUESTED,
    [ITEM_CANCEL_SUCCESS]: CANCELLED,
    [ITEM_CANCEL_FAILED]: CANCEL_REQUESTED,
    [ITEM_SHIPPED]: SHIPPED,
    [ITEM_REJECTED]: ITEM_REJECTED,
    [ITEM_FAILED]: FAILED,
  };
  // When we have more than one state we want to show each status with quantity affected
  if (states.length > 1) {
    return states.map(({state, quantity}) => {
      let statusTranslationKey = get(itemStatusMap, state);
      if (!isDeliverable && statusTranslationKey === SHIPPED) {
        // Don't display 'Shipped' if non-physical item is fulfilled.
        statusTranslationKey = COMPLETED;
      }
      return {
        statusTranslationKey,
        quantity,
        color: get(statusColorMap, statusTranslationKey),
      };
    });
  }
  // We should be safe to assume we would always have state on items
  // For safety we can fallback on displaying IN_PROGRESS.
  let itemStatusTranslationKey = get(
      itemStatusMap,
      get(first(states), 'state', ITEM_INITIAL)
  );
  if (!isDeliverable && itemStatusTranslationKey === SHIPPED) {
    // Don't display 'Shipped' if non-physical item is fulfilled.
    itemStatusTranslationKey = COMPLETED;
  }
  return [
    {
      statusTranslationKey: itemStatusTranslationKey,
      color: get(statusColorMap, itemStatusTranslationKey),
    },
  ];
};

/**
 * Determines whether an item in this set of states should display its CMRD
 * If any status in the status list argument has the value IN_PROGRESS or SHIPPED, we should display a CMRD.
 * @param {string} cmrd - promised arrival date of the item
 * @param {array} statuses - array of status strings (i.e., the output of `mapItemStatesToStatuses`).
 * @param { boolean } hidePastCMRDs - hides CMRDs that are in the past
 */
export const shouldShowCmrd = (cmrd, itemStatuses = [], hidePastCMRDs) => {
  if (cmrd === null || cmrd === undefined || cmrd === '') {
    return false;
  }
  const today = dayjs();
  const arrivalDate = dayjs(cmrd);

  if (hidePastCMRDs && arrivalDate.isBefore(today)) {
    return false;
  }

  return (
      itemStatuses.filter(
          itemStatus =>
              itemStatus.statusTranslationKey === IN_PROGRESS ||
              itemStatus.statusTranslationKey === SHIPPED
      ).length > 0
  );
};

/**
 * For a given order, determine if *any* item is already shipped
 * @param order
 * @returns {boolean} true if any item is shipped
 */
export const areAnyItemsShipped = order => {
  for (const orderItem of order.lineItems) {
    const lineItemStates = get(orderItem, 'states', []);
    const itemIsShipped =
        lineItemStates.filter(
            lineItemState => lineItemState.state === ITEM_SHIPPED
        ).length > 0;
    if (itemIsShipped) {
      return true;
    }
  }
  return false;
};

/**
 * For a given item, determine if *all* state-quantities are in initial state
 * @param item
 * @returns {boolean} true if any item is shipped
 */
export const isItemInInitial = item =>
    item?.states?.every(lineItemState => lineItemState.state === ITEM_INITIAL);
