import React from 'react';
import {
  Column,
  ControlIcon,
  Dropdown,
  DropdownOption,
  FlexBox,
  PopoverLauncher,
  Popover,
  PopoverContent,
  Typography,
} from '@vp/swan';

const AdvancedLookupDropdown = ({
  label,
  valueArray,
  initialValue,
  setSearchValueFn,
  tooltipText,
  span,
  offset,
}) => (
  <Column span={span} offset={offset}>
    <FlexBox justifyContent="left" mb={1}>
      <Typography weight="bold" mr={2}>
        {label}
      </Typography>
      <Popover position="above">
        <PopoverLauncher>
          <ControlIcon skin="standard" iconType="info" />
        </PopoverLauncher>
        <PopoverContent>{tooltipText}</PopoverContent>
      </Popover>
    </FlexBox>
    <FlexBox justifyContent="left" mb={1}>
      <Dropdown
        value={initialValue}
        sizeVariant="mini"
        fullWidth
        onChange={e => setSearchValueFn(e.target.value)}
      >
        {valueArray.map((pt, index) => (
          <DropdownOption key={index} value={pt}>
            {pt}
          </DropdownOption>
        ))}
      </Dropdown>
    </FlexBox>
  </Column>
);

export default AdvancedLookupDropdown;
