import {
  BasicCollapsible,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableScrollContainer,
} from '@vp/swan';
import React from 'react';
import PropTypes from 'prop-types';
import lineItemStatesDisplay from '../common/lineItemStatesDisplay';

const LineItemResubmitStatus = ({ lineItems }) =>
  lineItems && lineItems.length > 0 ? (
    lineItems.map(item => (
      <BasicCollapsible heading={item.productName} defaultExpanded>
        <TableScrollContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>ItemId</TableCell>
                <TableCell>{item.lineItemId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>VP State</TableCell>
                <TableCell>
                  {lineItemStatesDisplay(item.currentStates)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>MCP State</TableCell>
                <TableCell>{item.currentMcpState}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableScrollContainer>
      </BasicCollapsible>
    ))
  ) : (
    <Typography>Could not find items for this order</Typography>
  );
LineItemResubmitStatus.propTypes = {
  lineItems: PropTypes.array,
};

export default LineItemResubmitStatus;
