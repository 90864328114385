import React from 'react';
import {
  Card,
  GridContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@vp/swan';
import CurrencyDisplay from '../currency/CurrencyDisplay';

/**
 * Uses pricingBreakdownV3 on DALI item to display pricing details.
 * @param {*} order that the item is a part of.
 * @param {*} item being displayed.
 * @returns 
 */
const ItemPricingDetails = ({ order, item }) => {
  const showListPrice =  !order.migrated;
  const locale = order.locale;
  const currency = order.orderPriceSummary.currency;
  const fractionDigits = order.orderPriceSummary.fractionDigits;

  return (
    <GridContainer>
      <Card bordered evenHeight>
        <GridContainer>
          <Table skin="simple">
            <TableHead>
              <TableCell>Tax Status</TableCell>
              {showListPrice && (
                <TableCell>
                  Product
                  <span className="table-text-second-line">List Price</span>
                </TableCell>
              )}
              <TableCell>
                Product
                <span className="table-text-second-line">Discounted Price</span>
              </TableCell>
              {showListPrice && (
                <TableCell>
                  Shipping
                  <span className="table-text-second-line">List Price</span>
                </TableCell>
              )}
              <TableCell>
                Shipping
                <span className="table-text-second-line">Discounted Price</span>
              </TableCell>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell textAlign="center">
                  <Typography>Taxed:</Typography>
                </TableCell>
                {showListPrice && (
                  <TableCell textAlign="center">
                    <CurrencyDisplay
                      centPrice={
                        item.pricingBreakdownV3.totalListPriceCents?.taxed
                      }
                      fractionDigits={fractionDigits}
                      currency={currency}
                      locale={locale}
                    />
                  </TableCell>
                )}
                <TableCell textAlign="center">
                  <CurrencyDisplay
                    centPrice={
                      item.pricingBreakdownV3.totalDiscountedPriceCents?.taxed
                    }
                    fractionDigits={fractionDigits}
                    currency={currency}
                    locale={locale}
                  />
                </TableCell>
                {showListPrice && (
                  <TableCell textAlign="center">
                    <CurrencyDisplay
                      centPrice={
                        item.pricingBreakdownV3.shippingListPriceCents?.taxed
                      }
                      fractionDigits={fractionDigits}
                      currency={currency}
                      locale={locale}
                    />
                  </TableCell>
                )}
                <TableCell textAlign="center">
                  <CurrencyDisplay
                    centPrice={
                      item.pricingBreakdownV3.shippingDiscountedPriceCents?.taxed
                    }
                    fractionDigits={fractionDigits}
                    currency={currency}
                    locale={locale}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell textAlign="center">
                  <Typography>UnTaxed:</Typography>
                </TableCell>
                {showListPrice && (
                  <TableCell>
                    <CurrencyDisplay
                      centPrice={
                        item.pricingBreakdownV3.totalListPriceCents?.untaxed
                      }
                      fractionDigits={fractionDigits}
                      currency={currency}
                      locale={locale}
                    />
                  </TableCell>
                )}
                <TableCell>
                  <CurrencyDisplay
                    centPrice={
                      item.pricingBreakdownV3.totalDiscountedPriceCents?.untaxed
                    }
                    fractionDigits={fractionDigits}
                    currency={currency}
                    locale={locale}
                  />
                </TableCell>
                {showListPrice && (
                  <TableCell>
                    <CurrencyDisplay
                      centPrice={
                        item.pricingBreakdownV3.shippingListPriceCents?.untaxed
                      }
                      fractionDigits={fractionDigits}
                      currency={currency}
                      locale={locale}
                    />
                  </TableCell>
                )}
                <TableCell>
                  <CurrencyDisplay
                    centPrice={
                      item.pricingBreakdownV3.shippingDiscountedPriceCents?.untaxed
                    }
                    fractionDigits={fractionDigits}
                    currency={currency}
                    locale={locale}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </GridContainer>
      </Card>
    </GridContainer>
  );
};

export default ItemPricingDetails;
