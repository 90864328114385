import React from 'react';

import { Column, Row, Typography } from '@vp/swan';
import { RESUBMIT_ORDERS_LIMIT } from './resubmissionErrorTypes';

const TitlePanel = () => (
  <>
    <Row>
      <Column>
        <Typography component="h2" mt={4}>
          Order Resubmission
        </Typography>
      </Column>
    </Row>
    <Row>
      <Column>
        <Typography>
          You can use this tool to upload a list of orders that need to be
          resubmitted for fulfillment, or you can resubmit all the orders
          associated with a failure incident by providing the incident
          identifier.
        </Typography>
      </Column>
    </Row>
    <Row>
      <Column>
        <Typography component="h4" mt={8}>
          How Resubmission Works
        </Typography>
      </Column>
    </Row>
    <Row>
      <Column>
        <Typography mb={5}>
          Upload a CSV file with a list of order numbers and reason for
          resubmission (up to {RESUBMIT_ORDERS_LIMIT} orders to resubmit per
          file) or provide an incident identifier.
        </Typography>
        <Typography mb={3}>
          If you provide a CSV, we will verify that each order exists and is
          valid for resubmission. An order is considered resubmittable if at
          least one line item in the order is in a valid state for resubmission
          ("Initial", "Rejected" or "FailedItem" state). Resubmittable line
          items are the only items from the order which will be resubmitted.{' '}
        </Typography>{' '}
        <Typography mb={3}>
          Following resubmission, the resubmitted orders are split into
          "Successful Resubmit Requests" and "Failed Resubmit Requests". Please
          note that "success" means a resubmit request to our Order Management
          Service succeeded, but does not guarantee successful placement in MCP.
        </Typography>
        <Typography mb={3}>
          If you provide an incident identifier, we will retrieve the order
          numbers associated with the incident for you and provide a transaction
          id that you can use to track the resubmission.{' '}
        </Typography>
      </Column>
    </Row>
  </>
);

export default TitlePanel;
