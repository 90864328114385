import {
  Button,
  FlexBox,
  Box,
  Link,
  ModalDialog,
  ModalDialogBody,
  ModalDialogButtons,
  ModalDialogCloseButton,
  ModalDialogContent,
  ModalDialogFooter,
  ModalDialogNav,
} from '@vp/swan';
import { PurcsPreview, PreviewModes } from '@vp/react-product-preview';
import '@vp/react-product-preview/build/esm/index.css';
import PropTypes from 'prop-types';
import React from 'react';

export const PurcsModal = ({ purcsData, productName, productKey }) => {
  const { locale } = 'en-US';
  const viewLarger = 'View larger';
  const closeMessage = 'Close';
  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const Preview = ({ previewMode }) => (
    <PurcsPreview
      locale={purcsData.locale || locale}
      tenantId={purcsData.tenantId}
      coreProductKey={productKey}
      previewInstructionsUri={purcsData.previewInstructionsUri}
      mpvId={purcsData.mpvId}
      optionSelections={purcsData.optionSelections}
      onClickMethod={openModal}
      previewMode={previewMode}
    />
  );

  Preview.propTypes = {
    previewMode: PropTypes.string,
  };

  return (
    <>
      {/* Product image icon */}
      <Preview previewMode={PreviewModes.Carousel} />
      {/* Zoom link */}
      <FlexBox
        alignItems="center"
        flexDirection="column"
        style={{ textAlign: 'center' }}
      >
        <Link component="button" onClick={() => openModal()} skin="standard">
          {viewLarger}
        </Link>
      </FlexBox>
      {/* Product image modal */}
      <ModalDialog
        isOpen={modalIsOpen}
        onRequestDismiss={() => setModalIsOpen(false)}
        variant="standard"
        bodyWidth="capped"
      >
        <ModalDialogContent style={{ width: '600px' }} aria-label={productName}>
          <ModalDialogNav>
            <ModalDialogCloseButton visuallyHiddenLabel={closeMessage} />
          </ModalDialogNav>
          <ModalDialogBody>
            <Box mt={4} px={5}>
              <Preview previewMode={PreviewModes.Carousel} />
            </Box>
          </ModalDialogBody>
          <ModalDialogFooter>
            <ModalDialogButtons>
              <Button
                sizeVariant="mini"
                widthVariant="full-width"
                skin="primary"
                onClick={() => closeModal()}
              >
                {closeMessage}
              </Button>
            </ModalDialogButtons>
          </ModalDialogFooter>
        </ModalDialogContent>
      </ModalDialog>
    </>
  );
};

PurcsModal.propTypes = {
  purcsData: PropTypes.object.isRequired,
  productName: PropTypes.string.isRequired,
  productKey: PropTypes.string.isRequired,
};
