export default function hasOrderManageArchivePermissions(
  permissions,
  environment
) {
  return permissions && permissions.orderManagement
    ? !!permissions.orderManagement.find(
        p =>
          (p.identifier === environment || p.identifier === '*') &&
          p.permissions.includes('order:manage-archive')
      )
    : false;
}
