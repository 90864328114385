import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { FlexBox, ListItem, Typography } from '@vp/swan';
import { DISPLAY_NAME, DISPLAY_VALUE } from '../itemAccessors';

const UnpricedItemAttribute = ({ attribute }) => {
  const attributeDisplayName = get(attribute, DISPLAY_NAME);
  const attributeDisplayValue = get(attribute, DISPLAY_VALUE);

  // if there is no display name, dont even bother showing the unpriced value
  return (
    attributeDisplayName && (
      <ListItem>
        <FlexBox justifyContent="space-between">
          <Typography component="label" textColor="dark-grey">
            <div>
              {attributeDisplayName}: {attributeDisplayValue}
            </div>
          </Typography>
          <Typography component="span">Included</Typography>
        </FlexBox>
      </ListItem>
    )
  );
};

UnpricedItemAttribute.propTypes = {
  attribute: PropTypes.object,
};

export default UnpricedItemAttribute;
