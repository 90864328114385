import React, { useContext, useEffect, useState } from 'react';
import { Spinner, FlexBox } from '@vp/swan';
import useIdentityContext from '../../hooks/useIdentityContext';
import { EnvironmentContext } from '../../contexts/EnvironmentContext';
import hasResubmitPermissions from '../auth/permissions/hasResubmitPermissions';
import MissingPermissions from '../auth/permissions/MissingPermissions';
import hasReadOrderPermissions from '../auth/permissions/hasReadOrderPermissions';
import hasIncidentReadPermissions from '../auth/permissions/hasIncidentReadPermissions';
import hasOrderManageArchivePermissions from '../auth/permissions/hasOrderManageArchivePermissions';

const PrivateRoute = ({ component: Component, orderNumber, environment }) => {
  const { environment: contextEnvironment, setEnvironment } = useContext(
    EnvironmentContext
  );
  const { isIdentityInitialized, identity, auth } = useIdentityContext();
  const { isSignedIn, permissions } = identity;

  // flag so environment is only set ONCE if passed as a param
  const [environmentSet, setEnvironmentSet] = useState(false);

  // Effect to set environment if passed as param
  useEffect(() => {
    if (environment && !environmentSet) {
      setEnvironmentSet(true);
      setEnvironment(environment);
    } else if (!environment && !environmentSet) {
      setEnvironmentSet(true);
      setEnvironment(contextEnvironment);
    }
  }, [
    contextEnvironment,
    environment,
    environmentSet,
    setEnvironment,
    setEnvironmentSet,
  ]);

  // include orderNumber as props if passed as param
  const returnVal = orderNumber ? (
    <Component orderNumber={orderNumber} />
  ) : (
    <Component />
  );

  // While auth and permissions are loading, show a spinner
  if (!isIdentityInitialized) {
    return (
      <FlexBox width="100%" alignItems="center" justifyContent="center" my={9}>
        <Spinner size="super" />
      </FlexBox>
    );
  }

  // If the user is not signed in, sign them in
  if (isIdentityInitialized && !isSignedIn) {
    auth.signIn();
  }

  // If the user is signed in, display the page they are requesting
  switch (Component.name) {
    case 'ResubmissionMain':
    case 'OrderResubmissionHome':
      if (!hasResubmitPermissions(permissions, environment)) {
        return <MissingPermissions permissionName="resubmit:order" />;
      }
      break;
    case 'IncidentResubmissionHome':
      if (!hasIncidentReadPermissions(permissions, environment)) {
        return (
          <MissingPermissions permissionName="read:order-remediation:incidents" />
        );
      }
      if (!hasResubmitPermissions(permissions, environment)) {
        return <MissingPermissions permissionName="resubmit:order" />;
      }
      break;
    case 'OrderLookupHome':
    case 'OrderTimelineHome':
    case 'OrderConsole':
    case 'AgentVoyager':
      if (!hasReadOrderPermissions(permissions, environment)) {
        return <MissingPermissions permissionName="read:order" />;
      }
      break;
    case 'OrderCopy':
      if (!hasOrderManageArchivePermissions(permissions, environment)) {
        return <MissingPermissions permissionName="order:manage-archive" />;
      }
      break;
    default:
      return returnVal;
  }
  return returnVal;
};

export default PrivateRoute;
