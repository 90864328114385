import { Box, Typography } from '@vp/swan';
import React from 'react';
import { Image } from 'react-bootstrap';
import OrderModal from './OrderModal';

const GroupTemplate = ({ group }) => (
  <Box align="center" ml={5} mr={5} mt={5}>
    {group.milestoneGroupType === 'ORDER' && <OrderModal order={group.order} />}
    {group.livePreviewUrl && (
      <>
        <Image src={group.livePreviewUrl} height={125} />
        <Typography>{group.content}</Typography>
      </>
    )}
  </Box>
);

export default GroupTemplate;
