import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { FlexBox, ListItem, Typography } from '@vp/swan';
import {
  BASE_PRICE_NAME,
  DISCOUNTED_PRICE,
  DISPLAY_NAME,
  DISPLAY_VALUE,
  LIST_PRICE,
  NAME,
  TAXED,
  UNTAXED,
  VALUE,
} from '../itemAccessors';
import CurrencyWrapper from '../../currency/CurrencyWrapper';
import { currencyLocaleFormatter } from '../../currency/currencyLocaleFormatter';
import { OrderConsoleContext } from '../../../../../contexts/OrderConsoleContext';

/*
 * For non-US tenants, item pricing uses the taxed price to display the gross of attributes and the total.
 * For US tenants, item pricing uses the untaxed price to display the net of attributes and the total.
 * Order level is tax inclusive which allows for an easy display of the difference between net and gross.
 * This may change in the future and item totals may be taxed while attributes are shown untaxed.
 * 'pricingBreakdown' is for a new platform ("V3") item and includes attribute pricing.
 * 'migratedPricing' is for a legacy item and has minimal pricing details.
 * Callers typically will pass only one of the arguments.
 * For a migrated order, we do not try to show cross out pricing. This is because we are missing a taxed list price
 * for a majority of migrated items.  For migrated items, we just default to always showing the discountedPrice alone
 */
const PricedItemAttribute = ({ attribute, lineItem }) => {
  const { order, includeTax } = useContext(OrderConsoleContext);
  const { locale } = order;

  const listPriceFieldName = includeTax
    ? `${LIST_PRICE}.${TAXED}`
    : `${LIST_PRICE}.${UNTAXED}`;
  const discountedPriceFieldName = includeTax
    ? `${DISCOUNTED_PRICE}.${TAXED}`
    : `${DISCOUNTED_PRICE}.${UNTAXED}`;
  const listPrice = get(attribute, listPriceFieldName);
  const discountedPrice = get(attribute, discountedPriceFieldName);

  const attributeDisplayKey = get(attribute, DISPLAY_NAME);
  const attributeDisplayValue = get(attribute, DISPLAY_VALUE);
  const attributeName = get(attribute, NAME);
  const attributeValue = get(attribute, VALUE);

  const attributeDisplayName =
    attributeDisplayKey && attributeDisplayValue
      ? `${attributeDisplayKey}: ${attributeDisplayValue}`
      : `${attributeName}: ${attributeValue}`;

  const displayName =
    attributeName === BASE_PRICE_NAME ? 'Base Price' : attributeDisplayName;

  const { totalListPrice } = lineItem;
  const { fractionDigits } = totalListPrice;
  const currency = totalListPrice && totalListPrice.currencyCode;
  return (
    discountedPrice && (
      <ListItem>
        <FlexBox justifyContent="space-between" my={1}>
          <Typography textColor="dark-grey">{displayName}</Typography>
          <Typography weight="bold">
            <CurrencyWrapper
              fractionDigits={fractionDigits}
              typeSize={5}
              listCents={listPrice}
              discountCents={discountedPrice}
              currencyCode={currency}
              locale={currencyLocaleFormatter(locale)}
            />
          </Typography>
        </FlexBox>
      </ListItem>
    )
  );
};

PricedItemAttribute.propTypes = {
  attribute: PropTypes.object,
};

export default PricedItemAttribute;
