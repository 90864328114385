import {
  DROPDOWN_FIELD,
  INPUT_FIELD,
  TARGET_EMAIL_ADDRESS,
  TARGET_SHOPPER_ID,
  TARGET_TENANT_ID,
} from './orderCopyTargetFormFields';

export const defaultTargetOrderFormFieldConfigs = {
  [TARGET_SHOPPER_ID]: {
    label: 'Target shopper ID',
    value: '',
    isValid: true,
    type: INPUT_FIELD,
    isRequired: true,
    isHidden: false,
  },
  [TARGET_TENANT_ID]: {
    label: 'Target tenant ID',
    value: '',
    isValid: true,
    type: DROPDOWN_FIELD,
    options: [],
    isRequired: true,
    isHidden: false,
    helperText:
      'The copied order will be persisted in staging, regardless of its origin',
  },
  [TARGET_EMAIL_ADDRESS]: {
    label: 'Target email address',
    value: '',
    isValid: true,
    type: INPUT_FIELD,
    isRequired: false,
    isHidden: true,
  },
};
