/* eslint-disable no-unused-vars */
import React from 'react';

export const OrderConsoleContext = React.createContext({
  order: undefined,
  setOrder: order => {},
  includeTax: true,
  setIncludeTax: includeTax => {},
  incidents: undefined,
  setIncidents: incidents => {},
  failures: undefined,
  setFailures: failures => {},
  stateChangeEvents: undefined,
  setStateChangeEvents: stateChangeEvents => {},
});
