import Navbar from 'react-bootstrap/Navbar';
import React from 'react';
import { Link } from '@vp/swan';

const Footer = () => (
  <Navbar
    className="justify-content-end"
    bg="light"
    expand="lg"
    sticky="bottom"
    style={{
      left: 0,
      bottom: 0,
      right: 0,
    }}
  >
    <Navbar.Text>
      <Link
        href="https://ordermanagement.ideas.aha.io/"
        target="_blank"
        skin="cta"
      >
        Send Feedback
      </Link>
    </Navbar.Text>
  </Navbar>
);
export default Footer;
