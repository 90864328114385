import React from 'react';
import {
  BasicPreloader,
  BoundedContent,
  Column,
  Divider,
  GridContainer,
  Row,
  SelectionSet,
  SelectionSetInput,
  SelectionSetLabel,
  SecondaryTile,
  SecondaryTileImage,
  SecondaryTileContents,
  SecondaryTileName,
  SecondaryTileDescription,
  Typography,
  BasicResponsiveImage,
} from '@vp/swan';
import { Link as RouterLink } from '@reach/router';
import useIdentityContext from '../hooks/useIdentityContext';

const Main = () => {
  const { identity } = useIdentityContext();
  const { isSignedIn } = identity;
  const HISTORY =
    'https://cms.cloudinary.vpsvc.com/image/upload/v1652366518/order_management/sort-ui/history.jpg';
  const SEARCH =
    'https://cms.cloudinary.vpsvc.com/image/upload/v1652366512/order_management/sort-ui/search.jpg';
  const VOYAGER =
    'https://cms.cloudinary.vpsvc.com/image/upload/v1652366512/order_management/sort-ui/sailboat.jpg';
  const RESUBMIT =
    'https://cms.cloudinary.vpsvc.com/image/upload/v1652366512/order_management/sort-ui/deliver.jpg';
  const COPY =
    'https://ih1.redbubble.net/image.596243530.6581/st,small,507x507-pad,600x600,f8f8f8.u1.jpg';

  return isSignedIn ? (
    <BoundedContent>
      <GridContainer>
        <Row mt={4}>
          <Typography textSize={4} weight="bold">
            Welcome to SORT, where we provide answers to your order questions!
          </Typography>
        </Row>
        <Divider mt={4} mb={4} />
        <Row>
          <Column span={4}>
            <Row mt={4}>
              <Typography textSize={4} weight="bold">
                Looking for Order information?
              </Typography>
            </Row>
            <Divider mt={4} mb={4} />
            <SelectionSet>
              <RouterLink
                to="/orderLookup"
                style={{ width: '75%', textDecoration: 'none' }}
              >
                <SelectionSetInput value="Order Search">
                  <SelectionSetLabel>
                    <SecondaryTile layout="horizontal">
                      <SecondaryTileImage>
                        <BasicResponsiveImage aspectRatio={1} src={SEARCH} />
                      </SecondaryTileImage>
                      <SecondaryTileContents>
                        <SecondaryTileName>Order Lookup</SecondaryTileName>
                        <SecondaryTileDescription>
                          Find an order using our search form
                        </SecondaryTileDescription>
                      </SecondaryTileContents>
                    </SecondaryTile>
                  </SelectionSetLabel>
                </SelectionSetInput>
              </RouterLink>
              <RouterLink
                to="/orderVoyager"
                style={{ width: '75%', textDecoration: 'none' }}
              >
                <SelectionSetInput value="Voyager">
                  <SelectionSetLabel>
                    <SecondaryTile layout="horizontal">
                      <SecondaryTileImage>
                        <BasicResponsiveImage aspectRatio={1} src={VOYAGER} />
                      </SecondaryTileImage>
                      <SecondaryTileContents>
                        <SecondaryTileName>Voyager</SecondaryTileName>
                        <SecondaryTileDescription>
                          Visualize an order's voyage via its events
                        </SecondaryTileDescription>
                      </SecondaryTileContents>
                    </SecondaryTile>
                  </SelectionSetLabel>
                </SelectionSetInput>
              </RouterLink>
            </SelectionSet>
          </Column>
          <Column span={4}>
            <Row mt={4}>
              <Typography textSize={4} weight="bold">
                Looking for Order resubmission tooling?
              </Typography>
            </Row>
            <Divider mt={4} mb={4} />
            <SelectionSet>
              <RouterLink
                to="/orderResubmissionHome"
                style={{ width: '75%', textDecoration: 'none' }}
              >
                <SelectionSetInput value="Resubmission">
                  <SelectionSetLabel>
                    <SecondaryTile layout="horizontal">
                      <SecondaryTileImage>
                        <BasicResponsiveImage aspectRatio={1} src={RESUBMIT} />
                      </SecondaryTileImage>
                      <SecondaryTileContents>
                        <SecondaryTileName>
                          Resubmission Dashboard
                        </SecondaryTileName>
                        <SecondaryTileDescription>
                          Resubmit one or more orders for fulfillment
                        </SecondaryTileDescription>
                      </SecondaryTileContents>
                    </SecondaryTile>
                  </SelectionSetLabel>
                </SelectionSetInput>
              </RouterLink>
              <RouterLink
                to="/remediationHistory"
                style={{ width: '75%', textDecoration: 'none' }}
              >
                <SelectionSetInput value="History">
                  <SelectionSetLabel>
                    <SecondaryTile layout="horizontal">
                      <SecondaryTileImage>
                        <BasicResponsiveImage aspectRatio={1} src={HISTORY} />
                      </SecondaryTileImage>
                      <SecondaryTileContents>
                        <SecondaryTileName>
                          Resubmission History
                        </SecondaryTileName>
                        <SecondaryTileDescription>
                          Show the state of all remediation requests
                        </SecondaryTileDescription>
                      </SecondaryTileContents>
                    </SecondaryTile>
                  </SelectionSetLabel>
                </SelectionSetInput>
              </RouterLink>
            </SelectionSet>
          </Column>
          <Column span={4}>
            <Row mt={4}>
              <Typography textSize={4} weight="bold">
                Looking to copy an order?
              </Typography>
            </Row>
            <Divider mt={4} mb={4} />
            <SelectionSet>
              <RouterLink
                to="/orderCopy"
                style={{ width: '75%', textDecoration: 'none' }}
              >
                <SelectionSetInput value="Order Copy">
                  <SelectionSetLabel>
                    <SecondaryTile layout="horizontal">
                      <SecondaryTileImage>
                        <BasicResponsiveImage aspectRatio={1} src={COPY} />
                      </SecondaryTileImage>
                      <SecondaryTileContents>
                        <SecondaryTileName>Order Copy</SecondaryTileName>
                        <SecondaryTileDescription>
                          Copy an order to a staging account
                        </SecondaryTileDescription>
                      </SecondaryTileContents>
                    </SecondaryTile>
                  </SelectionSetLabel>
                </SelectionSetInput>
              </RouterLink>
            </SelectionSet>
          </Column>
        </Row>
      </GridContainer>
    </BoundedContent>
  ) : (
    <BasicPreloader sizeVariant="large" centered my={6} />
  );
};

export default Main;
