import {
  BasicCollapsible,
  BasicPreloader,
  Column,
  FormField,
  FormLabel,
  Row,
  SelectionSet,
  SelectionSetInput,
  SelectionSetLabel,
} from '@vp/swan';
import { Image } from 'react-bootstrap';
import React, { useContext } from 'react';
import { OrderTimelineContext } from '../../../contexts/OrderTimelineContext';

const ItemFilter = ({ timelineItems }) => {
  const MAX_ITEMS_TO_DISPLAY = 5;
  const {
    isLoading,
    selectedTimelineItems,
    setSelectedTimelineItems,
  } = useContext(OrderTimelineContext);

  return (
    <>
      {timelineItems.length > 0 && (
        <Row component={FormField} mb={3}>
          <Column span={3}>
            <FormLabel>Timeline Items to filter:</FormLabel>
          </Column>
          <Column span={9}>
            <SelectionSet
              variant="multi-select"
              skin="buttons-with-images"
              selectedValues={selectedTimelineItems}
              onSelectedValuesChange={newSelectedValue => {
                setSelectedTimelineItems(newSelectedValue);
              }}
            >
              <BasicCollapsible
                heading="Timeline Items"
                defaultExpanded={timelineItems.length <= MAX_ITEMS_TO_DISPLAY}
              >
                {!isLoading ? (
                  <>
                    {timelineItems.map(timelineItem => (
                      <SelectionSetInput value={timelineItem.id}>
                        <SelectionSetLabel>
                          {timelineItem.milestoneGroup === 'ORDER' ? (
                            <>
                              <Image src="/order.png" height={50} />
                              {timelineItem.content}
                            </>
                          ) : (
                            <>
                              <Image
                                src={timelineItem.livePreviewUrl}
                                height={50}
                              />
                              {timelineItem.content}
                            </>
                          )}
                        </SelectionSetLabel>
                      </SelectionSetInput>
                    ))}
                  </>
                ) : (
                  <BasicPreloader sizeVariant="large" centered my={6} />
                )}
              </BasicCollapsible>
            </SelectionSet>
          </Column>
        </Row>
      )}
    </>
  );
};

export default ItemFilter;
