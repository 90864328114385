import React, { useContext, useState } from 'react';
import {
  Card,
  GridContainer,
  Link,
  ModalDialog,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@vp/swan';
import IncidentModal from '../cia/IncidentModal';
import { OrderConsoleContext } from '../../../contexts/OrderConsoleContext';

const IncidentsDisplayCard = ({ lineItemId }) => {
  const { order, incidents, failures } = useContext(OrderConsoleContext);
  const { orderNumber } = order;

  const [isOpen, setIsOpen] = useState(false);

  const displayLineItemId = !lineItemId;
  const incidentsExist =
    incidents &&
    incidents.some(incident =>
      incident.itemFailures.some(
        failure =>
          orderNumber === failure.orderNumber &&
          (!lineItemId || lineItemId === failure.lineItemId)
      )
    );

  const failureDetailsExist =
      failures &&
      failures
          .some(f => f.lineItemId === lineItemId && f.itemInfo?.itemProperties?.failureDetails);

  return incidentsExist ? (
    <GridContainer>
      <Card bordered evenHeight>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Incident (click to open incident resubmission)
              </TableCell>
              <TableCell>Failure Type</TableCell>
              {displayLineItemId && <TableCell>Line Item</TableCell>}
              <TableCell>Assignee</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell>Resolution Steps</TableCell>
              {failureDetailsExist && (
                  <TableCell>Failure Details</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {incidents.map(incident =>
              incident.itemFailures
                .filter(
                  failure =>
                    orderNumber === failure.orderNumber &&
                    (!lineItemId || lineItemId === failure.lineItemId)
                )
                .map(failure => (
                  <>
                    <TableRow>
                      <TableCell onClick={() => setIsOpen(true)}>
                        <Typography textSize={7}>
                          <Link>{incident.type}</Link>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography textSize={7}>{failure.type}</Typography>
                      </TableCell>
                      {displayLineItemId && (
                        <TableCell>
                          <Typography textSize={7}>
                            {failure.lineItemId}
                          </Typography>
                        </TableCell>
                      )}
                      <TableCell>
                        <Typography textSize={7}>
                          {incident.assignee.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography textSize={7}>
                          {incident.assignee.emailAddress}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography textSize={7}>
                          {failures
                            .filter(f => f.id === failure.itemFailureId)
                            .map(f => f.remediationSteps)}
                        </Typography>
                      </TableCell>
                      {failureDetailsExist && (
                          <TableCell>
                            <Typography textSize={7}>
                              {failures
                                  .filter(f => f.id === failure.itemFailureId)
                                  .map(f => JSON.stringify(f.itemInfo.itemProperties.failureDetails))}
                            </Typography>
                          </TableCell>
                      )}
                    </TableRow>
                    <ModalDialog
                      translucent
                      takeOver
                      isOpen={isOpen}
                      onRequestDismiss={() => {
                        setIsOpen(false);
                      }}
                    >
                      <IncidentModal incident={incident} />
                    </ModalDialog>
                  </>
                ))
            )}
          </TableBody>
        </Table>
      </Card>
    </GridContainer>
  ) : (
    <GridContainer>
      <Typography textSize={6}>No active failures</Typography>
    </GridContainer>
  );
};

export default IncidentsDisplayCard;
