/*
 * Takes in an array of orders and splits orders into resubmittable and notResubmittable
 * Resubmittable flag means OMS fetch succeeded (order exists) and at least one item in the order is associated with a valid resubmittable state
 * Returns an object with both lists as properties
 */

const partitionResubmittableOrders = orders => {
  const resubmittable = orders.filter(function(order) {
    return (
      order && order.resubmittableOrder && order.resubmittableOrder === true
    );
  });

  const notResubmittable = orders.filter(function(order) {
    return !order.resubmittableOrder || order.resubmittableOrder === false;
  });
  return { resubmittable, notResubmittable };
};

export default partitionResubmittableOrders;
