import React from 'react';
import { Helmet } from 'react-helmet';

const UILibrary = () => (
  <Helmet defer={false}>
    {/* The UI Library default setup https://ui-library.cdn.vpsvc.com/viewer.html#setup */}
    <meta name="viewport" content="initial-scale=1" />
    <meta name="format-detection" content="telephone=no" />

    <link
      rel="icon"
      href="https://ui-library.cdn.vpsvc.com/images/favicon/favicon.ico"
    />
    <link
      rel="apple-touch-icon"
      sizes="152x152"
      href="https://ui-library.cdn.vpsvc.com/images/favicon/vistaprint-favorites-76-76-2014-2x.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="120x120"
      href="https://ui-library.cdn.vpsvc.com/images/favicon/vistaprint-favorites-60-60-2014-2x.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="76x76"
      href="https://ui-library.cdn.vpsvc.com/images/favicon/vistaprint-favorites-76-76-2014.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="60x60"
      href="https://ui-library.cdn.vpsvc.com/images/favicon/vistaprint-favorites-60-60-2014.png"
    />

    <meta name="theme-color" content="#006196" />
  </Helmet>
);

export default UILibrary;
