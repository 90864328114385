import React, { useContext, useState } from 'react';
import {
  Card,
  Column,
  ControlIcon,
  Divider,
  GridContainer,
  Link,
  Row,
  TabContent,
  TabHeader,
  Tabs,
  TabsContents,
  TabsHeaders,
  ToggleSwitch,
  ToggleSwitchOffLabel,
  ToggleSwitchOnLabel,
  Typography,
} from '@vp/swan';
import { ItemPreviewDisplay } from '../../../shared/lineItemDisplay/ItemPreviewDisplay';
import LineItemStatus from '../../../shared/lineItemDisplay/LineItemStatus';
import { mapItemStatesToStatuses } from '../../../shared/lineItemDisplay/mapStateToStatus';
import { InfoTypography } from '../../../shared/InfoTypography';
import IncidentsDisplayCard from '../IncidentsDisplayCard';
import { OrderConsoleContext } from '../../../../contexts/OrderConsoleContext';
import { ItemTotalSummary } from './ItemTotalSummary';
import { ItemAttributeDetails } from './attribute/ItemAttributeDetails';
import { FulfillmentHolds } from './FulfillmentHolds';
import ItemStatusChart from './ItemStatusChart';
import ItemPricingDetails from './ItemPricingDetails';
import { EnvironmentContext } from '../../../../contexts/EnvironmentContext';

const LineItemDisplayCard = ({ lineItem }) => {
  const { includeTax, setIncludeTax, order, incidents } = useContext(
    OrderConsoleContext
  );
  const [isDetailsShown, setDetailsShown] = useState(false);

  const { orderNumber } = order;
  const {
    states: lineItemStates,
    isDeliverable,
    productName,
    quantity,
    id,
    trackingData,
  } = lineItem;

  const { environment } = useContext(EnvironmentContext);

  const isReordered = !!lineItem?.customOrderData?.analytics?.is_reordered;
  const originalOrderId =
    lineItem?.customOrderData?.analytics?.original_order_id;
  const lineItemStatuses = mapItemStatesToStatuses(
    lineItemStates,
    isDeliverable
  );

  const pricingData = (
    <GridContainer>
      <Card evenHeight bordered>
        <Row>
          <Column span={12}>
            <ToggleSwitch
              disabled={order.migrated}
              activated={includeTax}
              aria-labelledby="includeTaxToggle"
              onRequestActivatedChange={activated => setIncludeTax(activated)}
            >
              <ToggleSwitchOnLabel>Inc. Tax</ToggleSwitchOnLabel>
              <ToggleSwitchOffLabel>Ex. Tax</ToggleSwitchOffLabel>
            </ToggleSwitch>
          </Column>
          <Divider />
        </Row>
        <Row>
          <Column span={12}>
            <ItemAttributeDetails lineItem={lineItem} />
            <Divider />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <ItemTotalSummary lineItem={lineItem} />
          </Column>
        </Row>
      </Card>

      {/** For aria labeling of toggle switch: */}
      <Typography id="showHidePricingDetails" visuallyHidden>
        Item Pricing Details
      </Typography>

      <ToggleSwitch
        activated={isDetailsShown}
        sizeVariant="mini"
        aria-labelledby="showHidePricingDetails"
        onRequestActivatedChange={activated => {
          setDetailsShown(!isDetailsShown);
        }}
      >
        <ToggleSwitchOnLabel>Hide Item Pricing Details</ToggleSwitchOnLabel>
        <ToggleSwitchOffLabel>Show Item Pricing Details</ToggleSwitchOffLabel>
      </ToggleSwitch>
      {isDetailsShown && (
        <Card evenHeight bordered>
          <GridContainer cardDividers>
            <ItemPricingDetails order={order} item={lineItem} />
          </GridContainer>
        </Card>
      )}
    </GridContainer>
  );

  // Line Item States
  const itemStateData = (
    <GridContainer>
      <Card evenHeight bordered>
        <GridContainer>
          <ItemStatusChart lineItem={lineItem} />
          <Row>
            {trackingData &&
              trackingData.map(tracking => (
                <Column span={3}>
                  <InfoTypography
                    label="Track"
                    valueToDisplay={
                      <Link href={tracking.trackingUrl} target="_blank">
                        {tracking.trackingId}
                      </Link>
                    }
                  />
                </Column>
              ))}
          </Row>
        </GridContainer>
      </Card>
    </GridContainer>
  );

  const fulfillmentData = (
    <GridContainer>
      <Card evenHeight bordered>
        <GridContainer cardDividers>
          <InfoTypography
            label="Promised Arrival"
            valueToDisplay={lineItem.promisedArrivalDate}
          />
          <InfoTypography
            label="Is Deliverable"
            valueToDisplay={lineItem.isDeliverable ? 'True' : 'False'}
          />
          <InfoTypography
            label="Fulfillment Sku"
            valueToDisplay={lineItem.fulfillmentSku}
          />
          <InfoTypography
            label="Product Config Url"
            valueToDisplay={lineItem.mcpProductConfigUrl}
          />
        </GridContainer>
      </Card>
    </GridContainer>
  );

  const productAndDesignData = (
    <GridContainer>
      <Card evenHeight bordered>
        <GridContainer cardDividers>
          <Row>
            <Column span={6}>
              <Typography weight="bold">Product</Typography>
              <InfoTypography
                label="Product Key"
                valueToDisplay={lineItem.productKey}
              />
              <InfoTypography
                label="Product Version"
                valueToDisplay={lineItem.productVersion}
              />
              <InfoTypography
                label="Legacy Alt Doc Id"
                valueToDisplay={lineItem.altDocId}
              />
            </Column>
            <Column span={6}>
              <Typography weight="bold">Design</Typography>
              <InfoTypography
                label="docRefUrl"
                valueToDisplay={
                  lineItem.documentUrl ? (
                    <Link href={lineItem.documentUrl} target="_blank">
                      {lineItem.documentUrl}
                    </Link>
                  ) : (
                    undefined
                  )
                }
              />
              <InfoTypography
                label="editDocumentUrl"
                valueToDisplay={lineItem.editDocumentUrl}
              />
            </Column>
          </Row>
        </GridContainer>
      </Card>
    </GridContainer>
  );

  const reorderedItem = orderId => (
    <Typography>
      <Link
        skin="cta"
        target="_blank"
        href={`/orderConsole/${environment}/${orderId}`}
      >
        View original order
      </Link>
    </Typography>
  );

  // only show holds tab if order has (or had) holds
  const fulfillmentHoldsExist =
    lineItem.fulfillmentHolds?.length > 0 ||
    lineItem.fulfillmentHoldsV2?.length > 0;

  const incidentsExist =
    incidents &&
    incidents.some(incident =>
      incident.itemFailures.some(
        failure =>
          orderNumber === failure.orderNumber && id === failure.lineItemId
      )
    );

  return (
    <Card evenHeight bordered>
      <Row>
        <Column span={2} spanXs={4} pr={6}>
          <ItemPreviewDisplay lineItem={lineItem} />
        </Column>
        <Column span={2}>
          <Typography weight="bold" textSize={4}>
            {productName}
          </Typography>
          <InfoTypography label="Id" valueToDisplay={id} />
          <InfoTypography label="Quantity" valueToDisplay={quantity} />
          <InfoTypography
            label="Reordered item"
            valueToDisplay={isReordered ? 'True' : 'False'}
          />
          {isReordered ? reorderedItem(originalOrderId) : null}
          <LineItemStatus statuses={lineItemStatuses} />
        </Column>
        <Column span={8}>
          <Tabs defaultSelectedTabId="pricing">
            <TabsHeaders>
              <TabHeader tabId="states&Tracking">States & Tracking</TabHeader>
              <TabHeader tabId="pricing">Pricing</TabHeader>
              <TabHeader tabId="fulfillment">Fulfillment</TabHeader>
              <TabHeader tabId="product&Design">Product & Design</TabHeader>
              {fulfillmentHoldsExist && (
                <TabHeader tabId="holds">Holds</TabHeader>
              )}
              {incidentsExist && (
                <TabHeader tabId="ciaIncidents">
                  <Typography textColor="error">
                    CIA Incidents
                    <ControlIcon iconType="error" />
                  </Typography>
                </TabHeader>
              )}
            </TabsHeaders>
            {/* TODO - implement a bunch of these */}
            <TabsContents>
              <TabContent tabId="states&Tracking">{itemStateData}</TabContent>
              <TabContent tabId="pricing">{pricingData}</TabContent>
              <TabContent tabId="fulfillment">{fulfillmentData}</TabContent>
              <TabContent tabId="product&Design">
                {productAndDesignData}
              </TabContent>
              <TabContent tabId="holds">
                <FulfillmentHolds lineItem={lineItem} />
              </TabContent>
              <TabContent tabId="ciaIncidents">
                <IncidentsDisplayCard lineItemId={id} />
              </TabContent>
            </TabsContents>
          </Tabs>
        </Column>
      </Row>
    </Card>
  );
};

export default LineItemDisplayCard;
