import React, { useContext } from 'react';
import { FlexBox, H1, Icon } from '@vp/swan';
import { OrderCopyContext } from '../../../contexts/OrderCopyContext';

const OrderCopyHeading = () => {
  const { reset } = useContext(OrderCopyContext);
  return (
    <FlexBox justifyContent="space-between" alignItems="center">
      <H1 my={6}>Order Copy</H1>
      <Icon iconType="reorder" size="40p" alt="Start over" onClick={reset} />
    </FlexBox>
  );
};

export default OrderCopyHeading;
