import React, { useContext } from 'react';
import {
  GridContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Typography,
} from '@vp/swan';
import { Link as RouterLink } from '@reach/router';
import { EnvironmentContext } from '../../../contexts/EnvironmentContext';

const OrderFailuresPanel = ({ failures }) => {
  
  const { environment } = useContext(EnvironmentContext);

  return (
    <GridContainer>
     <Table>
      <TableHead>
        <TableRow>
          <TableCell>Type</TableCell>
          <TableCell>Order</TableCell>
          <TableCell>Line Item</TableCell>
          <TableCell>Failure Id</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      {failures && failures.map(failure => (
        <TableRow>
          <TableCell>
            <Typography textSize={7}>
              {failure.type}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography textSize={7}>
              <RouterLink
                  component="button"
                  to={`/orderConsole/${environment}/${failure.orderNumber}`}
                >{failure.orderNumber}
              </RouterLink>
            </Typography>
          </TableCell>
          <TableCell>
            <Typography textSize={7}>
              {failure.lineItemId}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography textSize={7}>
              {failure.itemFailureId}
            </Typography>
          </TableCell>
        </TableRow>
      ))}
      </TableBody>
    </Table>
    </GridContainer>
  );
};

export default OrderFailuresPanel;
