const prodMexBaseUrl = process.env.PRODUCTION_MCP_MEX_BASE_URL;
const stagingMexBaseUrl = process.env.STAGING_MCP_MEX_BASE_URL;

const mexSearchQueryParam = 'searchTerm';

function fetchMexConfigForEnvironment(environment) {
  if (!environment) {
    console.error(
      `Unable to derive service config for a null/empty environment, using STAGING environment as sensible fallback.`
    );
    return { baseUrl: stagingMexBaseUrl };
  }
  switch (environment) {
    case 'vistaprint-production':
      return { baseUrl: prodMexBaseUrl };
    case 'vistaprint-staging':
      return { baseUrl: stagingMexBaseUrl };
    default:
      console.warn(
        `Unsupported environment type of ${environment}, using STAGING envirnoment as sensible fallback.`
      );
      return { baseUrl: stagingMexBaseUrl };
  }
}

export const buildMexUrl = (environment, orders) => {
  const mexConfig = fetchMexConfigForEnvironment(environment);

  const orderNumbers = [];
  orders.forEach(order => {
    orderNumbers.push(order.orderNumber);
  });
  const orderNumbersString = orderNumbers.join(',');
  const mexUrl = `${mexConfig.baseUrl}/?${mexSearchQueryParam}=${orderNumbersString}`;
  return mexUrl;
};
