const SORT_UI_EVENT_TYPE = 'sort-ui-event';
const SORT_UI_ERROR_TYPE = 'sort-ui-error';

const RESUBMISSION_ATTEMPTED = 'sort-ui-resubmission-attempted';
const RESUBMISSION_COMPLETED = 'sort-ui-resubmission-completed';
const RESUBMISSION_CSV_ISSUE = 'sort-ui-resubmission-csv-issue';
const RESUBMISSION_INCIDENT_ISSUE = 'sort-ui-resubmission-incident-issue';
const INCIDENT_SEARCH_ID_ERROR = 'sort-ui-incident-search-id-error';
const INCIDENT_FETCH_ALL_ERROR = 'sort-ui-incident-fetch-all-error';
const VOYAGER_SEARCH_ERROR = 'sort-ui-order-voyager-search-error';
const VOYAGER_ORDER_SEARCH = 'sort-ui-order-voyager-search';
const AGENT_VOYAGER_ORDER_SEARCH_ERROR =
  'sort-ui-agent-voyager-order-search-error';
const AGENT_VOYAGER_ORDER_SEARCH = 'sort-ui-agent-voyager-order-search';

/** ***********************************
 * Main NewRelic tracking functions.
 ************************************ */

function trackError(data) {
  if (window.newrelic) {
    window.newrelic.addPageAction(SORT_UI_ERROR_TYPE, data);
  }
}

/**
 * Submit a non-error event to NR
 * @param {*} data
 */
function trackEvent(data) {
  if (window.newrelic) {
    window.newrelic.addPageAction(SORT_UI_EVENT_TYPE, data);
  }
}

/**
 * Format error tracking data suitable for viewing in New Relic.
 * @param {string} reason queriable error type
 * @param {*} error error data object
 * @param {*} shopperId shopper ID of original request
 */
function createTrackingErrorData(
  reason,
  canonicalId,
  errorObj = {},
  additionalData = {}
) {
  return {
    errorReason: reason,
    error: JSON.stringify(errorObj),
    canonicalId,
    ...additionalData,
  };
}

/**
 * Format event tracking data suitable for viewing in New Relic.
 * Spread Operator Usage: New Relic prefers flattened structure of data, nesting JSON will make querying difficult.
 * @param {string} eventReason queriable event type
 * @param {*} canonicalId canonical ID of original request
 * @param {*} eventDataObj event data object
 */
function createTrackingEventData(eventReason, canonicalId, eventDataObj = {}) {
  return {
    eventReason,
    canonicalId,
    ...eventDataObj,
  };
}

/**
 * A user has initiated a resubmission of orders
 */
export const trackResubmissionStarted = (additionalData, canonicalId = '') => {
  trackEvent(
    createTrackingEventData(RESUBMISSION_ATTEMPTED, canonicalId, additionalData)
  );
};

/**
 * A user has completed a resubmission of orders
 */
export const trackResubmissionComplete = (additionalData, canonicalId = '') => {
  trackEvent(
    createTrackingEventData(RESUBMISSION_COMPLETED, canonicalId, additionalData)
  );
};

/**
 * A user has uploaded a CSV that is invalid
 */
export const trackResubmissionCsvIssue = (additionalData, canonicalId = '') => {
  trackEvent(
    createTrackingEventData(RESUBMISSION_CSV_ISSUE, canonicalId, additionalData)
  );
};

/**
 * An error occurred resubmitting an incident
 */
export const trackResubmissionIncidentIssue = (
  additionalData,
  canonicalId = ''
) => {
  trackEvent(
    createTrackingEventData(
      RESUBMISSION_INCIDENT_ISSUE,
      canonicalId,
      additionalData
    )
  );
};

/**
 * An error occurred searching for an incident by id
 */
export const trackSearchIncidentError = (additionalData, canonicalId = '') => {
  trackError(
    createTrackingEventData(
      INCIDENT_SEARCH_ID_ERROR,
      canonicalId,
      additionalData
    )
  );
};

/**
 * An error occurred fetching all unresolved incidents
 */
export const trackFetchAllIncidentsError = (
  additionalData,
  canonicalId = ''
) => {
  trackError(
    createTrackingEventData(
      INCIDENT_FETCH_ALL_ERROR,
      canonicalId,
      additionalData
    )
  );
};

/**
 * A user entered an order number and an error was thrown
 */
export const trackVoyagerOrderSearchError = (
  error,
  additionalData,
  canonicalId = ''
) => {
  trackError(
    createTrackingErrorData(
      VOYAGER_SEARCH_ERROR,
      canonicalId,
      error,
      additionalData
    )
  );
};

/**
 * A user entered an order number and an error was thrown
 */
export const trackVoyagerOrderSearch = (additionalData, canonicalId = '') => {
  trackEvent(
    createTrackingEventData(VOYAGER_ORDER_SEARCH, canonicalId, additionalData)
  );
};

/**
 * An agent entered an order number and an error was thrown
 */
export const trackAgentVoyagerOrderSearchError = (
  error,
  additionalData,
  canonicalId = ''
) => {
  trackError(
    createTrackingErrorData(
      AGENT_VOYAGER_ORDER_SEARCH_ERROR,
      canonicalId,
      error,
      additionalData
    )
  );
};

/**
 * An agent entered an order number and the search succeeded
 */
export const trackAgentVoyagerOrderSearch = (
  additionalData,
  canonicalId = ''
) => {
  trackEvent(
    createTrackingEventData(
      AGENT_VOYAGER_ORDER_SEARCH,
      canonicalId,
      additionalData
    )
  );
};
