import { Image } from 'react-bootstrap';
import React from 'react';

/**
 * For the given item, identify the correct icon resource to use
 * @param item
 */
const itemIconMapper = item => {
  if (item.milestoneType && item.milestoneType.iconType) {
    const { iconType } = item.milestoneType;
    let iconToUse;
    switch (iconType) {
      case 'ORDER_CREATED':
        iconToUse =
          'https://cms.cloudinary.vpsvc.com/image/upload/v1652366641/order_management/sort-ui/cart.png';
        break;
      case 'ITEM_CREATED':
        iconToUse =
          'https://cms.cloudinary.vpsvc.com/image/upload/v1652366641/order_management/sort-ui/cart.png';
        break;
      case 'MONEY':
        iconToUse =
          'https://cms.cloudinary.vpsvc.com/image/upload/v1652366642/order_management/sort-ui/payment.png';
        break;
      case 'EMAIL':
        iconToUse =
          'https://cms.cloudinary.vpsvc.com/image/upload/v1652366642/order_management/sort-ui/plain_envelope.png';
        break;
      case 'SHIPPED':
        iconToUse =
          'https://cms.cloudinary.vpsvc.com/image/upload/v1652366643/order_management/sort-ui/truck.png';
        break;
      case 'CANCEL':
        iconToUse =
          'https://cms.cloudinary.vpsvc.com/image/upload/v1652366641/order_management/sort-ui/cancel.png';
        break;
      case 'REFUND':
        iconToUse =
          'https://cms.cloudinary.vpsvc.com/image/upload/v1652366642/order_management/sort-ui/refund.png';
        break;
      case 'MANUFACTURING':
        iconToUse =
          'https://cms.cloudinary.vpsvc.com/image/upload/v1652366642/order_management/sort-ui/manufacturing.png';
        break;
      case 'RETRY':
        iconToUse =
          'https://cms.cloudinary.vpsvc.com/image/upload/v1652366643/order_management/sort-ui/retry.png';
        break;
      case 'REPLACE':
        iconToUse =
          'https://cms.cloudinary.vpsvc.com/image/upload/v1652366643/order_management/sort-ui/replace.png';
        break;
      case 'ITEM_HOLD':
        iconToUse =
          'https://cms.cloudinary.vpsvc.com/image/upload/v1652366642/order_management/sort-ui/hold.png';
        break;
      case 'ITEM_UPDATED':
        iconToUse =
          'https://cms.cloudinary.vpsvc.com/image/upload/v1652366641/order_management/sort-ui/change.png';
        break;
      case 'ARRIVAL':
        iconToUse =
          'https://cms.cloudinary.vpsvc.com/image/upload/v1652366641/order_management/sort-ui/arrival.png';
        break;
      case 'SEND_FAILED':
        iconToUse =
          'https://cms.cloudinary.vpsvc.com/image/upload/v1652366642/order_management/sort-ui/failed.png';
        break;
      case 'GDPR':
        iconToUse =
          'https://cms.cloudinary.vpsvc.com/image/upload/v1652366642/order_management/sort-ui/gdpr.png';
        break;
      case 'INVOICE':
        iconToUse =
          'https://cms.cloudinary.vpsvc.com/image/upload/v1652366642/order_management/sort-ui/invoice.png';
        break;
      case 'REJECTED':
        iconToUse =
          'https://cms.cloudinary.vpsvc.com/image/upload/v1652366643/order_management/sort-ui/rejected.png';
        break;
      case 'ADDRESS':
        iconToUse =
          'https://cms.cloudinary.vpsvc.com/image/upload/v1652366641/order_management/sort-ui/change.png';
        break;
      case 'IMAGE_PREVIEW':
        iconToUse =
          'https://cms.cloudinary.vpsvc.com/image/upload/v1652366642/order_management/sort-ui/designChange.png';
        break;
      case 'TOP_SHEET':
        iconToUse =
          'https://cms.cloudinary.vpsvc.com/image/upload/v1652366643/order_management/sort-ui/topSheet.png';
        break;
      default:
      // do nothing
    }

    return iconToUse;
  }
  return undefined;
};

const ItemIcon = ({ item }) => {
  itemIconMapper(item);
  const iconToDisplay = itemIconMapper(item);
  if (iconToDisplay) {
    return <Image src={iconToDisplay} width={35} height={35} mr={2} />;
  }
  return null;
};

export default ItemIcon;
