import React, { useContext, useEffect, useState } from 'react';
import {
  BasicPreloader,
  Column,
  GridContainer,
  Row,
  Typography,
} from '@vp/swan';
import { OrderTimelineContext } from '../../../contexts/OrderTimelineContext';
import useIdentityContext from '../../../hooks/useIdentityContext';
import OrderTimelineViz from './OrderTimelineViz';
import { orderJourneyFetch } from '../../../api/backendOrderRemediationToolService';
import { EnvironmentContext } from '../../../contexts/EnvironmentContext';
import {
  trackAgentVoyagerOrderSearch,
  trackAgentVoyagerOrderSearchError,
} from '../../../api/newRelicTrackingApi';

const AgentVoyager = props => {
  const { identity } = useIdentityContext();
  const { cimpressADFSUserId, isSignedIn } = identity;
  const { environment } = useContext(EnvironmentContext);
  const searchObjectFilter = 'justMilestones';
  // Flag so order is only loaded once
  const [orderLoadInitiated, setOrderLoadInitiated] = useState(false);
  // eslint-disable-next-line react/destructuring-assignment
  const [orderNumber, setOrderNumber] = useState(props.orderNumber);
  const [visualizationData, setVisualizationData] = useState(undefined);
  const [isLoading, setIsLoading] = useState(undefined);
  const [orderMilestones, setOrderMilestones] = useState([]);
  const [invalidOrderNumber, setInvalidOrderNumber] = useState(false);
  const [timelineItems, setTimelineItems] = useState([]);

  const loadOrderData = async () => {
    setInvalidOrderNumber(false);
    setIsLoading(true);

    try {
      const orderVizData = await orderJourneyFetch(
        environment,
        props.orderNumber,
        [],
        searchObjectFilter
      );

      setTimelineItems(orderVizData.groups);
      setOrderMilestones(orderVizData.milestones);
      setVisualizationData(orderVizData);
      // New Relic Tracking
      const voyagerSearchSuccessMetricData = {
        environment,
        orderNumber,
        searchObjectFilter,
      };
      trackAgentVoyagerOrderSearch(
        cimpressADFSUserId,
        voyagerSearchSuccessMetricData
      );
    } catch (err) {
      setInvalidOrderNumber(true);
      setTimelineItems([]);
      setOrderMilestones([]);
      setVisualizationData(undefined);

      // New Relic Tracking
      const voyagerSearchErrorMetricData = {
        environment,
        orderNumber,
        searchObjectFilter,
      };
      trackAgentVoyagerOrderSearchError(
        err,
        voyagerSearchErrorMetricData,
        cimpressADFSUserId
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isSignedIn && orderNumber && !orderLoadInitiated) {
      setOrderLoadInitiated(true);
      loadOrderData();
    }
  }, [isSignedIn, orderNumber, loadOrderData, orderLoadInitiated]);

  return isSignedIn ? (
    <OrderTimelineContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        orderNumber,
        setOrderNumber,
        visualizationData,
        setVisualizationData,
        isLoading,
        setIsLoading,
        orderMilestones,
        setOrderMilestones,
        invalidOrderNumber,
        setInvalidOrderNumber,
        timelineItems,
        setTimelineItems,
      }}
    >
      <div>
        <GridContainer>
          <Row mt={5}>
            <Column offset={1}>
              <Typography component="h2" mt={4}>
                Agent Voyager {orderNumber}
              </Typography>
            </Column>
          </Row>
        </GridContainer>
        <GridContainer>
          <Row mb={6} mt={6}>
            <Column span={10} offset={1}>
              {!invalidOrderNumber && orderNumber ? (
                <OrderTimelineViz
                  visualizationData={visualizationData}
                  iconsOnly={false}
                />
              ) : (
                <Typography mt={4} mb={7}>
                  Order not found!
                </Typography>
              )}
            </Column>
          </Row>
        </GridContainer>
      </div>
    </OrderTimelineContext.Provider>
  ) : (
    <BasicPreloader sizeVariant="large" centered my={6} />
  );
};
export default AgentVoyager;
