import {
  BasicPreloader,
  BoundedContent,
  FlexBox,
  GridContainer,
  Paginator,
  PaginatorButton,
  PaginatorInput,
  PaginatorLabel,
  Row,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@vp/swan';
import React, { useContext, useState, useEffect } from 'react';
import { isUndefined } from 'lodash';
import { EnvironmentContext } from '../../../contexts/EnvironmentContext';
import {
  fetchOrdersForMultipleSearchTerms,
  SHOPPER_ID_SEARCH_TERM,
} from '../../../api/daliService';
import { getProfiles } from '../../../api/profileService';
import OrderDetails from './OrderDetail';
import ProfileDetail from './ProfileDetail';
import AdvancedLookup from './AdvancedLookup';

const FIRST_PAGE_INDEX = 0;

// We display the API page index + 1 as the page number.
const getDisplayedPageNumber = index => index + 1;
const getPageIndex = pageDisplayNumber => pageDisplayNumber - 1;
const getResultsLastPageIndex = searchData =>
  searchData ? searchData.totalPages - 1 : 0;

const OrderLookupHome = () => {
  const { environment } = useContext(EnvironmentContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isProfileLoading, setIsProfileLoading] = useState(false);
  const [genericSearchError, setGenericSearchError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [searchData, setSearchData] = useState(undefined);
  const [currentPageIndex, setCurrentPageIndex] = useState(FIRST_PAGE_INDEX);
  const [currentSearchTerms, setCurrentSearchTerms] = useState(undefined);
  const [profileData, setProfileData] = useState(undefined);

  const haveSearchData =
    searchData && searchData.orders && searchData.orders.length > 0;
  const haveProfileData = profileData && profileData.length > 0;

  /**
   * If either the order or the profile list comes in, check to see whether any profiles
   * can populate any missing email field in the order, based on matching shopper IDs.
   */
  useEffect(
    () => {
      if (searchData && searchData.orders && profileData) {
        let doUpdate = false;
        searchData.orders.map(o => {
          profileData.map(p => {
            if (p.shopperId === o.shopperId) {
              if (!o.emailAddress) {
                o.emailAddress = p.email;
                doUpdate = true;
              }
            }
            return p;
          });
          return o;
        });
        if (doUpdate) {
          // Have to do deep copy and replace so React sees the change.
          const s = JSON.stringify(searchData);
          const sObj = JSON.parse(s);
          setSearchData(sObj);
        }
      }
    },
    [profileData, searchData],
    profileData
  );

  /**
   * Attach the current profile email (if there is one) to all orders provided
   * that do NOT already have an 'emailAddress' field value.
   * @param {*} profile Profile for all orders in array.
   * @param {*} orderArr List of orders.
   * @returns
   */
  const populateProfileFields = (profile, orderArr) => {
    if (!profile || !orderArr || orderArr.length === 0) {
      return;
    }
    const { email } = profile;
    for (let i = 0; i < orderArr.length; i += 1) {
      const res = orderArr[i];
      // Email on the order has precedence.
      if (!res.emailAddress) {
        res.emailAddress = email;
      }
      if (res.billingAddress && !res.billingAddress.email) {
        res.billingAddress.email = email;
      }
      if (res.shippingAddress && !res.shippingAddress.email) {
        res.shippingAddress.email = email;
      }
    }
  };

  /**
   * Handles resetting page to FIRST_PAGE if necessary.
   * @param {*} requestedPageIndex
   * @returns Actual page to search for.
   */
  const contextSwitch = requestedPageIndex => {
    let finalPageIndex = requestedPageIndex;
    if (isUndefined(requestedPageIndex)) {
      setCurrentPageIndex(FIRST_PAGE_INDEX);
      finalPageIndex = FIRST_PAGE_INDEX;
    }
    return finalPageIndex;
  };

  /**
   * Searches with an array of search term name/value pairs.
   * @param {*} terms
   * @param {string} pgIndex API index of page.
   */
  const doFullTermSearch = async (terms, pgIndex, prof) => {
    const pgIndex1 = contextSwitch(pgIndex);
    if (Object.getOwnPropertyNames(terms).length === 0) {
      // Do nothing if there are no search terms.
    } else {
      // Clear both data fields here.  This is assuming we start this search first before the profile call.
      setSearchData(undefined);
      setProfileData(undefined);

      setIsLoading(true);
      setCurrentSearchTerms(terms);
      setErrorMessage(undefined);

      try {
        const result = await fetchOrdersForMultipleSearchTerms(
          environment,
          terms,
          pgIndex1
        );
        populateProfileFields(prof, result.orders);
        setSearchData(result);
      } catch (err) {
        setGenericSearchError(true);
        setErrorMessage(err.message);
      }
    }
    setIsLoading(false);
  };

  /**
   * Handle paging for each search type.
   * @param {*} pgIndex
   */
  const nextSearchPage = pgIndex => {
    if (currentSearchTerms) {
      doFullTermSearch(currentSearchTerms, pgIndex);
    }
  };

  /**
   * Search the Profile Service for entries by first+last name, and/or email.
   * @param {*} firstName
   * @param {*} lastName
   * @param {string} email
   * @return array of profile objects.
   */
  const doProfileSearch = async (
    firstName,
    lastName,
    email,
    isProfilesEnabled
  ) => {
    setProfileData(undefined);
    if (!isProfilesEnabled) {
      return;
    }
    setIsProfileLoading(true);
    try {
      const profileData1 = await getProfiles(firstName, lastName, email);
      setProfileData(profileData1);
    } catch (err) {
      console.log(`Profiles: ERROR${JSON.stringify(err)}`);
      setProfileData(undefined);
    }
    setIsProfileLoading(false);
  };

  const getProfile = profileArray => profileArray[0];

  /**
   * Search for orders by shopper ID only.
   * @param {*} shopperId
   */
  const doSearchByShopperId = shopperId => {
    const term = {};
    term[SHOPPER_ID_SEARCH_TERM] = shopperId;
    // Get profile!
    let prof;
    if (profileData) {
      const profArr = profileData.filter(p => p.shopperId === shopperId);
      prof = getProfile(profArr);
    }
    setProfileData(undefined);
    doFullTermSearch(term, FIRST_PAGE_INDEX, prof);
  };

  return (
    <BoundedContent>
      <GridContainer>
        <Row mt={5} mb={5}>
          <Typography component="h2" mt={4}>
            Order Lookup
          </Typography>
        </Row>
        <Row mb={8} span={12}>
          <AdvancedLookup
            searchFn={doFullTermSearch}
            profileSearchFn={doProfileSearch}
          />
        </Row>

        {isLoading || isProfileLoading ? (
          <BasicPreloader sizeVariant="large" centered my={6} />
        ) : (
          <>
            {searchData &&
              searchData.orders &&
              searchData.orders.length === 0 && (
                <Typography textColor="error">
                  No orders were found for this search.
                </Typography>
              )}
            {errorMessage && (
              <Typography textColor="error">{errorMessage}</Typography>
            )}
            {(haveSearchData || haveProfileData) && (
              <GridContainer>
                <Row>
                  <Table mb={5}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Order Number (Click for details)</TableCell>
                        <TableCell>Email Address</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Tenant Id</TableCell>
                        <TableCell>Amount (* = multi-payment)</TableCell>
                        <TableCell>Date Created</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {haveProfileData &&
                        profileData.map(p => (
                          <ProfileDetail
                            profile={p}
                            searchByShopperFn={doSearchByShopperId}
                          />
                        ))}
                      {haveSearchData &&
                        searchData.orders.map(order => (
                          <OrderDetails order={order} />
                        ))}
                    </TableBody>
                  </Table>
                </Row>
                <FlexBox justifyContent="center" mb={5} mt={5}>
                  <Paginator visuallyHiddenText="Pagination">
                    <PaginatorButton
                      variant="previous"
                      visuallyHiddenText="Previous Page"
                      disabled={currentPageIndex === FIRST_PAGE_INDEX}
                      onClick={() => {
                        if (currentPageIndex !== FIRST_PAGE_INDEX) {
                          const newPageIndex = currentPageIndex - 1;
                          setCurrentPageIndex(newPageIndex);
                          nextSearchPage(newPageIndex);
                        }
                      }}
                    />
                    <PaginatorInput
                      visuallyHiddenText="Go to page"
                      defaultValue={getDisplayedPageNumber(currentPageIndex)}
                      onKeyDown={e => {
                        const newPageNum = parseInt(e.target.value);
                        let newPageIndex = getPageIndex(newPageNum);
                        if (newPageIndex < FIRST_PAGE_INDEX) {
                          newPageIndex = FIRST_PAGE_INDEX;
                        }
                        if (
                          newPageIndex <= getResultsLastPageIndex(searchData) &&
                          e.key === 'Enter'
                        ) {
                          setCurrentPageIndex(newPageIndex);
                          nextSearchPage(newPageIndex);
                        }
                      }}
                    />
                    <PaginatorLabel>
                      of {haveSearchData && searchData.totalPages}
                    </PaginatorLabel>
                    <PaginatorButton
                      variant="next"
                      visuallyHiddenText="Next Page"
                      disabled={
                        currentPageIndex >= getResultsLastPageIndex(searchData)
                      }
                      onClick={() => {
                        if (
                          currentPageIndex < getResultsLastPageIndex(searchData)
                        ) {
                          const newPageIndex = currentPageIndex + 1;
                          setCurrentPageIndex(newPageIndex);
                          nextSearchPage(newPageIndex);
                        }
                      }}
                    />
                  </Paginator>
                </FlexBox>
              </GridContainer>
            )}
          </>
        )}
      </GridContainer>
    </BoundedContent>
  );
};
export default OrderLookupHome;
