import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { FlexBox, ListItem, Typography } from '@vp/swan';
import { DISCOUNTED_PRICE, LIST_PRICE, TAXED, UNTAXED } from '../itemAccessors';
import CurrencyWrapper from '../../currency/CurrencyWrapper';
import { currencyLocaleFormatter } from '../../currency/currencyLocaleFormatter';
import { OrderConsoleContext } from '../../../../../contexts/OrderConsoleContext';

const PricedFeeAttribute = ({ fee, lineItem }) => {
  const { order, includeTax } = useContext(OrderConsoleContext);
  const { locale } = order;

  const listPriceFieldName = includeTax
    ? `${LIST_PRICE}.${TAXED}`
    : `${LIST_PRICE}.${UNTAXED}`;
  const discountedPriceFieldName = includeTax
    ? `${DISCOUNTED_PRICE}.${TAXED}`
    : `${DISCOUNTED_PRICE}.${UNTAXED}`;
  const listPrice = get(fee, listPriceFieldName);
  const discountedPrice = get(fee, discountedPriceFieldName);

  const displayName = 'additional fees';

  const { totalListPrice } = lineItem;
  const { fractionDigits } = totalListPrice;
  const currency = totalListPrice && totalListPrice.currencyCode;

  return (
    discountedPrice && (
      <ListItem>
        <FlexBox justifyContent="space-between" marginY={4}>
          <Typography textColor="dark-grey">{displayName}</Typography>
          <Typography>
            <CurrencyWrapper
              fractionDigits={fractionDigits}
              typeSize={5}
              listCents={listPrice}
              discountCents={discountedPrice}
              currencyCode={currency}
              locale={currencyLocaleFormatter(locale)}
            />
          </Typography>
        </FlexBox>
      </ListItem>
    )
  );
};

PricedFeeAttribute.propTypes = {
  fee: PropTypes.object,
};

export default PricedFeeAttribute;
