import React, { useContext } from 'react';
import { Button } from '@vp/swan';
import { OrderCopyContext } from '../../../contexts/OrderCopyContext';
import { API_STATUS } from '../../../constants/apiStatus';
import { copyOrder } from '../../../api/backendOrderRemediationToolService';
import {
  TARGET_EMAIL_ADDRESS,
  TARGET_SHOPPER_ID,
  TARGET_TENANT_ID,
} from '../../../constants/orderCopyTargetFormFields';

const OrderCopyButton = () => {
  const {
    sourceOrder,
    setOrderCopyStatus,
    setTargetOrderNumber,
    targetOrderFormFields,
    validateForms,
  } = useContext(OrderCopyContext);

  const handleOnCopyOrderClick = async () => {
    setOrderCopyStatus(API_STATUS.PENDING);
    let result;
    try {
      result = await copyOrder({
        sourceOrder,
        targetShopperId: targetOrderFormFields[TARGET_SHOPPER_ID].value,
        targetTenantId: targetOrderFormFields[TARGET_TENANT_ID].value,
        targetEmailAddress: targetOrderFormFields[TARGET_EMAIL_ADDRESS].value,
      });
      setOrderCopyStatus(API_STATUS.SUCCESS);
      setTargetOrderNumber(result?.newOrderNumber);
    } catch (err) {
      setOrderCopyStatus(API_STATUS.FAILED);
      setTargetOrderNumber('');
      console.error(err);
    }
  };

  return (
    <Button
      skin="primary"
      width="full-width"
      mb={8}
      onClick={() => {
        const formInputValid = validateForms();
        if (formInputValid) {
          handleOnCopyOrderClick();
        }
      }}
    >
      Copy order
    </Button>
  );
};

export default OrderCopyButton;
