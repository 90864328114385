import React from 'react';
import { Link, TableCell, TableRow, Typography } from '@vp/swan';

const ProfileDetail = ({ profile, searchByShopperFn }) => {
  const firstName = profile?.firstName || '';
  const lastName = profile?.lastName || '';
  const name = `${firstName} ${lastName}`;
  const rowTextBg = 'emerald';
  const rowTextColor = 'white';
  const locale = profile?.locale || '-';

  return (
    <TableRow>
      <TableCell onClick={() => searchByShopperFn(profile.shopperId)}>
        <Link>
          <Typography textColor={rowTextBg}>Search This Shopper</Typography>
        </Link>
      </TableCell>
      <TableCell>
        <Typography backgroundColor={rowTextBg} textColor={rowTextColor}>
          {profile.email}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography backgroundColor={rowTextBg} textColor={rowTextColor}>
          {name}
        </Typography>
      </TableCell>
      <TableCell>{locale}</TableCell>
      <TableCell>-</TableCell>
      <TableCell>-</TableCell>
    </TableRow>
  );
};

export default ProfileDetail;
