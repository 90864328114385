/* eslint-disable react/destructuring-assignment */
import { GridContainer, Row, Column, BoundedContent, H2, Card } from '@vp/swan';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import TargetOrderForm from './targetOrder/TargetOrderForm';
import { OrderCopyProvider } from '../../../contexts/OrderCopyContext';
import OrderCopyButton from './OrderCopyButton';
import TargetOrderPreview from './targetOrder/TargetOrderPreview';
import OrderCopyResult from './OrderCopyResult';
import SourceOrderForm from './sourceOrder/SourceOrderForm';
import SourceOrderPreview from './sourceOrder/SourceOrderPreview';
import { EnvironmentContext } from '../../../contexts/EnvironmentContext';
import OrderCopyHeading from './OrderCopyHeading';

const OrderCopy = props => {
  const { environment } = useContext(EnvironmentContext);

  return (
    <OrderCopyProvider
      orderNumber={props?.orderNumber || ''}
      environment={environment || ''}
    >
      <BoundedContent mb={4}>
        <GridContainer>
          <Row>
            <Column span={10} offset={1}>
              <OrderCopyHeading />
            </Column>
          </Row>

          <Row sticky>
            <Column span={5} offset={1}>
              <Card bordered p={6} mb={4}>
                <SourceOrderForm />
              </Card>
              <Card bordered p={6} mb={4}>
                <TargetOrderForm />
              </Card>
              <OrderCopyButton />
            </Column>

            <Column span={5} sticky>
              <Card p={6} backgroundColor="light-blue">
                <H2 fontSize={3}>Source</H2>
                <Card bordered backgroundColor="transparent" mb={6}>
                  <SourceOrderPreview />
                </Card>
                <H2 fontSize={3}>Target</H2>
                <Card bordered backgroundColor="transparent" mb={6}>
                  <TargetOrderPreview />
                </Card>
                <H2 fontSize={3}>Result</H2>
                <Card bordered backgroundColor="transparent">
                  <OrderCopyResult />
                </Card>
              </Card>
            </Column>
          </Row>
        </GridContainer>
      </BoundedContent>
    </OrderCopyProvider>
  );
};

OrderCopy.propTypes = {
  orderNumber: PropTypes.string,
};

export default OrderCopy;
