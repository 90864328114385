import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';
import { Accordion, BasicCollapsible, List, Row } from '@vp/swan';
import get from 'lodash/get';
import PricedItemAttribute from './PricedItemAttribute';
import PricedFeeAttribute from './PricedFeeAttribute';
import UnpricedItemAttribute from './UnpricedItemAttribute';
import {
  ADDITIONAL_FEES,
  BREAKDOWN,
  DISCOUNTED_PRICE,
  FEES,
  TAXED,
  UNTAXED,
} from '../itemAccessors';
import { OrderConsoleContext } from '../../../../../contexts/OrderConsoleContext';

/**
 * Responsible for calculating the display of an item's priced and unpriced attributes.
 */
export const ItemAttributeDetails = ({ lineItem }) => {
  const { order, includeTax } = useContext(OrderConsoleContext);
  const { migrated: isMigratedOrder } = order;
  const {
    pricingBreakdownV3: pricingBreakdown,
    displayProductAttributes: displayAttributes,
  } = lineItem;

  const attributeBreakdowns = pricingBreakdown[BREAKDOWN] || [];
  const fees = pricingBreakdown[ADDITIONAL_FEES]?.[FEES] || [];

  /*
  If no display attributes with keyDisplayName and valueDisplayName exist for the line item,
  then we opt out of displaying the Selected Options Accordion for the line item.
  This allows the client of Vistacart to enable whether or not Selected Options should be shown in Cart and Order Details.
  In the case that display attributes with keyDisplayName and valueDisplayName  ARE provided, we believe that:
  It is best to always display priced attributes which sum to the item total, regardless of whether or not there is
  untranslated priced attribute.
  */
  const displayableAttributes = filter(
    displayAttributes,
    attribute => attribute.keyDisplayName && attribute.valueDisplayName
  );

  let unpricedDisplayAttributes = displayableAttributes;

  const discountedPriceFieldName = includeTax
    ? `${DISCOUNTED_PRICE}.${TAXED}`
    : `${DISCOUNTED_PRICE}.${UNTAXED}`;

  // filters displayAttributes with discountedPrice > 0 into a priced attributes list and removes these attributes from the unpriced attributes
  const pricedDisplayAttributes = attributeBreakdowns.filter(
    pricedAttribute => {
      const discountedPrice = get(pricedAttribute, discountedPriceFieldName);
      if (discountedPrice > 0) {
        const pricedAttributeKeyValue = `${pricedAttribute.name}: ${pricedAttribute.value}`;
        const attribute = displayAttributes?.find(displayAttribute => {
          const displayAttributeKeyValue = `${displayAttribute.key}: ${displayAttribute.value}`;
          return pricedAttributeKeyValue === displayAttributeKeyValue;
        });

        if (attribute) {
          // add displayValues to priced item attribute
          pricedAttribute.keyDisplayName = attribute.keyDisplayName;
          pricedAttribute.valueDisplayName = attribute.valueDisplayName;

          // remove priced attribute from unpriced attributes list
          unpricedDisplayAttributes = unpricedDisplayAttributes.filter(
            value => value !== attribute
          );
        }
      }

      return discountedPrice > 0;
    }
  );

  const showSelectedOptions = displayableAttributes.length > 0;

  const hasAttributes =
    (unpricedDisplayAttributes && unpricedDisplayAttributes.length > 0) ||
    (pricedDisplayAttributes && pricedDisplayAttributes.length > 0);

  const hasFees = fees && fees.length > 0;

  return !isMigratedOrder && showSelectedOptions && hasAttributes ? (
    <Accordion single skin="standard">
      <BasicCollapsible
        heading="Selected Options"
        collapsibleId="attribute-breakdowns"
        role="note"
        skin="standard"
        fullBleed
      >
        <List minimal>
          {unpricedDisplayAttributes.map(attribute => (
            <UnpricedItemAttribute attribute={attribute} />
          ))}
          {pricedDisplayAttributes.map(attribute => (
            <PricedItemAttribute attribute={attribute} lineItem={lineItem} />
          ))}
          {hasFees &&
            fees.map(fee => (
              <PricedFeeAttribute fee={fee} lineItem={lineItem} />
            ))}
        </List>
      </BasicCollapsible>
    </Accordion>
  ) : (
    <>
      {/* Fixes rendering when no selectedOptions shown */}
      <Row mt={5} />
    </>
  );
};

ItemAttributeDetails.propTypes = {
  lineItem: PropTypes.object,
};
