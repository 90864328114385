import axios from 'axios';
import { buildStandardHeaders } from '../utils/serviceHelpers';
import { getAuthorizationHeaderAndShopperId } from '../components/auth/auth';

const prodCIABaseUrl = process.env.PRODUCTION_CIA_BASE_URL;
const stagingCIABaseUrl = process.env.STAGING_CIA_BASE_URL;

/**
 * Returns an object containing parameters to use for calling the CIA service.
 * In the future, consider adding timeouts as part of the configuration.
 * @param {*} environment The environment to send CIA service requests too.
 */
function fetchCIAConfigForEnvironment(environment) {
  if (!environment) {
    console.error(
      `Unable to derive service config for a null/empty environment, using STAGING environment as sensible fallback.`
    );
    return { baseUrl: stagingCIABaseUrl };
  }

  switch (environment) {
    case 'vistaprint-production':
      return { baseUrl: prodCIABaseUrl };
    case 'vistaprint-staging':
      return { baseUrl: stagingCIABaseUrl };
    default:
      console.warn(
        `Unsupported environment type of ${environment}, using STAGING envirnoment as sensible fallback.`
      );
      return { baseUrl: stagingCIABaseUrl };
  }
}

function ensureRequestWasSuccessfulOrThrow(response) {
  if (response && response.status === 200) {
    // noice!
  } else {
    throw new Error('request failed');
  }
}

export const healthCheck = async environment => {
  const ciaConfig = fetchCIAConfigForEnvironment(environment);
  const axiosInstance = axios.create({
    baseURL: ciaConfig.baseUrl,
  });
  return axiosInstance.get(`health`);
};

/**
 * Search for incident by incidentId
 * @param environment
 * @param incidentId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetchIncidentById = (environment, incidentId) => {
  const ciaConfig = fetchCIAConfigForEnvironment(environment);
  const { idToken } = getAuthorizationHeaderAndShopperId();

  const axiosInstance = axios.create({
    headers: buildStandardHeaders(idToken),
    baseURL: ciaConfig.baseUrl,
  });

  return axiosInstance
    .get(`/incidents/${incidentId}`)
    .then(response => {
      ensureRequestWasSuccessfulOrThrow(response);
      return response.data;
    })
    .catch(err => {
      throw err;
    });
};

/**
 * Search for order failures by order number
 * @param environment
 * @param orderNumber
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetchFailuresByOrderNumber = (environment, orderNumber) => {
  const ciaConfig = fetchCIAConfigForEnvironment(environment);
  const { idToken } = getAuthorizationHeaderAndShopperId();

  const axiosInstance = axios.create({
    headers: buildStandardHeaders(idToken),
    baseURL: ciaConfig.baseUrl,
  });

  return axiosInstance
    .get(`/orderItemFailures/orders/${orderNumber}`)
    .then(response => {
      ensureRequestWasSuccessfulOrThrow(response);
      return response.data;
    })
    .catch(err => {
      throw err;
    });
};

/**
 * Search for order incidents by order number
 * @param environment
 * @param orderNumber
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetchIncidentsByOrderNumber = (environment, orderNumber) => {
  const ciaConfig = fetchCIAConfigForEnvironment(environment);
  const { idToken } = getAuthorizationHeaderAndShopperId();

  const axiosInstance = axios.create({
    headers: buildStandardHeaders(idToken),
    baseURL: ciaConfig.baseUrl,
  });

  return axiosInstance
    .get(`/incidents/orders/${orderNumber}`)
    .then(response => {
      ensureRequestWasSuccessfulOrThrow(response);
      return response.data;
    })
    .catch(err => {
      throw err;
    });
};

/**
 * Get all available incidents
 * @param environment
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetchAllIncidents = environment => {
  const ciaConfig = fetchCIAConfigForEnvironment(environment);
  const { idToken } = getAuthorizationHeaderAndShopperId();

  const axiosInstance = axios.create({
    headers: buildStandardHeaders(idToken),
    baseURL: ciaConfig.baseUrl,
  });

  return axiosInstance
    .get(`/incidents`)
    .then(response => {
      ensureRequestWasSuccessfulOrThrow(response);
      return response.data;
    })
    .catch(err => {
      throw err;
    });
};
