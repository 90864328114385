import React, { useContext } from 'react';

import {
  Chart,
  CommonSeriesSettings,
  Legend,
  SeriesTemplate,
} from 'devextreme-react/chart';
import { Column, Divider, Typography } from '@vp/swan';
import { OrderConsoleContext } from '../../../../contexts/OrderConsoleContext';
import { InfoTypography } from '../../../shared/InfoTypography';

export const INITIAL_STATE = 'Initial';
export const IN_PROGRESS = 'In Progress';
export const FAILED = 'Failed';
export const COMPLETED = 'Completed';
export const REJECTED = 'Rejected';
const FOUR_HOURS_IN_MS = 14400000;

const itemStatusMap = new Map([
  ['Initial', IN_PROGRESS],
  ['SentToMCP', IN_PROGRESS],
  ['CancelRequested', IN_PROGRESS],
  ['CancelSuccess', COMPLETED],
  ['CancelFailed', IN_PROGRESS],
  ['Shipped', COMPLETED],
  ['Rejected', REJECTED],
  ['FailedItem', FAILED],
]);

function getStateRange(state, start, end) {
  const stateCategory = itemStatusMap.get(state);
  // If it's a "terminal" state, set the end of the range to be a day after start so that the
  // range bar is not soo long
  let adjustedEndDate = null;
  if (stateCategory === COMPLETED) {
    adjustedEndDate = new Date(start);
    adjustedEndDate.setTime(start.getTime() + FOUR_HOURS_IN_MS);
  } else {
    adjustedEndDate = end;
  }

  const stateRange = {
    state,
    start,
    end: adjustedEndDate,
    category: stateCategory,
  };

  return stateRange;
}

function getData(orderCreationDate, itemStateChangeEvents = []) {
  const dataSource = [];
  let endDateTime = new Date().toISOString();
  itemStateChangeEvents.forEach(event => {
    const state = event.itemInfo[0].itemProperties.toState;

    const stateRange = getStateRange(
      state,
      new Date(event.dateTime),
      new Date(endDateTime)
    );

    endDateTime = event.dateTime;

    dataSource.push(stateRange);

    // If the fromState is Initial, add another state to the array
    if (event.itemInfo[0].itemProperties.fromState === INITIAL_STATE) {
      const initialStateRange = getStateRange(
        INITIAL_STATE,
        orderCreationDate,
        endDateTime
      );
      dataSource.push(initialStateRange);
    }
  });

  return dataSource;
}

const ItemStatusChart = ({ lineItem }) => {
  const { order, stateChangeEvents } = useContext(OrderConsoleContext);
  const { orderCreationDate } = order;
  const itemStateChangeEvents = stateChangeEvents?.get(lineItem.id);

  const dataSource = itemStateChangeEvents
    ? getData(orderCreationDate, itemStateChangeEvents)
    : undefined;

  return itemStateChangeEvents && dataSource ? (
    // https://js.devexpress.com/Demos/WidgetsGallery/Demo/Charts/Timeline/React/Light/
    <Chart id="chart" dataSource={dataSource} barGroupPadding={0.2} rotated>
      <CommonSeriesSettings
        type="rangeBar"
        argumentField="state"
        rangeValue1Field="start"
        rangeValue2Field="end"
        barOverlapGroup="state"
        barWidth={30}
      />
      <Legend verticalAlignment="bottom" horizontalAlignment="center" />
      <SeriesTemplate nameField="category" />
    </Chart>
  ) : (
    <Column span={6}>
      <Typography weight="bold" mb={3}>
        States
      </Typography>
      {lineItem.states.map(s => (
        <>
          <Divider />
          <InfoTypography label="Quantity" valueToDisplay={s.quantity} />
          <InfoTypography label="State" valueToDisplay={s.state} />
        </>
      ))}
    </Column>
  );
};

export default ItemStatusChart;
