import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@vp/swan';

const LineItemStatus = ({ statuses }) => (
  <>
    {statuses.map(({ statusTranslationKey, color, quantity = null }, index) => {
      const additionalProps = {};
      if (color === 'alert' || color === 'error') {
        additionalProps.color = color;
      } else if (color === 'success') {
        // This is the green color from Swan badge that meets contrast a11y criteria
        additionalProps.style = { color: '#0a856a' };
      }

      const { color: textColor, style } = additionalProps;
      return (
        <Typography
          component="span"
          weight="bold"
          marginRight={2}
          key={index}
          textColor={textColor}
          style={style}
        >
          {statusTranslationKey} {quantity ? `x(${quantity})` : ''}
          {statuses.length - 1 === index ? '' : ', '}
        </Typography>
      );
    })}
  </>
);

LineItemStatus.propTypes = {
  statuses: PropTypes.array,
};

export default LineItemStatus;
