// react redux
import React from 'react';
import PropTypes from 'prop-types';

// Currency-formatter
import { Currency } from '@vp/om-currency';

// VP UI
import { Typography } from '@vp/swan';
import { currencyLocaleFormatter } from './currencyLocaleFormatter';

/**
 * Displays a single line of currency. Works with the CurrencyWrapper
 */
const CurrencyDisplay = ({
  fractionDigits,
  centPrice,
  currency,
  component,
  discounted,
  label = '',
  locale,
  prependLabel,
  typeSize = 5,
  typeStrong,
}) => {
  const preText = `${prependLabel ? label : ''}`;
  const text = `${prependLabel ? '' : label}`;
  const className = discounted ? 'discount-price' : undefined;

  return (
    <>
      {`${preText}`}
      <Typography
        component={component || 'span'}
        textSize={typeSize}
        weight={typeStrong ? 'bold' : 'standard'}
        className={className}
      >
        {`${text}`}
        <span style={{ whiteSpace: 'nowrap' }}>
          <Currency
            locale={currencyLocaleFormatter(locale)}
            cents={centPrice}
            currency={currency}
            fractionDigits={fractionDigits}
          />
        </span>
      </Typography>
    </>
  );
};

CurrencyDisplay.propTypes = {
  fractionDigits: PropTypes.number.isRequired,
  centPrice: PropTypes.number.isRequired,
  currency: PropTypes.string,
  component: PropTypes.string,
  discounted: PropTypes.bool,
  label: PropTypes.string,
  locale: PropTypes.string,
  prependLabel: PropTypes.bool,
  typeSize: PropTypes.number,
  typeStrong: PropTypes.bool,
};

export default CurrencyDisplay;
