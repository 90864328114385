import React, { useState } from 'react';
import {
  BasicPreloader,
  ModalDialog,
  ModalDialogBody,
  ModalDialogCloseButton,
  ModalDialogContent,
  ModalDialogHeader,
  ModalDialogNav,
  ModalDialogTitle,
  Button,
  useBrowserHistoryState,
} from '@vp/swan';
import ReactJson from 'react-json-view';
import { getEdoDebugData } from '../../../../../api/edoEvaluationUrlApi';
import handleCopy from '../../../common/handleCopy';

export const EdoDetailsModal = ({ evaluationUrl }) => {
  const [edoDetails, setEdoDetails] = useState(undefined);
  const [isOpen, setIsOpen] = useBrowserHistoryState(
    'modal-dialog-open',
    false
  );

  const getEdoEvaluation = async () => {
    if (!edoDetails) {
      const response = await getEdoDebugData(evaluationUrl);
      setEdoDetails(response);
    }
  };

  const handleClick = async () => {
    getEdoEvaluation();
    setIsOpen(true);
  };

  return (
    <>
      <Button
        sizeVariant="mini"
        widthVariant="full-width"
        onClick={handleClick}
        my="2"
      >
        Show Edo Details
      </Button>
      <ModalDialog
        isOpen={isOpen}
        onRequestDismiss={() => setIsOpen(false)}
        takeOver
      >
        <ModalDialogContent>
          <ModalDialogNav>
            <ModalDialogCloseButton visuallyHiddenLabel="Close" />
          </ModalDialogNav>
          <ModalDialogHeader>
            <ModalDialogTitle>Edo Debug Info</ModalDialogTitle>
          </ModalDialogHeader>
          <ModalDialogBody>
            {edoDetails ? (
              <ReactJson
                collapsed={false}
                name={false}
                displayObjectSize={false}
                displayDataTypes={false}
                src={edoDetails}
                enableClipboard={handleCopy}
              />
            ) : (
              <BasicPreloader sizeVariant="large" centered my={6} />
            )}
          </ModalDialogBody>
        </ModalDialogContent>
      </ModalDialog>
    </>
  );
};
