import React, { useContext, useEffect, useState } from 'react';
import {
  BasicPreloader,
  BoundedContent,
  Column,
  Divider,
  FormError,
  FormField,
  FormInputGroup,
  FormLabel,
  GridContainer,
  Link,
  Row,
  StandardForm,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TextInput,
  Typography,
} from '@vp/swan';
import useIdentityContext from '../../../hooks/useIdentityContext';
import { fetchAllIncidents, fetchIncidentById } from '../../../api/ciaService';
import { EnvironmentContext } from '../../../contexts/EnvironmentContext';
import IncidentRow from './IncidentRow';
import {
  trackFetchAllIncidentsError,
  trackSearchIncidentError,
} from '../../../api/newRelicTrackingApi';
import { ERROR_INCIDENT_SEARCH } from '../resubmission/resubmissionErrorTypes';

const IncidentReport = () => {
  const { identity } = useIdentityContext();
  const { isSignedIn } = identity;
  const { cimpressADFSUserId } = identity;
  const [incidents, setIncidents] = useState(null);
  const lookerIncidentDashboardUrl =
    'https://cimpress.eu.looker.com/dashboards/7020';

  const { environment } = useContext(EnvironmentContext);
  const [searchIncidentId, setSearchIncidentId] = useState(undefined);
  const [blankIncidentIdError, setBlankIncidentIdError] = useState(false);
  const [invalidIncidentIdError, setInvalidIncidentIdError] = useState(false);
  const [searchClicked, setSearchClicked] = useState(false);

  useEffect(() => {
    if (isSignedIn) {
      setIncidents(null);
      fetchAllIncidents(environment)
        .then(incidentData => setIncidents(incidentData))
        .catch(err => {
          setIncidents([]);
          const errMsg = JSON.stringify(err);
          const metricData = {
            errMsg,
            errorType: ERROR_INCIDENT_SEARCH,
          };
          trackFetchAllIncidentsError(metricData, cimpressADFSUserId);
        });
    }
  }, [cimpressADFSUserId, environment, isSignedIn]);

  useEffect(() => {
    if (searchIncidentId && environment && searchClicked) {
      setIncidents(null);
      fetchIncidentById(environment, searchIncidentId)
        .then(incidentData => setIncidents([incidentData]))
        .catch(err => {
          setInvalidIncidentIdError(true);
          setIncidents([]);
          const errMsg = JSON.stringify(err);
          const metricData = {
            errMsg,
            errorType: ERROR_INCIDENT_SEARCH,
          };
          trackSearchIncidentError(metricData, cimpressADFSUserId);
        });
    }
    setSearchClicked(false);
  }, [
    blankIncidentIdError,
    cimpressADFSUserId,
    environment,
    isSignedIn,
    searchClicked,
    searchIncidentId,
  ]);

  const searchForIncident = async () => {
    if (
      !searchIncidentId ||
      searchIncidentId === '' ||
      searchIncidentId.trim() === ''
    ) {
      setBlankIncidentIdError(true);
      return;
    }
    setBlankIncidentIdError(false);
    setInvalidIncidentIdError(false);
    setSearchClicked(true);
  };

  return isSignedIn && incidents ? (
    <BoundedContent>
      <GridContainer>
        <Row mt={5} mb={5}>
          <Typography component="h2" mt={4}>
            Incident Resubmission
          </Typography>
        </Row>
        <Row mb={5}>
          <Column>
            <Link href={lookerIncidentDashboardUrl} target="_blank" skin="cta">
              Incident Dashboard on Looker
            </Link>
          </Column>
        </Row>
        <Divider />
        <StandardForm variant="horizontal" mt={8}>
          <Row component={FormField} mb={5}>
            <Column span={2}>
              <FormLabel>Search by Incident Id</FormLabel>
            </Column>
            <Column span={8}>
              <FormInputGroup>
                <TextInput
                  value={searchIncidentId}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      searchForIncident();
                    }
                  }}
                  onChange={event => {
                    const input = event.target.value;
                    const isEmpty =
                      !input || input === '' || input.trim() === '';
                    setSearchIncidentId(input);
                    setBlankIncidentIdError(isEmpty);
                    setInvalidIncidentIdError(false);
                  }}
                />
                {blankIncidentIdError && (
                  <FormError>Incident Id required for search</FormError>
                )}
                {invalidIncidentIdError && (
                  <FormError>Incident Id not found!</FormError>
                )}
              </FormInputGroup>
            </Column>
            <Column>
              <Button
                disabled={blankIncidentIdError}
                skin="primary"
                onClick={() => {
                  searchForIncident();
                }}
              >
                Search
              </Button>
            </Column>
          </Row>
        </StandardForm>
        <Row mb={5}>
          <Column span={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Incident Id</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Grouped By</TableCell>
                  <TableCell>Assigned To</TableCell>
                  <TableCell>Number of failures</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {incidents.map(incident => (
                  <IncidentRow incident={incident} />
                ))}
              </TableBody>
            </Table>
          </Column>
        </Row>
      </GridContainer>
    </BoundedContent>
  ) : (
    <GridContainer>
      <Row mt={6}>
        <Typography component="h4" margin="auto">
          Fetching Unresolved Incidents ...
        </Typography>
        <BasicPreloader sizeVariant="large" centered my={6} />
      </Row>
    </GridContainer>
  );
};

export default IncidentReport;
