import React, { useState } from 'react';
import {
  Link,
  ModalDialog,
  TableCell,
  TableRow,
  Typography,
} from '@vp/swan';

import ReactJson from 'react-json-view';
import IncidentModal from './IncidentModal';

const IncidentRow = ({ incident }) => {
  const [isOpen, setIsOpen] = useState(false);

  const assigneeField = assignee => {
    if (assignee && assignee.name) {
      return assignee.name;
    }
    return '';
  };

  const failureCount = incident?.itemFailures.length;

  return (
    <>
      <TableRow>
        <TableCell onClick={() => setIsOpen(true)}>
          <Typography textSize={7}>
            <Link>{incident.type}</Link>
          </Typography>
        </TableCell>
        <TableCell onClick={() => setIsOpen(true)}>
          <Typography textSize={7}>
            <Link>{incident.id}</Link>
          </Typography>
        </TableCell>
        <TableCell>
          <Typography textSize={7}>
            {incident?.description}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography textSize={7}>
            <ReactJson
            collapsed={false}
            name={false}
            displayObjectSize={false}
            displayDataTypes={false}
            src={incident?.groupedBy}
            enableClipboard={false}
          />
          </Typography>
        </TableCell>
        <TableCell>
          <Typography textSize={7}>
            {assigneeField(incident?.assignee)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography textSize={7}>
            {failureCount}
          </Typography>
        </TableCell>
      </TableRow>
      <ModalDialog
        translucent
        takeOver
        isOpen={isOpen}
        onRequestDismiss={() => {
          setIsOpen(false);
        }}
      >
        <IncidentModal incident={incident} />
      </ModalDialog>
    </>
  );
};

export default IncidentRow;
