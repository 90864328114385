import { useQuery } from 'react-query';
import { getSiteConfig } from '../api/siteConfig';
import { GET_SITE_CONFIG } from '../constants/reactQueryKeys';

const useStagingTenantsFetch = () => {
  const { data: siteConfig } = useQuery([GET_SITE_CONFIG], () =>
    getSiteConfig()
  );

  const stagingTenantIds = [];

  if (siteConfig) {
    siteConfig.forEach(site => {
      if (
        site.index.environment === 'staging' &&
        site.index.brand === 'vistaprint'
      ) {
        if (!stagingTenantIds.includes(site.index.tenant)) {
          stagingTenantIds.push(site.index.tenant);
        }
      }
    });
  }

  return { stagingTenantIds };
};

export default useStagingTenantsFetch;
