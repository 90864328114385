import { Row, Typography, GridContainer } from '@vp/swan';
import React from 'react';
import PropTypes from 'prop-types';
import lineItemStatesDisplay from '../../common/lineItemStatesDisplay';

const LineItemPreview = ({ order }) =>
  order && order.lineItems ? (
    order.lineItems.map(item => (
      <GridContainer>
        {item.resubmittableItem ? (
          <Row mb={4}>
            <Typography weight="bold" align="left" textColor="brand-blue">
              ({item.quantity}): {item.productName}
            </Typography>
          </Row>
        ) : (
          <Row mb={4}>
            <Typography weight="bold" align="left" textColor="error">
              ({item.quantity}): {item.productName}
            </Typography>
          </Row>
        )}
        <Row margin="auto" mb={2}>
          <Typography textSize={6}> Id: {item.lineItemId}</Typography>
        </Row>
        <Row mb={4} margin="auto">
          <Typography textSize={6}>
            State(s): {lineItemStatesDisplay(item.currentStates)}
          </Typography>
        </Row>
      </GridContainer>
    ))
  ) : (
    <Typography>Could not find items for order {order.orderNumber}</Typography>
  );

LineItemPreview.propTypes = {
  order: PropTypes.object,
};

export default LineItemPreview;
