import { useContext } from 'react';
import { IdentityContext } from '../contexts/IdentityContext';

// https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/171245584/Custom+JWT+Claims
const canonicalIDClaim = 'https://claims.cimpress.io/canonical_id';
const cimpressInternalClaim = 'https://claims.cimpress.io/cimpress_internal';

const useIdentityContext = () => {
  const { isIdentityInitialized, identity, auth, permissions } = useContext(
    IdentityContext
  );

  // Build an identity container including only the information needed within the application.
  // The container either represents a valid logged in shopper, a valid logged in internal user, or an anonymous user.
  const identityContainer = {};
  if (isIdentityInitialized && identity && identity.isSignedIn) {
    identityContainer.isSignedIn = true;
    identityContainer.permissions = permissions;

    // internal user
    if (identity.profile[cimpressInternalClaim]) {
      identityContainer.cimpressADFSUserId = identity.profile[canonicalIDClaim];
      identityContainer.isInternalUser = true;
    }
  }

  return {
    isIdentityInitialized,
    identity: identityContainer,
    auth,
  };
};

export default useIdentityContext;
