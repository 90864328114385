import {
  Card,
  Column,
  GridContainer,
  Link,
  ModalDialog,
  ModalDialogBody,
  ModalDialogCloseButton,
  ModalDialogContent,
  ModalDialogHeader,
  ModalDialogTitle,
  Row,
  Button,
  Typography,
} from '@vp/swan';
import React, { useContext, useState } from 'react';
import { Link as RouterLink } from '@reach/router';
import Date from '../../../shared/Date';
import { InfoTypography } from '../../../shared/InfoTypography';
import { EnvironmentContext } from '../../../../contexts/EnvironmentContext';
import CollapsibleOrderJson from '../../common/CollapsibleOrderJson';
import { OrderConsoleContext } from '../../../../contexts/OrderConsoleContext';
import { buildMexUrl } from '../../common/mcpMexUrlBuilder';

export const OrderOverview = () => {
  const { order } = useContext(OrderConsoleContext);
  const { environment } = useContext(EnvironmentContext);
  const anon = order.orderPlacedAnonymously ? 'True' : 'False';
  const [isJsonOpen, setIsJsonOpen] = useState(false);
  const email =
    order.emailAddress ||
    order.billingAddress.email ||
    order.shippingAddress?.email ||
    undefined;

  const name = `${order.billingAddress.firstName} ${order.billingAddress.lastName}`;

  return (
    <>
      <GridContainer>
        <Card bordered evenHeight>
          <GridContainer cardDividers>
            <Row>
              <Column span={4}>
                <Typography weight="bold">Summary</Typography>
                <InfoTypography
                  label="Order Date"
                  valueToDisplay={<Date date={order.orderCreationDate} />}
                />
                <InfoTypography
                  label="Migrated Order"
                  valueToDisplay={order.migrated ? 'True' : 'False'}
                />
                <InfoTypography
                  label="Tenant"
                  valueToDisplay={order.tenantId}
                />
                <InfoTypography label="Site" valueToDisplay={order.siteName} />
                <InfoTypography
                  label="Order State"
                  valueToDisplay={order.state}
                />
              </Column>
              <Column span={4}>
                <Typography weight="bold">Shopper</Typography>
                <InfoTypography label="Id" valueToDisplay={order.shopperId} />
                <InfoTypography label="Name" valueToDisplay={name} />
                <InfoTypography label="Anonymous Order" valueToDisplay={anon} />
                {email && (
                  <InfoTypography label="Email" valueToDisplay={email} />
                )}
              </Column>
              <Column span={4}>
                <Button
                  sizeVariant="mini"
                  widthVariant="full-width"
                  mb={4}
                  onClick={() => setIsJsonOpen(true)}
                >
                  Order JSON
                </Button>
                <Button sizeVariant="mini" widthVariant="full-width" mb={4}>
                  <RouterLink
                    component="button"
                    to={`/orderVoyager/${environment}/${order.orderNumber}`}
                    style={{ color: 'inherit', textDecoration: 'inherit' }}
                  >
                    Event Timeline
                  </RouterLink>
                </Button>
                <Button sizeVariant="mini" widthVariant="full-width">
                  <Link
                    href={buildMexUrl(environment, [order])}
                    target="_blank"
                    style={{ color: 'inherit', textDecoration: 'inherit' }}
                  >
                    View Order in MCP
                  </Link>
                </Button>
              </Column>
            </Row>
          </GridContainer>
        </Card>
      </GridContainer>
      <ModalDialog
        translucent
        takeOver="true"
        isOpen={isJsonOpen}
        onRequestDismiss={() => setIsJsonOpen(false)}
      >
        <ModalDialogContent>
          <ModalDialogCloseButton />
          <ModalDialogHeader>
            <ModalDialogTitle>{order.orderNumber}</ModalDialogTitle>
          </ModalDialogHeader>
          <ModalDialogBody>
            <CollapsibleOrderJson order={order} />
          </ModalDialogBody>
        </ModalDialogContent>
      </ModalDialog>
    </>
  );
};
