import { Link, ModalDialog, TableCell, TableRow } from '@vp/swan';
import React, { useState } from 'react';
import OrderLinksModal from '../common/OrderLinksModal';

// Capitalization function.  Assumes at least 2 characters if not falsey.
const capitalize = s => (s ? s[0].toUpperCase() + s.slice(1) : '');
/**
 * Pull the payment out an array on an order that we want to display on a single-line display.
 * Prefer the one that's Paid or Pending.  Worst case, just return the first (or only) payment.
 * @param {*} pmtArr Array of payments (may be null or empty).
 * @returns A payment object, possibly empty.
 */
const getBestPayment = pmtArr => {
  if (!pmtArr || pmtArr.length === 0) {
    return {};
  }
  if (pmtArr.length === 1) {
    return pmtArr[0];
  }
  for (let i = 0; i < pmtArr.length; i += 1) {
    const pmt = pmtArr[i];
    if (pmt.state === 'Paid' || pmt.state === 'Pending') {
      return pmt;
    }
  }
  return pmtArr[0];
};

const paymentCount = pmtArr => {
  if (!pmtArr || pmtArr.length === 0) {
    return 0;
  }
  return pmtArr.length;
};

const OrderDetails = ({ order }) => {
  const [isOpen, setIsOpen] = useState(false);
  // The order field names used below MUST match the fields in the OWL API Order object!
  const createdDate = order.orderCreationDate?.substring(0, 10) || '-';
  const addr = order.shippingAddress || order.billingAddress || {};
  const fname = addr.firstName || '';
  const lname = addr.lastName || '';
  const fullName = `${fname} ${lname}`;
  const email = order.emailAddress || addr.email;
  const tenant = order.tenantId;
  // This displays info about the FIRST payment only, which may be misleading.  But the full object will show all.
  const pricing = order.pricingData || {};
  const totalPrice = Number(pricing?.discountedTotalCents?.taxed) || 0;
  const amtInCurrency = Number(totalPrice / 100).toFixed(2); // pmt.cents ? Number(pmt.cents / 100).toFixed(2) : '0';
  const amount = order.currencyCode
    ? `${order.currencyCode} ${amtInCurrency}`
    : '-';
  // Display any payment info we have.
  const pmt = getBestPayment(order.payments);
  const pmtCnt = paymentCount(order.payments);
  const pmtType = pmt.paymentMethod || '';
  const pmtMulti = pmtCnt > 1 ? '*' : '';
  let pmtInfo = '';
  if (pmtType) {
    pmtInfo = `${pmtMulti} (${capitalize(pmtType)})`;
  }

  return (
    <>
      <TableRow>
        <TableCell onClick={() => setIsOpen(true)}>
          <Link>{order.orderNumber}</Link>
        </TableCell>
        <TableCell>{email}</TableCell>
        <TableCell>{fullName}</TableCell>
        <TableCell>{tenant}</TableCell>
        <TableCell>
          {amount}
          {pmtInfo}
        </TableCell>
        <TableCell>{createdDate}</TableCell>
      </TableRow>
      <ModalDialog
        translucent
        takeOver="true"
        isOpen={isOpen}
        onRequestDismiss={() => setIsOpen(false)}
      >
        <OrderLinksModal order={order} />
      </ModalDialog>
    </>
  );
};

export default OrderDetails;
