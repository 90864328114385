/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useState } from 'react';
import {
  FlexBox,
  Box,
  Button,
  Link,
  Icon,
  AlertBox,
  Spinner,
  Typography,
  SkeletonText,
} from '@vp/swan';
import { Link as RouterLink } from '@reach/router';

import { OrderCopyContext } from '../../../../contexts/OrderCopyContext';
import { InfoTypographyWithSkeleton } from '../../../shared/InfoTypography';
import { EnvironmentContext } from '../../../../contexts/EnvironmentContext';

const SourceOrderPreview = () => {
  const { environment } = useContext(EnvironmentContext);

  const {
    sourceOrder,
    sourceOrderNumber,
    isOrderFetchLoading,
    isOrderFetchError,
  } = useContext(OrderCopyContext);

  const [showOrderJson, setShowOrderJson] = useState(false);

  if (isOrderFetchLoading) {
    return (
      <FlexBox justifyContent="center">
        <Spinner accessibleText="Loading order preview..." />
      </FlexBox>
    );
  }

  if (isOrderFetchError) {
    return <AlertBox skin="error">Order not found 👀</AlertBox>;
  }

  return (
    <Box textAlign="left">
      <SkeletonText>
        <InfoTypographyWithSkeleton
          label="Order number"
          valueToDisplay={sourceOrderNumber}
        />
        <InfoTypographyWithSkeleton
          label="Shopper ID"
          valueToDisplay={sourceOrder?.shopperId}
        />
        <InfoTypographyWithSkeleton
          label="Email"
          valueToDisplay={
            sourceOrder?.emailAddress ||
            sourceOrder?.billingAddress?.email ||
            sourceOrder?.shippingAddress?.email
          }
          hideEmptyValue
        />
        <InfoTypographyWithSkeleton
          label="Tenant"
          valueToDisplay={sourceOrder?.tenantId}
        />
        {sourceOrder && (
          <>
            <Link
              skin="unstyled"
              render={p => (
                <Button
                  skin="link"
                  my={2}
                  onClick={() => setShowOrderJson(!showOrderJson)}
                  className={p.className}
                >
                  {p.children}
                </Button>
              )}
            >
              <Typography fontSize={1}>
                <b>Order JSON </b>
                {showOrderJson ? (
                  <Icon iconType="minus" size="16p" alt="" />
                ) : (
                  <Icon iconType="plus" size="16p" alt="" />
                )}
              </Typography>
            </Link>
            {showOrderJson && (
              <pre
                style={{
                  fontSize: '12px',
                  overflow: 'auto',
                  color: 'dark-gray',
                  fontWeight: 'bold',
                  padding: '16px',
                  marginTop: '8px',
                  backgroundColor: 'transparent',
                  height: '400px',
                }}
              >
                {JSON.stringify(sourceOrder, null, 2)}
              </pre>
            )}
            <FlexBox justifyContent="flex-end" alignItems="stretch">
              <Box>
                <RouterLink
                  to={`/orderConsole/${environment}/${sourceOrder.orderNumber}`}
                  style={{ width: '75%', textDecoration: 'none' }}
                >
                  <Button size="mini">
                    View in console
                    <img
                      src="https://static.thenounproject.com/png/299638-200.png"
                      width="24p"
                      alt=""
                      style={{ paddingLeft: '5px' }}
                    />
                  </Button>
                </RouterLink>
              </Box>
            </FlexBox>
          </>
        )}
      </SkeletonText>
    </Box>
  );
};

export default SourceOrderPreview;
