import React from 'react';
import {
  Column,
  ControlIcon,
  Checkbox,
  FlexBox,
  PopoverLauncher,
  Popover,
  PopoverContent,
  SelectionSet,
  Typography,
} from '@vp/swan';

const AdvancedLookupCheckbox = ({
  label,
  initialValue,
  setValueFn,
  tooltipText,
  span,
  offset,
}) => (
  <Column offset={offset} span={span}>
    <FlexBox
      flexDirection="column"
      flexWrap="initial"
      justifyContent="space-between"
      mt={6}
    >
      <SelectionSet variant="multi-select" skin="simple-column">
        <Checkbox
          skin="buttons"
          value={initialValue}
          onChange={() => {
            const usingP = initialValue;
            setValueFn(!usingP);
          }}
          displayName="Yo"
        />
        <Typography ml={1}>{label}</Typography>
        <Popover position="above">
          <PopoverLauncher>
            <ControlIcon skin="standard" iconType="info" ml={2} />
          </PopoverLauncher>
          <PopoverContent>{tooltipText}</PopoverContent>
        </Popover>
      </SelectionSet>
    </FlexBox>
  </Column>
);

export default AdvancedLookupCheckbox;
