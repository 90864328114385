import React, { useContext } from 'react';
import {
  FlexBox,
  Box,
  Button,
  Icon,
  Popover,
  PopoverLauncher,
  PopoverContent,
  SkeletonText,
  Callout,
} from '@vp/swan';
import { Link as RouterLink } from '@reach/router';
import { API_STATUS } from '../../../constants/apiStatus';
import { OrderCopyContext } from '../../../contexts/OrderCopyContext';
import { InfoTypographyWithSkeleton } from '../../shared/InfoTypography';

const OrderCopyResult = () => {
  const { orderCopyStatus, targetOrderNumber } = useContext(OrderCopyContext);

  const orderCopyStatusUxMap = {
    [API_STATUS.IDLE]: {
      imgSrc: '',
      color: 'dark-gray',
    },
    [API_STATUS.PENDING]: {
      imgSrc:
        'https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2Fc6239c07-0aea-4b63-b41e-508299518081_400x300.gif',
      color: 'kingfisher',
    },
    [API_STATUS.SUCCESS]: {
      imgSrc: 'https://media.tenor.com/xylSdHmk90gAAAAC/excited-seinfeld.gif',
      color: 'medium-green',
    },
    [API_STATUS.FAILED]: {
      imgSrc:
        'https://64.media.tumblr.com/3f53515bd51948deb93022ea9e81aa0e/tumblr_ml1r8maIBB1s99m4bo1_400.gif',
      color: 'mandarin',
    },
  };

  return (
    <SkeletonText>
      <InfoTypographyWithSkeleton
        label="Status"
        valueToDisplay={
          <Callout
            backgroundColor={orderCopyStatusUxMap[orderCopyStatus].color}
            textColor="white"
            size="mini"
            px={4}
            border="none"
          >
            {orderCopyStatus}
          </Callout>
        }
      />

      <InfoTypographyWithSkeleton
        label="Order number"
        valueToDisplay={targetOrderNumber}
      />

      {orderCopyStatus === API_STATUS.SUCCESS && (
        <FlexBox flexDirection="column" alignItems="flex-end">
          <Popover>
            <PopoverLauncher>
              <Button
                size="mini"
                my={1}
                onClick={() => {
                  navigator.clipboard.writeText(targetOrderNumber);
                }}
              >
                Copy order number
                <Icon
                  iconType="saveAsCopy"
                  size="24p"
                  skin="standard"
                  aria-label="Copy order number to clipboard"
                />
              </Button>
            </PopoverLauncher>
            <PopoverContent>Copied to clipboard!</PopoverContent>
          </Popover>

          <RouterLink
            to={`/orderConsole/vistaprint-staging/${targetOrderNumber}`}
            style={{ textDecoration: 'none' }}
          >
            <Button size="mini" my={1}>
              View in console
              <img
                src="https://static.thenounproject.com/png/299638-200.png"
                width="24p"
                alt=""
                style={{ paddingLeft: '5px' }}
              />
            </Button>
          </RouterLink>
        </FlexBox>
      )}

      {orderCopyStatusUxMap[orderCopyStatus].imgSrc ? (
        <Box textAlign="center" my={4}>
          <img
            src={orderCopyStatusUxMap[orderCopyStatus].imgSrc}
            alt=""
            style={{ width: '300px' }}
          />
        </Box>
      ) : null}
    </SkeletonText>
  );
};

export default OrderCopyResult;
