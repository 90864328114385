import React from 'react';

import {
  Column,
  List,
  ListItem,
  Row,
  Typography,
  GridContainer,
} from '@vp/swan';
import PropTypes from 'prop-types';
import {
  ERROR_CSV_DATA_ISSUE,
  ERROR_ORDER_LIMIT_EXCEEDED,
  RESUBMIT_ORDERS_LIMIT,
} from './resubmissionErrorTypes';

const ErrorPanel = ({ errorType, errorData }) => {
  let errorTitle = 'Unexpected error occurred!';
  if (errorType === ERROR_ORDER_LIMIT_EXCEEDED) {
    errorTitle = `Too many orders were attempted at once. We only allow ${RESUBMIT_ORDERS_LIMIT} orders to be resubmit at once. Please batch up your submissions.`;
  } else if (errorType === ERROR_CSV_DATA_ISSUE) {
    errorTitle =
      'There was an error in your CSV data format, make sure your CSV data format matches our example. Please see the list of errors to fix.';
  }
  return (
    <GridContainer>
      <Row>
        <Column>
          <Typography component="h4" mt={4} textColor="error">
            Uh oh! Looks like there was some issues with your CSV data.
          </Typography>
          <Typography component="h5" mt={4} textColor="error">
            Please refresh and try again with a valid file.
          </Typography>
        </Column>
      </Row>
      <Row>
        <Column>
          <Typography>{errorTitle}</Typography>
        </Column>
      </Row>
      {errorData && (
        <>
          <Row mt={3}>
            <Column>
              <Typography component="h5">Errors:</Typography>
            </Column>
          </Row>
          <Row>
            <Column>
              <List>
                {errorData.map(error => (
                  <ListItem>{error}</ListItem>
                ))}
              </List>
            </Column>
          </Row>
        </>
      )}
    </GridContainer>
  );
};

ErrorPanel.propTypes = {
  errorType: PropTypes.string,
  errorData: PropTypes.array,
};

export default ErrorPanel;
