// Pricing Breakdown accessors
export const TAXED = 'taxed';
export const UNTAXED = 'untaxed';

export const TOTAL_LIST_PRICE = 'totalListPriceCents';
export const TOTAL_DISCOUNTED_PRICE = 'totalDiscountedPriceCents';
export const BREAKDOWN = 'breakdown';
export const ADDITIONAL_FEES = 'additionalFees';
export const FEES = 'fees';
export const FEE_TYPE = 'feeType';

// Attribute
export const NAME = 'name';
export const VALUE = 'value';
export const LIST_PRICE = 'listPriceCents';
export const DISCOUNTED_PRICE = 'discountedPriceCents';
export const BASE_PRICE_NAME = 'Base Price';
export const DISPLAY_NAME = 'keyDisplayName';
export const DISPLAY_VALUE = 'valueDisplayName';

// Item Level Accessors
export const ITEM_STATES = 'states'; // used
