import React from 'react';
import CSVReader from 'react-csv-reader';
import { Column, Row, Typography } from '@vp/swan';
import PropTypes from 'prop-types';

const CSVUploader = ({ csvReaderOptions, onFileUpload }) => (
  <>
    <Row mt={7}>
      <Column>
        <Typography component="h4" mt={4}>
          Upload your file here:
        </Typography>
      </Column>
    </Row>
    <Row mb={5}>
      <Column>
        <CSVReader
          parserOptions={csvReaderOptions}
          onFileLoaded={data => {
            onFileUpload(data);
          }}
        />
      </Column>
    </Row>
  </>
);

CSVUploader.propTypes = {
  csvReaderOptions: PropTypes.object,
  onFileUpload: PropTypes.func,
};

export default CSVUploader;
