import { Dropdown, DropdownOption } from '@vp/swan';
import React, { useContext } from 'react';
import { EnvironmentContext } from '../contexts/EnvironmentContext';

const EnvDropdown = () => {
  const { setEnvironment, environment } = useContext(EnvironmentContext);
  return (
    <Dropdown
      value={environment}
      onChange={event => setEnvironment(event.target.value)}
    >
      <DropdownOption hidden disabled>
        select an environment to run
      </DropdownOption>
      <DropdownOption value="vistaprint-production">Production</DropdownOption>
      <DropdownOption value="vistaprint-staging">Staging</DropdownOption>
    </Dropdown>
  );
};

export default EnvDropdown;
