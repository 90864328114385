import { Column, Typography } from '@vp/swan';
import React, { useContext } from 'react';
import { AddressDisplay } from '@vp/address-form/src/components/AddressDisplay';
import { InfoTypography } from '../../../../shared/InfoTypography';
import Date from '../../../../shared/Date';
import { getPromisedArrivalDateRange } from '../../../../../utils/lineItemListUtils';
import { OrderConsoleContext } from '../../../../../contexts/OrderConsoleContext';
import { EdoDetailsModal } from './EdoDetailsModal';
import { PickupPointDisplay } from './PickupPointDisplay';

export const ShipmentDetails = ({ columSpan }) => {
  const { order } = useContext(OrderConsoleContext);
  const PROMISED_ARRIVAL = 'Promised Arrival';

  const isPickupPoint = order.shippingAddress?.type === 'pickupPoint';

  const shippingData = order.orderShippingData;
  const arrivalDateRange = getPromisedArrivalDateRange(order.lineItems);

  const earliestArrivalDate = arrivalDateRange?.earliestArrivalDate;
  const latestArrivalDate = arrivalDateRange?.latestArrivalDate;

  const showDate = earliestArrivalDate;
  const evaluationUrl = order.customOrderData?.shipping?.evaluationUrl;

  const shippingMethodDisplayValue = shippingData?.shippingMethodId
    ? `${shippingData.name} | ${shippingData.shippingMethodId}`
    : `${shippingData.name}`;

  const showDateRange =
    earliestArrivalDate &&
    latestArrivalDate &&
    !earliestArrivalDate.isSame(latestArrivalDate, 'day');

  const showDeliveryDate = () => {
    // If items arrive on different days, show the date range
    if (showDate && showDateRange) {
      const dateDisplayRangeValue =
        <Date date={earliestArrivalDate.toString()} /> -
        <Date date={latestArrivalDate.toString()} />;

      return (
        <InfoTypography
          label={PROMISED_ARRIVAL}
          valueToDisplay={dateDisplayRangeValue}
        />
      );
    }
    // If all items arrive on the same day, show that date
    if (showDate && !showDateRange) {
      return (
        <InfoTypography
          label={PROMISED_ARRIVAL}
          valueToDisplay={<Date date={earliestArrivalDate.toString()} />}
        />
      );
    }
    // If the arrival date is unknown or nonexistent (digital orders), show disclaimer
    return (
      <InfoTypography
        label={PROMISED_ARRIVAL}
        valueToDisplay="No delivery speeds"
      />
    );
  };

  return (
    <>
      {shippingData && (
        <Column span={columSpan}>
          <Typography weight="bold">Shipping Data</Typography>
          <InfoTypography
            label="Method"
            valueToDisplay={shippingMethodDisplayValue}
          />
          <InfoTypography
            label="Business Days"
            valueToDisplay={shippingData?.businessDays}
          />
          {showDeliveryDate()}
          {evaluationUrl && <EdoDetailsModal evaluationUrl={evaluationUrl} />}
        </Column>
      )}
      {isPickupPoint ? (
        <Column span={columSpan}>
          <PickupPointDisplay pickupPointAddressData={order.shippingAddress} />
        </Column>
      ) : (
        <Column span={columSpan}>
          <Typography weight="bold" data-testid="card-title">
            Shipping Address
          </Typography>
          <AddressDisplay address={order.shippingAddress} locale="en-US" />
        </Column>
      )}
    </>
  );
};
