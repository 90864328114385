import React, { useContext } from 'react';
import {
  FlexBox,
  SelectionSet,
  SelectionSetInput,
  SelectionSetLabel,
  SecondaryTile,
  SecondaryTileContents,
  SecondaryTileName,
  SecondaryTileDescription,
  BasicCollapsible,
  GridContainer,
  Row,
  Column,
} from '@vp/swan';
import { orderCatalogOptions } from '../../../../constants/orderCatalogOptions';
import OrderCategoryTag from './OrderCategoryTag';
import { OrderCopyContext } from '../../../../contexts/OrderCopyContext';

const SourceOrderCatalog = () => {
  const { sourceOrderNumber, updateSourceOrder } = useContext(OrderCopyContext);

  return (
    <BasicCollapsible
      collapsibleId="order-copy-catalog"
      heading="Select from catalog"
      fullBleed
    >
      <div style={{ maxHeight: '400px', overflowY: 'scroll', padding: '8px' }}>
        <SelectionSet
          variant="single-select"
          selectedValue={sourceOrderNumber}
          onSelectedValueChange={newSelectedValue => {
            updateSourceOrder({ newOrderNumber: newSelectedValue });
          }}
        >
          <GridContainer>
            <Row>
              {Object.values(orderCatalogOptions).map(catalogOrder => (
                <Column span={12} key={catalogOrder.orderNumber}>
                  <SelectionSetInput value={catalogOrder.orderNumber}>
                    <SelectionSetLabel>
                      <SecondaryTile as="span" layout="horizontal">
                        <SecondaryTileContents as="div">
                          <SecondaryTileName as="div" fontWeight="bold">
                            {catalogOrder.orderNumber}
                          </SecondaryTileName>
                          <SecondaryTileDescription as="div">
                            <FlexBox
                              justifyContent="flex-start"
                              flexWrap="wrap"
                            >
                              <OrderCategoryTag
                                tagName={catalogOrder.tenant}
                                key={`${catalogOrder.orderNumber}-${catalogOrder.tenant}-tag`}
                                isTenant
                              />
                              {catalogOrder.tags.map(tag => (
                                <OrderCategoryTag
                                  tagName={tag}
                                  key={`${catalogOrder.orderNumber}-${tag}-tag`}
                                />
                              ))}
                            </FlexBox>
                          </SecondaryTileDescription>
                        </SecondaryTileContents>
                      </SecondaryTile>
                    </SelectionSetLabel>
                  </SelectionSetInput>
                </Column>
              ))}
            </Row>
          </GridContainer>
        </SelectionSet>
      </div>
    </BasicCollapsible>
  );
};

export default SourceOrderCatalog;
