import React, { useContext } from 'react';
import {
  BasicPreloader,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Typography,
} from '@vp/swan';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
import { OrderTimelineContext } from '../../../contexts/OrderTimelineContext';

/**
 * Simple milestone/event timeline in a grid format
 * @param visualizationData
 * @returns {JSX.Element}
 * @constructor
 */
const SimpleTimeline = ({ visualizationData }) => {
  const { isLoading } = useContext(OrderTimelineContext);

  // build list of events from milestones
  const events = [];
  // eslint-disable-next-line no-unused-expressions

  visualizationData &&
    visualizationData.milestones &&
    visualizationData.milestones.forEach(m =>
        m.events && m.events.forEach(e => {
        const eventItemId = e.itemInfo[0] && e.itemInfo[0].itemId;
        const itemHidden = visualizationData.groups.find(
          g => g.id === eventItemId && g.visible === false
        );
        if (!itemHidden) {
          events.push(e);
        }
      })
    );

  function getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()];
  }

  const sortedEvents = getUniqueListBy(events, 'id').sort(
    (a, b) => new Date(b.dateTime) - new Date(a.dateTime)
  );

  return (
    <div>
      {!isLoading ? (
        <div>
          {visualizationData && (
            <>
              <Typography component="h3" mt={4}>
                Simple Timeline
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Event</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Line Item</TableCell>
                    <TableCell>Source</TableCell>
                    <TableCell>Event Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedEvents.map(event => (
                    <TableRow>
                      <TableCell>
                        <Typography textSize={7}>{event.type}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography textSize={7}>{event.dateTime}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography textSize={7}>
                          {event.itemInfo[0] && event.itemInfo[0].itemId}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography textSize={7}>{event.source}</Typography>
                      </TableCell>
                      <TableCell>
                        <ReactJson
                          collapsed
                          name={false}
                          displayObjectSize={false}
                          displayDataTypes={false}
                          src={event}
                          enableClipboard
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}
        </div>
      ) : (
        <BasicPreloader sizeVariant="large" centered my={6} />
      )}
    </div>
  );
};

SimpleTimeline.propTypes = {
  visualizationData: PropTypes.object,
};

export default SimpleTimeline;
