import React from 'react';
import {
  BasicCollapsible,
  Column,
  GridContainer,
  Row,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@vp/swan';
import PropTypes from 'prop-types';
import LineItemPreview from './LineItemPreview';
import partitionResubmittableOrders from '../partitionResubmittableOrders';

const countResubmitItems = order => {
  let resubmitCnt = 0;
  let totalItems = 0;
  order.lineItems.forEach(lineItem => {
    if (lineItem.resubmittableItem) {
      resubmitCnt += 1;
    }
    totalItems += 1;
  });
  return { resubmitCnt, totalItems };
};

const OrderPreviewFailures = ({ orders }) => (
  <Table skin="simple">
    <TableHead>
      <TableRow>
        <TableCell>Order Number</TableCell>
        <TableCell>Reason</TableCell>
        <TableCell>Error</TableCell>
      </TableRow>
    </TableHead>

    {orders.map(order => (
      <TableBody>
        <TableRow>
          <TableCell>{order.orderNumber}</TableCell>
          <TableCell>{order.remediationReason}</TableCell>
          <TableCell>{order.error.message}</TableCell>
        </TableRow>
      </TableBody>
    ))}
  </Table>
);

const OrderPreviewSuccesses = ({ orders }) => (
  <Table skin="simple">
    <TableHead>
      <TableRow>
        <TableCell>Order Number</TableCell>
        <TableCell>Reason</TableCell>
      </TableRow>
    </TableHead>

    {orders.map(order => {
      const { resubmitCnt, totalItems } = countResubmitItems(order);
      return (
        <TableBody>
          <TableRow>
            <TableCell>
              <BasicCollapsible
                heading={`${order.orderNumber} (${resubmitCnt}/${totalItems} Line Items to resubmit)`}
              >
                <LineItemPreview order={order} />
              </BasicCollapsible>
            </TableCell>
            <TableCell>{order.remediationReason}</TableCell>
          </TableRow>
        </TableBody>
      );
    })}
  </Table>
);

const ResubmitPreview = ({ orders }) => {
  if (orders && orders.length > 0) {
    const { resubmittable, notResubmittable } = partitionResubmittableOrders(
      orders
    );
    return (
      <GridContainer>
        <Row>
          <Column span={6}>
            <Typography component="h4" textColor="error">
              Orders Unable To Be Resubmitted
            </Typography>
            <OrderPreviewFailures orders={notResubmittable} />
          </Column>
          <Column span={6}>
            <Typography component="h4">Orders To Be Resubmitted</Typography>
            <OrderPreviewSuccesses orders={resubmittable} />
          </Column>
        </Row>
      </GridContainer>
    );
  }
};

ResubmitPreview.propTypes = {
  orders: PropTypes.array,
};

OrderPreviewSuccesses.propTypes = {
  orders: PropTypes.array,
};

OrderPreviewFailures.propTypes = {
  orders: PropTypes.array,
};

export default ResubmitPreview;
