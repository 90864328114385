/* Takes in a string or an array of states in the format [{state: "sampleState", quantity: #}] and creates a comma separated string of all states present */

export default function lineItemStatesDisplay(states) {
  let output = '';
  if (!states) {
    return;
  }
  if (!Array.isArray(states)) {
    return states;
  }
  states.forEach(stateQuantity => {
    output =
      output !== ''
        ? (output += `, ${stateQuantity.state}`)
        : stateQuantity.state;
  });
  return output;
}
