import axios from 'axios';

const siteTenantConfigUrl =
  'https://site-tenant-configuration.s3-eu-west-1.amazonaws.com/site-configurations';

export const getSiteConfig = () =>
  axios
    .get(siteTenantConfigUrl)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
