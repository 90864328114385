import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Typography, BasicPreloader } from '@vp/swan';
import PropTypes from 'prop-types';
import Timeline from 'react-visjs-timeline';
import ReactDOM, { createPortal } from 'react-dom';
import ItemTemplate from './ItemTemplate';
import GroupTemplate from './GroupTemplate';
import { OrderTimelineContext } from '../../../contexts/OrderTimelineContext';

/**
 * Wrapper for the {@link Timeline} that receives the timelineData
 * needed to generate the timeline
 * @param timelineData
 * @returns {JSX.Element}
 * @constructor
 */
const OrderTimelineViz = ({ visualizationData, iconsOnly }) => {
  let groupItems = [];
  let groups = [];
  let earliestEvent = '';
  let latestEvent = '';
  let maxTimelineDate = '';
  let minTimelineDate = '';
  const [timelinePos, setTimelinePos] = useState(Date.now);
  // eslint-disable-next-line no-unused-vars
  const [start, setStart] = useState(Date.now);
  const { isLoading } = useContext(OrderTimelineContext);

  const timelineRef = React.createRef();

  // defines the sort order of the items of a group
  function sortItemsByDateTime(a, b) {
    return a.dateTime - b.dateTime;
  }

  function needsHolds(items) {
    for (const i of items) {
      if (i.isBackground) return false;
    }

    return true;
  }

  if (visualizationData) {
    // eslint-disable-next-line prefer-destructuring
    groups = visualizationData.groups;
    // eslint-disable-next-line prefer-destructuring
    groupItems = visualizationData.milestones;

    // alert! lazy assumption that milestones are sorted
    if (groupItems.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      earliestEvent = groupItems[0];
      latestEvent = groupItems[groupItems.length - 1];
    }
    // loop through the events and identify the earliest and latest occurring
    for (const item of groupItems) {
      if (!item.isBackground && item.start < earliestEvent.start) {
        earliestEvent = item;
      }

      if (!item.isBackground && item.start > latestEvent.start) {
        latestEvent = item;
      }
    }

    // mark the max/min of the timeline to be the 1 days before/after the min/max dates
    // this provides a little bit of padding to the timeline
    const earliestDate = new Date(earliestEvent.start);
    const latestDate = new Date(latestEvent.start);

    minTimelineDate = earliestDate.setDate(earliestDate.getDate() - 2);
    maxTimelineDate = latestDate.setDate(latestDate.getDate() + 2);

    // TODO: need to populate this for some reason so the items display
    // add light-red background for held items
    const backgroundItems = [];
    const needHoldsAdded = needsHolds(groupItems);
    groupItems.forEach(g => {
      g.type = 'box';
      if (needHoldsAdded && g.milestoneDisplayType === 'LONG_RUNNING') {
        backgroundItems.push({
          group: g.group,
          isBackground: true,
          start: g.start,
          end: g.end ? g.end : Date.now(),
          type: 'background',
          style: 'background-color: mistyrose',
          milestoneType: {
            displayName: '',
            name: 'background',
          },
        });
      }
    });

    if (backgroundItems) {
      backgroundItems.forEach(i => {
        groupItems.push(i);
      });
    }
  }

  // given a date, return the number corresponding to its day of the year
  function dayOfYear(date) {
    return Math.floor(
      (date - new Date(date.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24
    );
  }

  // given a date and an interval (defined as the values of the timeAxis.scale in the visjs timeline),
  // return a numeric value for the position of that date in increments of that interval. For example, the
  // second month of the year is 2, the fifth week of the year is 5. Defaults to returning minutes
  function getIncrement(date, interval) {
    const incomingDate = new Date(date);

    switch (interval) {
      case 'month':
        return incomingDate.getMonth();
      case 'weekday':
      case 'week':
      case 'day':
        return dayOfYear(incomingDate);
      case 'hour':
        return incomingDate.getHours();
      default:
        return incomingDate.getTime() / 60 / 1000;
    }
  }

  // snap to one end or the other of the timeline
  function extremityHandler(leftEnd) {
    if (leftEnd) {
      timelineRef.current.$el.focus(earliestEvent.id);

      setTimelinePos(new Date(earliestEvent.start));
    } else {
      timelineRef.current.$el.focus(latestEvent.id);

      setTimelinePos(new Date(latestEvent.start));
    }
  }

  // move to the next interesting event according to the timeAxis.step.scale to which the user has zoomed.
  function nextHandler(forward) {
    // get the date of the position on which the timeline is currently focussed
    const original = new Date(timelinePos);
    // how far did the user zoom in? (week, month, day)
    const currentIncrement = timelineRef.current.$el.timeAxis.step.scale;
    // position in zoom scale (eg, Feb 5, 2020 is 2 if zoomed to months, 36 if zoomed to days, 6 if zoomed to weeks)
    const originalIncrement = getIncrement(original, currentIncrement);

    // first we determine which end of the timeline we're headed toward
    let currentPos = forward
      ? new Date(latestEvent.start)
      : new Date(earliestEvent.start);

    // find the next event on the timeline in the current scale (next event occuring on a day/week/month boundary, depending on zoom)
    let currentItem = groupItems[0];
    for (const item of timelineRef.current.props.items) {
      const itemDate = new Date(item.start);
      const thisIncrement = getIncrement(itemDate, currentIncrement);

      // do the math depending on which direction we're heading
      const difference = forward
        ? thisIncrement - originalIncrement
        : originalIncrement - thisIncrement;
      if (difference > 0 && itemDate < currentPos) {
        currentPos = itemDate;
        currentItem = item;
      }
    }

    // set the timeline to focus on the newly selected event, and update the timeline position
    // so the timeline will re-render
    timelineRef.current.$el.focus(currentItem.id);
    setTimelinePos(new Date(currentPos));
  }

  useEffect(() => {
    setStart(timelinePos);
  }, [setStart, timelinePos, timelineRef]);

  const options = {
    order: sortItemsByDateTime,
    width: '100%',
    stack: true,
    showMajorLabels: true,
    showCurrentTime: true,
    zoomMin: 60000, // 5 mins
    zoomMax: 7884000000000, // 3 months
    type: 'background',
    start: earliestEvent.start,
    end: latestEvent.start,
    groupHeightMode: 'fitItems',
    orientation: 'both',
    format: {
      minorLabels: {
        minute: 'h:mma',
        hour: 'ha',
      },
    },
    margin: {
      item: {
        vertical: 50,
      },
      axis: 40,
    },
    horizontalScroll: true,
    verticalScroll: true,
    zoomKey: 'ctrlKey',
    template(item, element) {
      if (!item) {
        return;
      }
      return createPortal(
        ReactDOM.render(
          <ItemTemplate item={item} iconsOnly={iconsOnly} />,
          element
        ),
        element,
        () => {
          window.timeline.redraw();
        }
      );
    },
    groupTemplate(group, element) {
      if (!group) {
        return;
      }
      return ReactDOM.createPortal(
        ReactDOM.render(<GroupTemplate group={group} />, element),
        element,
        () => {
          window.timeline.redraw();
        }
      );
    },
    min: minTimelineDate,
    max: maxTimelineDate,
  };

  return (
    <div>
      {!isLoading ? (
        <div>
          {visualizationData && (
            <>
              <Typography component="h3" mt={4}>
                Timeline
              </Typography>
              <Timeline
                ref={timelineRef}
                options={options}
                groups={groups}
                items={groupItems}
              />
              <Box mt={3}>
                <Button onClick={() => extremityHandler(true)}>Start</Button>
                <Button onClick={() => nextHandler(false)}>Previous</Button>
                <Button onClick={() => nextHandler(true)}>Next</Button>
                <Button onClick={() => extremityHandler(false)}>End</Button>
              </Box>
            </>
          )}
        </div>
      ) : (
        <BasicPreloader sizeVariant="large" centered my={6} />
      )}
    </div>
  );
};

OrderTimelineViz.propTypes = {
  visualizationData: PropTypes.object,
};

export default OrderTimelineViz;
