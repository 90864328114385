/**
 * Example: en_IE to en-IE. Use en-IE by default.
 * https://gitlab.com/vistaprint-org/order-management/libraries2/om-currency
 * @param {*} locale is the configuration which contains the
 * site locale which needs to be converted
 */
export const currencyLocaleFormatter = locale => {
  if (locale) {
    return locale.replace('_', '-');
  }
  return 'en-IE';
};
