import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  BasicCollapsible,
} from '@vp/swan';
import LineItemResubmitStatus from './LineItemResubmitStatus';

const ResubmissionSuccessResultsTable = ({ successfullyResubmittedOrders }) =>
  successfullyResubmittedOrders &&
  successfullyResubmittedOrders.length > 0 && (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Order Number</TableCell>
        </TableRow>
      </TableHead>
      {successfullyResubmittedOrders.map(order => (
        <TableBody>
          <TableRow>
            <TableCell>
              <BasicCollapsible heading={order.orderNumber}>
                <LineItemResubmitStatus lineItems={order.lineItems} />
              </BasicCollapsible>
            </TableCell>
          </TableRow>
        </TableBody>
      ))}
    </Table>
  );
ResubmissionSuccessResultsTable.propTypes = {
  successfullyResubmittedOrders: PropTypes.array,
};

export default ResubmissionSuccessResultsTable;
