import {
  BoundedContent,
  Column,
  GridContainer,
  Link,
  Row,
  Typography,
} from '@vp/swan';
import React, { useContext } from 'react';
import { EnvironmentContext } from '../../../contexts/EnvironmentContext';

const MissingPermissions = ({ permissionName }) => {
  const { environment } = useContext(EnvironmentContext);
  return (
    <BoundedContent mb={7}>
      <GridContainer>
        <Row>
          <Column>
            <Typography component="h2" mt={4}>
              Missing Permissions
            </Typography>
            <Typography>
              You do not have access to this tool because you are missing{' '}
              {permissionName} permissions for the {environment} environment
            </Typography>
          </Column>
        </Row>
        <Row>
          <Column>
            <Typography component="h4" mt={8}>
              Request access
            </Typography>
            <Typography>
              Please contact{' '}
              <Link
                skin="cta"
                target="_blank"
                href="https://vistaprint.slack.com/archives/CNFFZMUD6"
              >
                LochNess Squad
              </Link>{' '}
            </Typography>
          </Column>
        </Row>
      </GridContainer>
    </BoundedContent>
  );
};
export default MissingPermissions;
