import React, { useContext, useEffect, useState } from 'react';
import {
  AlertBox,
  BasicPreloader,
  BoundedContent,
  Column,
  FormError,
  FormField,
  FormInputGroup,
  FormLabel,
  FormLabelOptional,
  GridContainer,
  Link,
  ModalDialogBody,
  ModalDialogCloseButton,
  ModalDialogContent,
  ModalDialogHeader,
  ModalDialogTitle,
  Row,
  StandardForm,
  Button,
  TextInput,
  Typography,
  BasicCollapsible,
} from '@vp/swan';

import ReactJson from 'react-json-view';
import { EnvironmentContext } from '../../../contexts/EnvironmentContext';
import {
  ERROR_INCIDENT_RESUBMISSION_ISSUE,
  ERROR_NO_UNRESOLVED_ORDERS,
} from '../resubmission/resubmissionErrorTypes';
import {
  trackResubmissionComplete,
  trackResubmissionIncidentIssue,
  trackResubmissionStarted,
} from '../../../api/newRelicTrackingApi';
import useIdentityContext from '../../../hooks/useIdentityContext';
import {
  initiateResubmit,
  resubmit,
} from '../../../api/backendOrderRemediationToolService';
import IncidentErrorPanel from './IncidentErrorPanel';
import OrderFailuresPanel from './OrderFailuresPanel';

const IncidentModal = ({ incident }) => {
  const lookerDashboardUrl = 'https://cimpress.eu.looker.com/dashboards/7020';
  const [isOpen, setIsOpen] = useState(false);
  const [transactionId, setTransactionId] = useState(undefined);

  const { identity } = useIdentityContext();
  const { cimpressADFSUserId } = identity;
  const { environment } = useContext(EnvironmentContext);

  const [incidentId] = useState(incident.id);
  const [orderCount, setOrderCount] = useState(undefined);
  const [remediationDescription, setRemediationDescription] = useState(
    undefined
  );
  const [
    remediationDescriptionError,
    setRemediationDescriptionError,
  ] = useState(false);

  const [resubmissionRequested, setResubmissionRequested] = useState(false);
  const [incidentData, setIncidentData] = useState(undefined);
  const [isResubmissionComplete, setIsResubmissionComplete] = useState(false);
  const [orderNumberSet, setOrderNumbersSet] = useState(undefined);
  const [resubmissionErrorType, setResubmissionErrorType] = useState(undefined);
  const [resubmittedOrdersCount, setResubmittedOrdersCount] = useState(
    undefined
  );

  const checkIfResubmissionSucceeded = resubmittedOrders => {
    if (resubmittedOrders.length > 0) {
      setResubmissionErrorType(undefined);
      setResubmittedOrdersCount(resubmittedOrders.length);
    } else {
      setResubmissionErrorType(ERROR_NO_UNRESOLVED_ORDERS);
    }
  };

  const buildIncidentData = () => {
    const failureData = [];
    for (const orderNum of orderNumberSet) {
      const aFailure = {};
      aFailure.orderNumber = orderNum;
      aFailure.remediationReason = remediationDescription;
      failureData.push(aFailure);
    }
    setIncidentData(failureData);
  };

  // initiate transaction in Mongo and fetch orderData once incidentData has been provided
  useEffect(() => {
    if (incidentData && environment) {
      initiateResubmit(environment, incidentData).then(
        orderResubmissionData => {
          setTransactionId(orderResubmissionData.transactionId);
        }
      );
    }
  }, [incidentData, environment]);

  // initiate resubmit once transactionId is defined
  useEffect(() => {
    if (transactionId) {
      const incidentResubmissionStartedMetricData = {
        environment,
        transactionId,
        incidentId,
      };
      trackResubmissionStarted(
        incidentResubmissionStartedMetricData,
        cimpressADFSUserId
      );
      resubmit(environment, transactionId, remediationDescription)
        .then(response => checkIfResubmissionSucceeded(response.orderData))
        .catch(err => {
          const errMsg = 'An unexpected error occurred';
          setResubmissionErrorType(errMsg);
          const metricData = {
            errMsg,
            errorType: ERROR_INCIDENT_RESUBMISSION_ISSUE,
          };
          trackResubmissionIncidentIssue(metricData, cimpressADFSUserId);
        });
      trackResubmissionComplete(
        incidentResubmissionStartedMetricData,
        cimpressADFSUserId
      );
      setIsResubmissionComplete(true);
    }
  }, [
    cimpressADFSUserId,
    environment,
    incidentId,
    remediationDescription,
    transactionId,
  ]);

  // build set of unique orderIds and setOrderCount when modal is generated
  useEffect(() => {
    const orderNumSet = new Set();
    const failures = incident?.itemFailures || [];

    for (const failure of failures) {
      orderNumSet.add(failure.orderNumber);
    }

    setOrderNumbersSet(orderNumSet);
    setOrderCount(incident?.itemFailures.length);
  }, [incident]);

  const resubmissionHandler = async () => {
    if (
      !remediationDescription ||
      remediationDescription === '' ||
      remediationDescription.trim() === ''
    ) {
      setRemediationDescriptionError(true);
      return;
    }
    setResubmittedOrdersCount(undefined);
    setResubmissionErrorType(undefined);
    setResubmissionRequested(true);
    setIsResubmissionComplete(false);

    buildIncidentData();
  };

  const assigneeField = assignee => {
    if (assignee && assignee.name) {
      return assignee.name;
    }
    return '';
  };

  return (
    <ModalDialogContent>
      <BoundedContent>
        <ModalDialogCloseButton />
        <ModalDialogHeader>
          <ModalDialogTitle>
            {incident?.type || 'Incident To Resubmit'}
          </ModalDialogTitle>
        </ModalDialogHeader>
        <ModalDialogBody>
          <Row>
            <GridContainer>
              <Row margin="auto">
                {!resubmissionRequested ? (
                  <StandardForm variant="horizontal">
                    <Row component={FormField} mb={5}>
                      <Column>
                        <Row>
                          <Column mt={4} span={4}>
                            <FormLabel>Incident Id:</FormLabel>
                          </Column>
                          <Column mt={4} span={6}>
                            <FormInputGroup>
                              <Typography>{incident.id}</Typography>
                            </FormInputGroup>
                          </Column>
                        </Row>
                        <Row>
                          <Column mt={4} span={4}>
                            <FormLabel>Order Failures:</FormLabel>
                          </Column>
                          <BasicCollapsible>
                            <Column>
                              <OrderFailuresPanel
                                failures={incident.itemFailures}
                              />
                            </Column>
                          </BasicCollapsible>
                        </Row>
                        <Row>
                          <Column mt={4} span={4}>
                            <FormLabel>Grouped By:</FormLabel>
                          </Column>
                          <Column mt={4} span={6}>
                            <FormInputGroup>
                              <ReactJson
                                collapsed={false}
                                name={false}
                                displayObjectSize={false}
                                displayDataTypes={false}
                                src={incident?.groupedBy}
                                enableClipboard={false}
                              />
                            </FormInputGroup>
                          </Column>
                        </Row>
                        <Row>
                          <Column span={4}>
                            <FormLabel>
                              Remediation Description{' '}
                              <FormLabelOptional>
                                {' '}
                                (what remediation action was taken){' '}
                              </FormLabelOptional>
                            </FormLabel>
                          </Column>
                          <Column span={6}>
                            <FormInputGroup>
                              <TextInput
                                value={remediationDescription}
                                onKeyDown={e => {
                                  if (
                                    e.key === 'Enter' &&
                                    !remediationDescriptionError
                                  ) {
                                    e.preventDefault();
                                    resubmissionHandler();
                                  }
                                }}
                                onChange={event => {
                                  const input = event.target.value;
                                  const isEmpty =
                                    !input ||
                                    input === '' ||
                                    input.trim() === '';
                                  setRemediationDescription(input);
                                  setRemediationDescriptionError(isEmpty);
                                }}
                              />
                              {remediationDescriptionError && (
                                <FormError>
                                  A remediation description is required
                                </FormError>
                              )}
                              {!orderCount ||
                                (orderCount === 0 && (
                                  <FormError>
                                    No unresolved orders associated with this
                                    incident
                                  </FormError>
                                ))}
                            </FormInputGroup>
                          </Column>
                        </Row>
                        <Row mt={8} mb={8}>
                          <Column>
                            <Typography mb={4}>
                              Resubmit {orderCount} Orders?
                            </Typography>
                            <Button
                              disabled={remediationDescriptionError}
                              skin="primary"
                              onClick={() => resubmissionHandler()}
                            >
                              Resubmit
                            </Button>
                          </Column>
                        </Row>
                      </Column>
                    </Row>
                  </StandardForm>
                ) : (
                  <div>
                    {!isResubmissionComplete ? (
                      <GridContainer>
                        <Row mt={6}>
                          <Typography component="h4" margin="auto">
                            Resubmitting Orders ... This could take a few
                            minutes
                          </Typography>
                        </Row>
                        <Row>
                          <BasicPreloader sizeVariant="large" centered my={6} />
                        </Row>
                      </GridContainer>
                    ) : (
                      <GridContainer>
                        <Row>
                          <Column>
                            <Typography component="h4">
                              Transaction ID: {transactionId}
                            </Typography>
                            <Typography>
                              We record the results of each resubmission attempt
                              as a transaction.{' '}
                              <NavLink to="/remediationHistory">
                                <Link skin="cta">
                                  View past transactions here
                                </Link>
                              </NavLink>
                            </Typography>
                            <Typography>
                              Having trouble? Please contact{' '}
                              <Link
                                href="https://vistaprint.slack.com/archives/CNFFZMUD6"
                                target="_blank"
                              >
                                LochNess Squad
                              </Link>{' '}
                              and provide this transaction ID.
                            </Typography>
                          </Column>
                        </Row>
                        {resubmissionErrorType && (
                          <Row mt={5} mb={5}>
                            <IncidentErrorPanel
                              errorType={resubmissionErrorType}
                            />
                          </Row>
                        )}
                        {resubmittedOrdersCount && (
                          <Row mt={5} mb={5}>
                            <AlertBox skin="positive">
                              <Typography>
                                Resubmitted {resubmittedOrdersCount} /{' '}
                                {orderCount} orders in incident{' '}
                              </Typography>
                              <Link
                                href={lookerDashboardUrl}
                                target="_blank"
                                skin="cta"
                              >
                                Check for failure and incident resolution in
                                Looker
                              </Link>
                            </AlertBox>
                          </Row>
                        )}
                      </GridContainer>
                    )}
                  </div>
                )}
              </Row>
            </GridContainer>
          </Row>
        </ModalDialogBody>
      </BoundedContent>
    </ModalDialogContent>
  );
};

export default IncidentModal;
