import {
  BoundedContent,
  Divider,
  GridContainer,
  Link,
  ModalDialogBody,
  ModalDialogCloseButton,
  ModalDialogContent,
  ModalDialogHeader,
  ModalDialogTitle,
  Row,
  Button,
} from '@vp/swan';
import React, { useContext, useState } from 'react';
import ReactJson from 'react-json-view';
import { Link as RouterLink } from '@reach/router';
import { EnvironmentContext } from '../../../contexts/EnvironmentContext';
import { buildMexUrl } from './mcpMexUrlBuilder';
import CollapsibleOrderJson from './CollapsibleOrderJson';

const OrderLinksModal = ({ order }) => {
  const { environment } = useContext(EnvironmentContext);
  const [isCollapsed, setIsCollapsed] = useState(true);

  function getOrderMCPUrl(orderNumber) {
    const orderVoyager = [];
    orderVoyager.push({ orderNumber });

    return buildMexUrl(environment, orderVoyager);
  }

  const handleCopy = copy => {
    navigator.clipboard.writeText(JSON.stringify(copy.src, null, '\t'));
  };

  return (
    <ModalDialogContent>
      <BoundedContent>
        <ModalDialogCloseButton />
        <ModalDialogHeader>
          <ModalDialogTitle>{order.orderNumber}</ModalDialogTitle>
        </ModalDialogHeader>
        <ModalDialogBody>
          <Row>
            <GridContainer>
              <Row margin="auto" mb={4} mr={2}>
                <Button mr={3}>
                  <RouterLink
                    component="button"
                    to={`/orderConsole/${environment}/${order.orderNumber}`}
                  >
                    Order Details Console
                  </RouterLink>
                </Button>
                <Button mr={3}>
                  <RouterLink
                    component="button"
                    to={`/orderVoyager/${environment}/${order.orderNumber}`}
                  >
                    Order Timeline
                  </RouterLink>
                </Button>
                <Button mr={3}>
                  <Link
                    skin="cta"
                    href={getOrderMCPUrl(order.orderNumber)}
                    target="_blank"
                  >
                    View order in MCP
                  </Link>
                </Button>
              </Row>
              <Divider mb={4} />
              <CollapsibleOrderJson order={order} />
            </GridContainer>
          </Row>
        </ModalDialogBody>
      </BoundedContent>
    </ModalDialogContent>
  );
};

export default OrderLinksModal;
