import React, { useContext } from 'react';
import { Card, Column, GridContainer, Row, Typography } from '@vp/swan';
import { AddressDisplay } from '@vp/address-form/src/components/AddressDisplay';
import { ShipmentDetails } from './ShipmentDetails';
import { OrderConsoleContext } from '../../../../../contexts/OrderConsoleContext';
import { PaymentDetails } from './PaymentDetails';

const CustomerDetails = () => {
  const { order } = useContext(OrderConsoleContext);
  const showShipmentDetails = order.shippingAddress && order.orderShippingData;
  const showPaymentDetails = order.payments && order.paymentState;

  /**
   * There should be 4 cases for the column span:
   * 1. Billing, Shipping, Ship method, Payment Details (span = 3)  Regular Item
   * 2. Billing, Payment Details (span = 6) Digital Item
   * 3. Billing, Shipping, Ship method (span = 4) Regular Item but payment details is not supported
   * 4. Billing (span = 12) Digital Item but payment details is not supported
   */
  const getColumnSpan = () => {
    let columnCount = 1; // The billing item should always be present.
    if (showShipmentDetails) {
      columnCount += 2;
    } // This will add 2 more cards to the row (shipping address/method)
    if (showPaymentDetails) {
      columnCount += 1;
    } // Add payment details if we can present this to shopper
    return 12 / columnCount;
  };

  return (
    <GridContainer>
      <Card evenHeight bordered>
        <GridContainer cardDividers>
          <Row>
            {showShipmentDetails && (
              <ShipmentDetails columSpan={getColumnSpan()} />
            )}
            {order.billingAddress && (
              <Column span={getColumnSpan()}>
                <Typography weight="bold" data-testid="card-title">
                  Billing Address
                </Typography>
                <AddressDisplay address={order.billingAddress} locale="en-US" />
              </Column>
            )}
            {showPaymentDetails && (
              <PaymentDetails columSpan={getColumnSpan()} />
            )}
          </Row>
        </GridContainer>
      </Card>
    </GridContainer>
  );
};
export default CustomerDetails;
