import React from 'react';
import {
  ControlIcon,
  Column,
  FlexBox,
  PopoverLauncher,
  Popover,
  PopoverContent,
  Row,
  TextInput,
  Typography,
} from '@vp/swan';
/**
 * AdvancedLookupField handles all layout for a single search field.
 * It is passed the label, tooltip, placeholder, and error texts, plus functions for dealing with input.
 * @param {*} param0
 * @returns
 */
const AdvancedLookupField = ({
  label,
  placeholderText,
  tooltipText,
  errorText,
  span,
  checkEnterFn,
  setErrFn,
  setSearchFn,
  isError,
}) => (
  <Column span={span} m={1}>
    <FlexBox justifyContent="left" mb={1}>
      <Typography weight="bold" mr={2}>
        {label}
      </Typography>
      <Popover position="above">
        <PopoverLauncher>
          <ControlIcon skin="standard" iconType="info" />
        </PopoverLauncher>
        <PopoverContent>{tooltipText}</PopoverContent>
      </Popover>
    </FlexBox>
    <Row>
      <TextInput
        placeholder={placeholderText}
        sizeVariant="mini"
        type="search"
        mb={3}
        onKeyDown={e => checkEnterFn(e)}
        onChange={event => {
          if (event.target.value !== '') {
            setErrFn(false);
          }
          setSearchFn(event.target.value);
        }}
      />
      {isError && (
        <Typography textSize={6} textColor="error">
          {errorText}
        </Typography>
      )}
    </Row>
  </Column>
);

export default AdvancedLookupField;
