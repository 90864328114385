import React, { useContext } from 'react';
import { Card, Column, Typography } from '@vp/swan';
import { OrderConsoleContext } from '../../../../../contexts/OrderConsoleContext';
import { InfoTypography } from '../../../../shared/InfoTypography';
import CurrencyDisplay from '../../currency/CurrencyDisplay';

const formatPaymentMethodString = p =>
  p ? p.charAt(0).toUpperCase() + p.slice(1) : undefined;

export const PaymentDetails = ({ columSpan }) => {
  const { order } = useContext(OrderConsoleContext);
  const { payments } = order;

  return (
    <Column span={columSpan}>
      <Typography weight="bold">Payment Data</Typography>
      <InfoTypography
        label="Order Payment State"
        valueToDisplay={order.paymentState}
      />
      {payments &&
        payments.map(p => (
          <Card bordered>
            <InfoTypography
              label="Payment Method"
              valueToDisplay={formatPaymentMethodString(p.paymentMethod)}
            />
            <InfoTypography
              label="Amount"
              valueToDisplay={
                <CurrencyDisplay
                  centPrice={p.cents}
                  fractionDigits={order.orderPriceSummary?.fractionDigits}
                  currency={p.currencyCode}
                  locale={order.locale}
                />
              }
            />
            <InfoTypography label="Payment State" valueToDisplay={p.state} />
          </Card>
        ))}
    </Column>
  );
};
