import VistaprintAuth from '@vp/auth';

export const getAuthorizationHeaderAndShopperId = () => {
  const auth = new VistaprintAuth.WebAuth();
  return {
    idToken: auth.getAuthorizationHeader(),
    isSignedIn: auth.isSignedIn(),
  };
};

export const logout = () => {
  const auth = new VistaprintAuth.WebAuth();
  auth.signOut();
};
