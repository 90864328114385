import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { API_STATUS } from '../constants/apiStatus';
import useOrderFetch from '../hooks/useOrderFetch';
import {
  TARGET_EMAIL_ADDRESS,
  TARGET_TENANT_ID,
} from '../constants/orderCopyTargetFormFields';
import useStagingTenantsFetch from '../hooks/useStagingTenantsFetch';
import { ENVIRONMENT } from '../constants/environment';
import { orderCatalogOptions } from '../constants/orderCatalogOptions';
import { defaultTargetOrderFormFieldConfigs } from '../constants/orderCopyFormFieldConfigs';

const OrderCopyContext = React.createContext();

const OrderCopyProvider = props => {
  const { orderNumber, environment, children } = props;

  // Initialize the source order search params
  const [sourceOrderNumber, setSourceOrderNumber] = useState(orderNumber);
  const [sourceOrderNumberValid, setSourceOrderNumberValid] = useState(true);
  const [sourceOrderEnvironment, setSourceOrderEnvironment] = useState(
    environment
  );

  // Initialize the target order form with default field configurations
  const [targetOrderFormFields, setTargetOrderFormFields] = useState(
    defaultTargetOrderFormFieldConfigs
  );

  // Initialize the order copy results
  const [orderCopyStatus, setOrderCopyStatus] = useState(API_STATUS.IDLE);
  const [targetOrderNumber, setTargetOrderNumber] = useState('');

  // Fetch the source order using the source order number and environment
  const {
    isOrderFetchLoading,
    isOrderFetchError,
    order: sourceOrder,
  } = useOrderFetch({
    orderNumber: sourceOrderNumber,
    orderEnvironment: sourceOrderEnvironment,
  });

  // Update the target order form to require the email field when source order is anonymous
  const sourceOrderPlacedAnonymously =
    sourceOrder?.orderPlacedAnonymously || false;

  targetOrderFormFields[TARGET_EMAIL_ADDRESS] = {
    ...targetOrderFormFields[TARGET_EMAIL_ADDRESS],
    isHidden: !sourceOrderPlacedAnonymously,
    isRequired: sourceOrderPlacedAnonymously,
  };

  // Fetch the staging tenant IDs
  const { stagingTenantIds } = useStagingTenantsFetch();

  // Configure tenant options to include staging tenants only
  targetOrderFormFields[TARGET_TENANT_ID].options = stagingTenantIds;

  // Resets the results of the latest copy request
  const resetCopyResults = () => {
    setTargetOrderNumber('');
    setOrderCopyStatus(API_STATUS.IDLE);
  };

  /**
   * Given an order number, update the state for the source orders' order number and environment,
   * and reset the results from the previous copy request.
   * By default, it will also validate the order number. This can be disabled by a caller who wishes to
   * ignore validation (and therefore assume validity to be true), such as a "reset" function
   */
  const updateSourceOrder = useCallback(
    ({ newOrderNumber, validate = true }) => {
      console.log({ newOrderNumber });
      // Update the order number
      setSourceOrderNumber(newOrderNumber);

      // If the order number exists in the catalog, use its corresponding environment for lookup
      setSourceOrderEnvironment(
        orderCatalogOptions[newOrderNumber]?.environment || ENVIRONMENT.UNKNOWN
      );

      // If validation is enabled (default), validate the order number
      setSourceOrderNumberValid(validate ? newOrderNumber?.length > 0 : true);

      // Reset the results from the previous copy request
      resetCopyResults();
    },
    []
  );

  /**
   * Given a field name and value, update the value for that field in the form configuration,
   * and reset the results from the previous copy request.
   * By default, it will also validate the value. This can be disabled by a caller who wishes to
   * ignore validation (and therefore assume validity to be true), such as a "reset" function
   */
  const updateTargetOrderFormFieldValue = useCallback(
    ({ fieldName, input, validate = true }) => {
      const { isRequired, isHidden } = targetOrderFormFields[fieldName];

      // If validation is enabled (default), validate the input
      const isValid = validate
        ? !(!isHidden && isRequired && input?.length === 0)
        : true;

      // Update the form fields with new value and validity
      setTargetOrderFormFields(prevValue => ({
        ...prevValue,
        [fieldName]: {
          ...targetOrderFormFields[fieldName],
          value: input,
          isValid,
        },
      }));

      // Reset the results from the previous copy request
      resetCopyResults();
    },
    [targetOrderFormFields]
  );

  // Resets the source order number to be empty
  const resetSourceOrder = useCallback(() => {
    updateSourceOrder({ newOrderNumber: '', validate: false });
  }, [updateSourceOrder]);

  // Resets the target fields to be empty
  const resetTarget = useCallback(() => {
    Object.keys(targetOrderFormFields).forEach(fieldName => {
      updateTargetOrderFormFieldValue({
        fieldName,
        input: '',
        validate: false,
      });
    });
  }, [targetOrderFormFields, updateTargetOrderFormFieldValue]);

  // Resets the source, target, and any existing copy results
  const reset = useCallback(() => {
    resetSourceOrder();
    resetTarget();
    resetCopyResults();
  }, [resetSourceOrder, resetTarget]);

  // Returns true if the source and target forms are complete with valid input
  // Returns false if at least ONE of the fields is incomplete/invalid
  const validateForms = useCallback(() => {
    // Validate the source
    const sourceIsValid = !!sourceOrderNumber && sourceOrder;
    setSourceOrderNumberValid(sourceIsValid);

    // Validate the target
    let targetIsValid = true;
    Object.keys(targetOrderFormFields).forEach(fieldName => {
      const { value, isRequired, isHidden } = targetOrderFormFields[fieldName];
      const isValid = !(!isHidden && isRequired && value?.length === 0);
      setTargetOrderFormFields(prevValue => ({
        ...prevValue,
        [fieldName]: {
          ...targetOrderFormFields[fieldName],
          isValid,
        },
      }));
      if (!isValid) {
        targetIsValid = false;
      }
    });

    return sourceIsValid && targetIsValid;
  }, [sourceOrder, sourceOrderNumber, targetOrderFormFields]);

  const orderCopyContextValues = useMemo(
    () => ({
      sourceOrderNumber,
      sourceOrderEnvironment,
      sourceOrderNumberValid,
      updateSourceOrder,
      isOrderFetchLoading,
      isOrderFetchError,
      sourceOrder,
      targetOrderFormFields,
      setTargetOrderFormFields,
      updateTargetOrderFormFieldValue,
      validateForms,
      targetOrderNumber,
      setTargetOrderNumber,
      orderCopyStatus,
      setOrderCopyStatus,
      reset,
      resetSourceOrder,
      resetTarget,
    }),
    [
      sourceOrderNumber,
      sourceOrderEnvironment,
      sourceOrderNumberValid,
      updateSourceOrder,
      isOrderFetchLoading,
      isOrderFetchError,
      sourceOrder,
      targetOrderFormFields,
      updateTargetOrderFormFieldValue,
      validateForms,
      targetOrderNumber,
      orderCopyStatus,
      reset,
      resetSourceOrder,
      resetTarget,
    ]
  );

  return (
    <OrderCopyContext.Provider value={orderCopyContextValues}>
      {children}
    </OrderCopyContext.Provider>
  );
};

OrderCopyProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  orderNumber: PropTypes.string,
  environment: PropTypes.string,
};

export { OrderCopyContext, OrderCopyProvider };
