// removes all duplicates from the csvData to ensure we are only processing unique orders
export const removeOrderNumDuplicates = csvDataArray => {
  let arrayWithUniqueOrderNumbers = {};
  if (csvDataArray) {
    arrayWithUniqueOrderNumbers = csvDataArray.filter(
      (csvRow, ind) =>
        ind ===
        csvDataArray.findIndex(elem => elem.orderNumber === csvRow.orderNumber)
    );
  }
  return arrayWithUniqueOrderNumbers;
};

/**
 * Validate the csvData, by ensuring each data row contains an orderNumber and remediationReason.
 * This method assumes the csvDataArray is just the data rows (i.e. no csv headers)
 * @param csvDataArray
 * @returns array of error strings (if found)
 */
export const validateResubmissionRow = csvDataArray => {
  const errors = [];
  if (!csvDataArray || csvDataArray.length === 0) {
    const errorMsg = `CSV file is Empty`;
    errors.push(errorMsg);
  }

  csvDataArray.forEach((dataRow, index) => {
    // increment index by 1 for display purposes, since array is zero based index and 1st row is the field headers
    const rowNum = index + 1;
    let errorMsg = `Row #${rowNum} missing: `;
    const missingOrderNum = !dataRow.orderNumber;
    const missingReason = !dataRow.remediationReason;
    if (missingOrderNum || missingReason) {
      if (missingOrderNum) {
        errorMsg += 'orderNumber, ';
      }
      if (missingReason) {
        errorMsg += 'remediationReason';
      }
      errors.push(errorMsg);
    }
  });
  return errors;
};
