import PropTypes from 'prop-types';
import {
  Row,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@vp/swan';

import React from 'react';

const ResubmissionFailureResultsTable = ({ failedToResubmitOrders }) =>
  failedToResubmitOrders &&
  failedToResubmitOrders.length > 0 && (
    <Row>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>OrderNumber</TableCell>
            <TableCell>Reason</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {failedToResubmitOrders.map(order => (
            <TableRow>
              <TableCell>{order.orderNumber}</TableCell>
              <TableCell>{order.error.message}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Row>
  );

ResubmissionFailureResultsTable.propTypes = {
  failedToResubmitOrders: PropTypes.array,
};

export default ResubmissionFailureResultsTable;
