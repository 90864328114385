import React, { useState } from 'react';
import {
  BoundedContent,
  ModalDialog,
  ModalDialogBody,
  ModalDialogCloseButton,
  ModalDialogContent,
  ModalDialogHeader,
  ModalDialogTitle,
  Typography,
} from '@vp/swan';
import PropTypes from 'prop-types';
import MilestoneView from './MilestoneView';
import ItemIcon from './ItemIcon';

const ItemTemplate = ({ item, iconsOnly }) => {
  const [isOpen, setIsOpen] = useState(false);

  // if the milestone is in event mode, we want to show only eventName
  // otherwise, just show milestoneType.displayName
  let itemDisplayName = '';
  if (item.milestoneType && item.milestoneType.name === 'GENERIC_EVENT') {
    itemDisplayName = item.eventName;
  } else {
    itemDisplayName = item.milestoneType.displayName;
  }

  return (
    <BoundedContent>
      <Typography onClick={() => setIsOpen(true)} textSize={6}>
        <ItemIcon item={item} />
        {!iconsOnly && itemDisplayName}
      </Typography>
      <ModalDialog
        translucent
        takeOver="true"
        isOpen={isOpen}
        onRequestDismiss={() => setIsOpen(false)}
      >
        <ModalDialogContent>
          <BoundedContent>
            <ModalDialogCloseButton />
            <ModalDialogHeader>
              <ModalDialogTitle>{itemDisplayName}</ModalDialogTitle>
            </ModalDialogHeader>
            <ModalDialogBody>
              <MilestoneView milestone={item} />
            </ModalDialogBody>
          </BoundedContent>
        </ModalDialogContent>
      </ModalDialog>
    </BoundedContent>
  );
};

ItemTemplate.propTypes = {
  item: PropTypes.object,
};

export default ItemTemplate;
