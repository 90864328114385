import {
  BoundedContent,
  Column,
  GridContainer,
  Link,
  ModalDialog,
  ModalDialogBody,
  ModalDialogCloseButton,
  ModalDialogContent,
  ModalDialogHeader,
  ModalDialogTitle,
  Row,
  Button,
  Typography,
} from '@vp/swan';
import { Image } from 'react-bootstrap';
import ReactJson from 'react-json-view';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { EnvironmentContext } from '../../../contexts/EnvironmentContext';
import { buildMexUrl } from '../common/mcpMexUrlBuilder';
import handleCopy from '../common/handleCopy';
import { getEdoDebugData } from '../../../api/edoEvaluationUrlApi';

const OrderModal = ({ order }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [showEdoDetails, setShowEdoDetails] = useState(false);
  const { environment } = useContext(EnvironmentContext);
  const evaluationUrl = order.customOrderData?.shipping?.evaluationUrl;
  const { orderNumber } = order;

  const [edoDetails, setEdoDetails] = useState(null);
  const getEdoEvaluation = async () => {
    setShowEdoDetails(true);
    if (!edoDetails) {
      const response = await getEdoDebugData(evaluationUrl);
      setEdoDetails(response);
    }
  };

  function getOrderUrl() {
    const orderVoyager = [];
    orderVoyager.push({ orderNumber });

    return buildMexUrl(environment, orderVoyager);
  }

  return (
    <BoundedContent>
      <Button skin="unstyled" onClick={() => setIsOpen(true)}>
        <Image src="/order.png" height={125} />
        <Typography>{orderNumber}</Typography>
      </Button>
      <ModalDialog
        translucent
        takeOver="true"
        isOpen={isOpen}
        onRequestDismiss={() => setIsOpen(false)}
      >
        <ModalDialogContent>
          <BoundedContent>
            <ModalDialogCloseButton />
            <ModalDialogHeader>
              <ModalDialogTitle>{orderNumber}</ModalDialogTitle>
            </ModalDialogHeader>
            <ModalDialogBody>
              <Row>
                <GridContainer>
                  <Row margin="auto" mb={4}>
                    <Link
                      skin="cta"
                      href={getOrderUrl(orderNumber)}
                      target="_blank"
                    >
                      View order in MCP
                    </Link>
                  </Row>
                  <Row margin="auto">
                    <Typography>Order: </Typography>
                    {isCollapsed ? (
                      <Button
                        ml={3}
                        mt={2}
                        sizeVariant="mini"
                        onClick={() => setIsCollapsed(false)}
                      >
                        Expand Order
                      </Button>
                    ) : (
                      <Button
                        ml={3}
                        mt={2}
                        sizeVariant="mini"
                        onClick={() => setIsCollapsed(true)}
                      >
                        Collapse Order
                      </Button>
                    )}
                  </Row>

                  <Row ml={3} mt={3}>
                    <ReactJson
                      collapsed={isCollapsed}
                      name={false}
                      src={order}
                      enableClipboard={handleCopy}
                      displayObjectSize={false}
                      displayDataTypes={false}
                    />
                  </Row>

                  <Row ml={3} mt={3}>
                    <Typography>Shipping debug info from EDO</Typography>
                  </Row>
                  {evaluationUrl ? (
                    <Column>
                      <Row margin="auto">
                        {!showEdoDetails ? (
                          <Button
                            ml={3}
                            mt={4}
                            sizeVariant="mini"
                            onClick={getEdoEvaluation}
                          >
                            Show Shipping Debug Info
                          </Button>
                        ) : (
                          <Button
                            ml={3}
                            mt={4}
                            sizeVariant="mini"
                            onClick={() => setShowEdoDetails(false)}
                          >
                            Hide Shipping Debug Info
                          </Button>
                        )}
                      </Row>
                      <Row margin="auto">
                        {edoDetails ? (
                          <Row ml={3} mt={3}>
                            <ReactJson
                              collapsed={!showEdoDetails}
                              name={false}
                              src={edoDetails}
                              enableClipboard={handleCopy}
                              displayObjectSize={false}
                              displayDataTypes={false}
                            />
                          </Row>
                        ) : (
                          <div>
                            {showEdoDetails && !edoDetails && (
                              <Row ml={3} mt={3}>
                                <Typography>
                                  Shipping details loading
                                </Typography>
                              </Row>
                            )}
                          </div>
                        )}
                      </Row>
                    </Column>
                  ) : (
                    <Row ml={3} mt={3}>
                      <Typography>No shipping details available</Typography>
                    </Row>
                  )}
                </GridContainer>
              </Row>
            </ModalDialogBody>
          </BoundedContent>
        </ModalDialogContent>
      </ModalDialog>
    </BoundedContent>
  );
};

export default OrderModal;

OrderModal.propTypes = {
  order: PropTypes.object,
};
