import React, { useContext, useEffect, useState } from 'react';
import {
  BasicPreloader,
  BoundedContent,
  Column,
  GridContainer,
  Row,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@vp/swan';
import formatDate from '../tools/common/formatDate';
import useIdentityContext from '../../hooks/useIdentityContext';
import { remediationHistory } from '../../api/backendOrderRemediationToolService';
import { EnvironmentContext } from '../../contexts/EnvironmentContext';
import {
  FailedTransactionDataCSVExporter,
  TransactionDataCSVExporter,
} from '../tools/common/CSVExporter';

const RemediationHistory = () => {
  const { identity } = useIdentityContext();
  const { isSignedIn } = identity;
  const [transactions, setTransactions] = useState(null);

  const { environment } = useContext(EnvironmentContext);

  useEffect(() => {
    if (isSignedIn) {
      setTransactions(null);
      remediationHistory(environment).then(transactionalData => {
        setTransactions(transactionalData.reverse());
      });
    }
  }, [environment, isSignedIn]);

  return isSignedIn && transactions ? (
    <BoundedContent>
      <GridContainer>
        <Row mt={5} mb={5}>
          <Typography component="h2" mt={4}>
            Remediation History
          </Typography>
        </Row>
        <Row mb={5}>
          <Column span={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Remediation Type</TableCell>
                  <TableCell>Transaction Id</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.map(transaction => (
                  <TableRow>
                    <TableCell>{formatDate(transaction.createdDate)}</TableCell>
                    <TableCell>{transaction.transactionType}</TableCell>
                    <TableCell>{transaction.transactionId}</TableCell>
                    <TableCell>
                      <Row>
                        <Column>
                          <TransactionDataCSVExporter
                            environment={environment}
                            headers={[
                              'orderNumber',
                              'remediationReason',
                              'productName',
                              'lineItemId',
                              'currentStates',
                              'currentMcpState',
                              'error',
                            ]}
                            transactionId={transaction.transactionId}
                          />
                        </Column>
                        <Column>
                          <FailedTransactionDataCSVExporter
                            environment={environment}
                            headers={['orderNumber', 'remediationReason']}
                            transactionId={transaction.transactionId}
                          />
                        </Column>
                      </Row>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Column>
        </Row>
      </GridContainer>
    </BoundedContent>
  ) : (
    <GridContainer>
      <Row mt={6}>
        <Typography component="h4" margin="auto">
          Fetching Remediation History ...
        </Typography>
        <BasicPreloader sizeVariant="large" centered my={6} />
      </Row>
    </GridContainer>
  );
};

export default RemediationHistory;
