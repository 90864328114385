import axios from 'axios';
import qs from 'query-string';
import { buildStandardHeaders } from '../utils/serviceHelpers';
import { getAuthorizationHeaderAndShopperId } from '../components/auth/auth';

const prodOehBaseUrl = process.env.PRODUCTION_ORDER_EVENT_HISTORY_BASE_URL;
const stagingOehBaseUrl = process.env.STAGING_ORDER_EVENT_HISTORY_BASE_URL;

/**
 * Returns an object containing parameters to use for calling the OEH service.
 * In the future, consider adding timeouts as part of the configuration.
 * @param {*} environment The environment to send OEH service requests too.
 */
function fetchOehConfigForEnvironment(environment) {
  if (!environment) {
    console.error(
      `Unable to derive service config for a null/empty environment, using STAGING environment as sensible fallback.`
    );
    return { baseUrl: stagingOehBaseUrl };
  }
  switch (environment) {
    case 'vistaprint-production':
      return { baseUrl: prodOehBaseUrl };
    case 'vistaprint-staging':
      return { baseUrl: stagingOehBaseUrl };
    default:
      console.warn(
        `Unsupported environment type of ${environment}, using STAGING envirnoment as sensible fallback.`
      );
      return { baseUrl: stagingOehBaseUrl };
  }
}

function ensureRequestWasSuccessfulOrThrow(response) {
  if (response && response.status === 200) {
    // noice!
  } else {
    throw new Error('request failed');
  }
}

export const healthCheck = async environment => {
  const bortConfig = fetchOehConfigForEnvironment(environment);
  const axiosInstance = axios.create({
    baseURL: bortConfig.baseUrl,
  });
  return axiosInstance.get(`health`);
};

/**
 * Aggregate the state change events by line item
 * @param events
 * @returns {Map}
 */
const lineItemAgg = events => {
  const itemToEventsMap = new Map();

  events.forEach(event => {
    event.itemInfo.forEach(item => {
      const { itemId } = item;
      if (itemToEventsMap.get(itemId) === undefined) {
        itemToEventsMap.set(itemId, [event]);
      } else {
        const existingEventsArray = itemToEventsMap.get(itemId);
        existingEventsArray.push(event);
      }
    });
  });

  return itemToEventsMap;
};

/**
 * Fetch line item state change events
 * @param environment
 * @param orderNumber
 * @returns {Promise<AxiosResponse<any>>}
 */
export const lineItemStateChangeEventsFetch = (environment, orderNumber) => {
  const oehConfig = fetchOehConfigForEnvironment(environment);
  const { idToken } = getAuthorizationHeaderAndShopperId();

  const axiosInstance = axios.create({
    headers: buildStandardHeaders(idToken),
    baseURL: oehConfig.baseUrl,
  });

  const url = `/api/v2/orders/${orderNumber}/events?`;

  return axiosInstance
    .get(url, {
      params: { types: ['LINE_ITEM_STATE_CHANGED'], page: 0, size: 100 },
      paramsSerializer: params => qs.stringify(params),
    })
    .then(response => {
      ensureRequestWasSuccessfulOrThrow(response);
      return lineItemAgg(response.data);
    })
    .catch(err => {
      throw err;
    });
};
