import {
  TextInput,
  Dropdown,
  DropdownOption,
  FormField,
  FormInputGroup,
  StandardForm,
  FormHelper,
  FormError,
  FormLabel,
  FlexBox,
  H2,
  Icon,
} from '@vp/swan';
import React, { useContext } from 'react';
import {
  DROPDOWN_FIELD,
  INPUT_FIELD,
} from '../../../../constants/orderCopyTargetFormFields';
import { OrderCopyContext } from '../../../../contexts/OrderCopyContext';

const TargetOrderForm = () => {
  const {
    targetOrderFormFields,
    updateTargetOrderFormFieldValue,
    resetTarget,
  } = useContext(OrderCopyContext);

  const handleReset = () => {
    resetTarget();
  };

  const handleOnChange = (fieldName, input) => {
    updateTargetOrderFormFieldValue({ fieldName, input });
  };

  return (
    <>
      <FlexBox justifyContent="space-between" mb={2}>
        <H2 fontSize={3}>Configure a target</H2>
        <Icon
          iconType="reset"
          size="32p"
          skin="standard"
          alt="Reset source order"
          onClick={handleReset}
        />
      </FlexBox>
      <StandardForm>
        {Object.keys(targetOrderFormFields).map(fieldName => {
          const {
            label,
            isRequired,
            value,
            isValid,
            type,
            options,
            isHidden,
            helperText,
          } = targetOrderFormFields[fieldName];

          if (isHidden) {
            return null;
          }

          if (type === INPUT_FIELD) {
            return (
              <FormField>
                <FormLabel>{label}</FormLabel>
                <FormInputGroup>
                  <TextInput
                    value={value}
                    onChange={event => {
                      handleOnChange(fieldName, event.target.value.trim());
                    }}
                    size="mini"
                    id={`${fieldName}-input-field`}
                  />
                  {isRequired && !isValid && (
                    <FormError>{label} is required! 🤡</FormError>
                  )}
                </FormInputGroup>
              </FormField>
            );
          }

          if (type === DROPDOWN_FIELD) {
            return (
              <FormField>
                <FormLabel>{label}</FormLabel>
                <FormInputGroup>
                  <Dropdown
                    value={value}
                    onChange={event => {
                      handleOnChange(fieldName, event.target.value);
                    }}
                    size="mini"
                    fullWidth
                    id={`${fieldName}-input-field`}
                  >
                    <DropdownOption value="" key="default-tenant-option">
                      Select
                    </DropdownOption>
                    {options.map(option => (
                      <DropdownOption value={option} key={option}>
                        {option}
                      </DropdownOption>
                    ))}
                  </Dropdown>

                  <FormHelper>{helperText}</FormHelper>
                  {isRequired && !isValid && (
                    <FormError>{label} is required! 🤡</FormError>
                  )}
                </FormInputGroup>
              </FormField>
            );
          }

          return null;
        })}
      </StandardForm>
    </>
  );
};

export default TargetOrderForm;
