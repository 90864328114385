import React, { useState } from 'react';
import {
  BoundedContent,
  GridContainer,
  ModalDialog,
  ModalDialogBody,
  ModalDialogCloseButton,
  ModalDialogContent,
  ModalDialogHeader,
  ModalDialogTitle,
  Row,
  Button,
  Typography,
} from '@vp/swan';
import { Image } from 'react-bootstrap';
import { Link as RouterLink } from '@reach/router';

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <GridContainer>
      <BoundedContent>
        <Button skin="unstyled" onClick={() => setIsOpen(true)}>
          <Image
            src="https://cms.cloudinary.vpsvc.com/image/upload/v1652889675/order_management/sort-ui/hamburgerMenu.png"
            width={35}
            height={35}
          />
        </Button>

        <ModalDialog
          variant="menu"
          isOpen={isOpen}
          onRequestDismiss={() => {
            setIsOpen(false);
          }}
        >
          <ModalDialogContent>
            <ModalDialogCloseButton visuallyHiddenLabel="Close" />
            <RouterLink
              component="button"
              onClick={() => setIsOpen(false)}
              to="/"
            >
              <ModalDialogHeader>
                <ModalDialogTitle>
                  <Typography component="h2"> SORT</Typography>
                </ModalDialogTitle>
              </ModalDialogHeader>
            </RouterLink>
            <ModalDialogBody>
              <GridContainer>
                <Row margin="auto" mt="2">
                  <RouterLink
                    component="button"
                    onClick={() => setIsOpen(false)}
                    to="/orderLookup"
                  >
                    <Typography component="h5"> Order Lookup</Typography>
                  </RouterLink>
                </Row>
                <Row margin="auto" mt="2">
                  <RouterLink
                    component="button"
                    onClick={() => setIsOpen(false)}
                    to="/orderVoyager"
                  >
                    <Typography component="h5"> Order Voyager</Typography>
                  </RouterLink>
                </Row>
                <Row margin="auto" mt="2">
                  <RouterLink
                    component="button"
                    onClick={() => setIsOpen(false)}
                    to="/orderResubmissionHome"
                  >
                    <Typography component="h5"> Order Resubmission </Typography>
                  </RouterLink>
                </Row>
                <Row margin="auto" mt="2">
                  <RouterLink
                    component="button"
                    onClick={() => setIsOpen(false)}
                    to="/remediationHistory"
                  >
                    <Typography component="h5">Remediation History</Typography>
                  </RouterLink>
                </Row>
                <Row margin="auto" mt="2">
                  <RouterLink
                    component="button"
                    onClick={() => setIsOpen(false)}
                    to="/orderCopy"
                  >
                    <Typography component="h5"> Order Copy</Typography>
                  </RouterLink>
                </Row>
              </GridContainer>
            </ModalDialogBody>
          </ModalDialogContent>
        </ModalDialog>
      </BoundedContent>
    </GridContainer>
  );
};

export default Menu;
