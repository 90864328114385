import { Typography, FlexBox } from '@vp/swan';
import React from 'react';

export const InfoTypography = ({ label, valueToDisplay }) =>
  valueToDisplay ? (
    <Typography fontSize={1} my={2}>
      <b>{label}: </b> {valueToDisplay}
    </Typography>
  ) : null;

export const InfoTypographyWithSkeleton = ({
  label,
  valueToDisplay,
  hideEmptyValue,
}) => {
  if (!valueToDisplay && hideEmptyValue) {
    return null;
  }

  return (
    <FlexBox justifyContent="space-between" flexWrap="wrap" my={2} fontSize={1}>
      <Typography fontWeight="bold">{label}</Typography>
      {valueToDisplay ? (
        <Typography>{valueToDisplay}</Typography>
      ) : (
        <div className="swan-skeleton-text-line swan-my-1 swan-bgc-gray-300" />
      )}
    </FlexBox>
  );
};
