import React, { useContext } from 'react';
import {
  Card,
  GridContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@vp/swan';
import CurrencyDisplay from '../currency/CurrencyDisplay';
import { OrderConsoleContext } from '../../../../contexts/OrderConsoleContext';

const OrderPricingDisplay = () => {
  const { order } = useContext(OrderConsoleContext);
  // don't render list price for migrated orders
  const showListPrice = !order.migrated;

  return (
    <GridContainer>
      <Card bordered evenHeight>
        <GridContainer>
          <Table skin="simple">
            <TableHead>
              <TableCell>Tax Status</TableCell>
              {showListPrice && (
                <TableCell>
                  Product
                  <span className="table-text-second-line">List Price</span>
                </TableCell>
              )}
              <TableCell>
                Product
                <span className="table-text-second-line">Discounted Price</span>
              </TableCell>
              {showListPrice && (
                <TableCell>
                  Shipping
                  <span className="table-text-second-line">List Price</span>
                </TableCell>
              )}
              <TableCell>
                Shipping
                <span className="table-text-second-line">Discounted Price</span>
              </TableCell>
              {showListPrice && (
                <TableCell>
                  Order Total
                  <span className="table-text-second-line">List Price</span>
                </TableCell>
              )}
              <TableCell>
                Order Total
                <span className="table-text-second-line">Discounted Price</span>
              </TableCell>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell textAlign="center">
                  <Typography>Taxed:</Typography>
                </TableCell>
                {showListPrice && (
                  <TableCell textAlign="center">
                    <CurrencyDisplay
                      centPrice={
                        order.orderPriceSummary.orderProductListPrice.taxed
                      }
                      fractionDigits={order.orderPriceSummary.fractionDigits}
                      currency={order.orderPriceSummary.currency}
                      locale={order.locale}
                    />
                  </TableCell>
                )}
                <TableCell textAlign="center">
                  <CurrencyDisplay
                    centPrice={
                      order.orderPriceSummary.orderProductDiscountedPrice.taxed
                    }
                    fractionDigits={order.orderPriceSummary.fractionDigits}
                    currency={order.orderPriceSummary.currency}
                    locale={order.locale}
                  />
                </TableCell>
                {showListPrice && (
                  <TableCell textAlign="center">
                    <CurrencyDisplay
                      centPrice={
                        order.orderPriceSummary.orderShippingListPrice.taxed
                      }
                      fractionDigits={order.orderPriceSummary.fractionDigits}
                      currency={order.orderPriceSummary.currency}
                      locale={order.locale}
                    />
                  </TableCell>
                )}
                <TableCell textAlign="center">
                  <CurrencyDisplay
                    centPrice={
                      order.orderPriceSummary.orderShippingDiscountedPrice.taxed
                    }
                    fractionDigits={order.orderPriceSummary.fractionDigits}
                    currency={order.orderPriceSummary.currency}
                    locale={order.locale}
                  />
                </TableCell>
                {showListPrice && (
                  <TableCell textAlign="center">
                    <CurrencyDisplay
                      centPrice={
                        order.orderPriceSummary.orderTotalListPrice.taxed
                      }
                      fractionDigits={order.orderPriceSummary.fractionDigits}
                      currency={order.orderPriceSummary.currency}
                      locale={order.locale}
                    />
                  </TableCell>
                )}
                <TableCell textAlign="center">
                  <CurrencyDisplay
                    centPrice={
                      order.orderPriceSummary.orderTotalDiscountedPrice.taxed
                    }
                    fractionDigits={order.orderPriceSummary.fractionDigits}
                    currency={order.orderPriceSummary.currency}
                    locale={order.locale}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell textAlign="center">
                  <Typography>UnTaxed:</Typography>
                </TableCell>
                {showListPrice && (
                  <TableCell>
                    <CurrencyDisplay
                      centPrice={
                        order.orderPriceSummary.orderProductListPrice.untaxed
                      }
                      fractionDigits={order.orderPriceSummary.fractionDigits}
                      currency={order.orderPriceSummary.currency}
                      locale={order.locale}
                    />
                  </TableCell>
                )}
                <TableCell>
                  <CurrencyDisplay
                    centPrice={
                      order.orderPriceSummary.orderProductDiscountedPrice
                        .untaxed
                    }
                    fractionDigits={order.orderPriceSummary.fractionDigits}
                    currency={order.orderPriceSummary.currency}
                    locale={order.locale}
                  />
                </TableCell>
                {showListPrice && (
                  <TableCell>
                    <CurrencyDisplay
                      centPrice={
                        order.orderPriceSummary.orderShippingListPrice.untaxed
                      }
                      fractionDigits={order.orderPriceSummary.fractionDigits}
                      currency={order.orderPriceSummary.currency}
                      locale={order.locale}
                    />
                  </TableCell>
                )}
                <TableCell>
                  <CurrencyDisplay
                    centPrice={
                      order.orderPriceSummary.orderShippingDiscountedPrice
                        .untaxed
                    }
                    fractionDigits={order.orderPriceSummary.fractionDigits}
                    currency={order.orderPriceSummary.currency}
                    locale={order.locale}
                  />
                </TableCell>
                {showListPrice && (
                  <TableCell>
                    <CurrencyDisplay
                      centPrice={
                        order.orderPriceSummary.orderTotalListPrice.untaxed
                      }
                      fractionDigits={order.orderPriceSummary.fractionDigits}
                      currency={order.orderPriceSummary.currency}
                      locale={order.locale}
                    />
                  </TableCell>
                )}
                <TableCell>
                  <CurrencyDisplay
                    centPrice={
                      order.orderPriceSummary.orderTotalDiscountedPrice.untaxed
                    }
                    fractionDigits={order.orderPriceSummary.fractionDigits}
                    currency={order.orderPriceSummary.currency}
                    locale={order.locale}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </GridContainer>
      </Card>
    </GridContainer>
  );
};

export default OrderPricingDisplay;
