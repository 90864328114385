import axios from 'axios';
import { buildStandardHeaders } from '../utils/serviceHelpers';
import { getAuthorizationHeaderAndShopperId } from '../components/auth/auth';

export const getEdoDebugData = async evaluationUrl => {
  const { idToken } = getAuthorizationHeaderAndShopperId();
  try {
    const { data } = await axios.get('', {
      headers: buildStandardHeaders(idToken),
      baseURL: evaluationUrl,
    });
    return data;
  } catch (e) {
    console.error(e);
  }
};
