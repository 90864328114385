import axios from 'axios';
import { buildStandardHeaders } from '../utils/serviceHelpers';
import { getAuthorizationHeaderAndShopperId } from '../components/auth/auth';

const MAX_ORDERS = 20;
const prodDALIBaseUrl = process.env.PRODUCTION_DALI_BASE_URL;
const stagingDALIBaseUrl = process.env.STAGING_DALI_BASE_URL;
const QUERY_PARAM_SUFFIX = `&size=${MAX_ORDERS}&page=`;

/**
 * Adapter for the Data Access Layer Interface (DALI) API for order search.
 */

/** Top level search terms */
export const EMAIL_SEARCH_TERM = 'emailAddress';
export const ORDER_NUMBER_SEARCH_TERM = 'orderNumber';
export const TENANT_SEARCH_TERM = 'tenant';
export const SHOPPER_ID_SEARCH_TERM = 'shopperId';

/** Embedded search terms */
export const PHONE_SEARCH_TERM = 'shippingAddress.phone'; // Note: Phone is a mandatory field on shipping address, only optional on billing.
export const FIRST_NAME_SEARCH_TERM = 'firstName'; // Pseudo search term
export const LAST_NAME_SEARCH_TERM = 'lastName'; // Pseudo search term
export const SHIPPING_FIRST_NAME_SEARCH_TERM = 'shippingAddress.firstName';
export const SHIPPING_LAST_NAME_SEARCH_TERM = 'shippingAddress.lastName';
export const BILLING_FIRST_NAME_SEARCH_TERM = 'billingAddress.firstName';
export const BILLING_LAST_NAME_SEARCH_TERM = 'billingAddress.lastName';
export const TOTAL_AMOUNT_SEARCH_TERM =
  'pricingData.totalPricing.discountedTotalCents.taxed';
export const PAYMENT_AMOUNT_SEARCH_TERM = 'paymentData.payments.centsString';
export const PAYMENT_TYPE_SEARCH_TERM = 'paymentData.payments.paymentMethod';

/** Search limits */
export const BEGIN_DATE_TERM = 'beginDate';
export const END_DATE_TERM = 'endDate';

/*  For date constraints, make the time inclusive of the entire day by including all hours, mins & secs of each end. */
const BEGIN_DATE_TIME_PREFIX = 'T00%3A00%3A00.001Z';
const END_DATE_TIME_PREFIX = 'T23%3A59%3A59.001Z';

/** Search types */
const SEARCH_TYPE_PARTIAL = 'PARTIAL';
const SEARCH_TYPE_EXACT = 'EXACT';

/** Field objects to be requested */
const BILLING_ADDRESS_FIELD = 'billingAddress';
const SHIPPING_ADDRESS_FIELD = 'shippingAddress';
const PAYMENT_DATA_FIELD = 'paymentData';
const PRICING_DATA_FIELD = 'pricingData';
const SITE_NAME_FIELD = 'siteName';
const LOCALE_FIELD = 'locale';
const CURRENCY_FIELD = 'currency';
const SHOPPER_ID_FIELD = 'shopperId';
const TENANT_ID_FIELD = 'tenantId';
const EMAIL_FIELD = 'emailAddress';

const FIELD_REQUESTS = [
  BILLING_ADDRESS_FIELD,
  SHIPPING_ADDRESS_FIELD,
  EMAIL_FIELD,
  SHOPPER_ID_FIELD,
  TENANT_ID_FIELD,
  SITE_NAME_FIELD,
  LOCALE_FIELD,
  CURRENCY_FIELD,
  PAYMENT_DATA_FIELD,
  PRICING_DATA_FIELD,
];
export const PAYMENT_TYPES = [
  'NONE',
  'Afterpay',
  'Amex',
  'ApplePayAmex',
  'ApplePayMastercard',
  'ApplePayVisa',
  'CarteBancAire',
  'CreditCard',
  'Credits',
  'Diners',
  'Discover',
  'Ideal',
  'Klarna',
  'Mastercard',
  'mc',
  'Multibanco',
  'PayPal',
  'Sofort',
  'Visa',
  'ZeroBalanceDue',
];

/**
 * Returns an object containing parameters to use for calling the DALI service.
 * @param {*} environment The environment to send DALI service requests to.
 */
function fetchDALIConfigForEnvironment(environment) {
  if (!environment) {
    console.error(
      `Unable to derive service config for a null/empty environment, using STAGING environment as sensible fallback.`
    );
    return { baseUrl: stagingDALIBaseUrl };
  }
  switch (environment) {
    case 'vistaprint-production':
      return { baseUrl: prodDALIBaseUrl };
    case 'vistaprint-staging':
      return { baseUrl: stagingDALIBaseUrl };
    default:
      console.warn(
        `Unsupported environment type of ${environment}, using STAGING envirnoment as sensible fallback.`
      );
      return { baseUrl: stagingDALIBaseUrl };
  }
}

const ensureRequestWasSuccessfulOrThrow = response => {
  if (response && response.status === 200) {
    // noice!
  } else if (response.status === 408) {
    // too many
    throw new Error(response.message);
  } else {
    throw new Error('request failed');
  }
};

/**
 * Our UI uses dates.  DALI uses timestamps.  Fix that. Pre-URL-encode the string.
 * @param {string} limitType
 * @param {string} dateStr
 * @returns
 */
const appendTimeToDateStr = (limitType, dateStr) => {
  const suffix =
    limitType === BEGIN_DATE_TERM
      ? BEGIN_DATE_TIME_PREFIX
      : END_DATE_TIME_PREFIX;
  return dateStr + suffix;
};

/**
 * Build an element of a DALi search term using a single path string.
 * @param {string} pathName
 * @param {string} value
 * @param {string} sType
 * @returns
 */
const buildSearchTerm = (pathName, value, sType) => {
  const term = {
    searchPath: pathName,
    searchTerm: value,
    searchType: sType,
  };
  return term;
};

/**
 * Build an element of a DALi search term using multiple path strings.
 * @param {string[]} pathArr
 * @param {string} value
 * @param {string} sType
 * @returns
 */
const buildMultiPathSearchTerm = (pathArr, value, sType) => {
  const term = {
    searchPaths: pathArr,
    searchTerm: value,
    searchType: sType,
  };
  return term;
};

/**
 * Extract useful fields from DALi Order objects.
 * @param {*} orderData API data from profile service query.
 * @returns Object with an 'orders' array.
 */
const processOrders = orderData => {
  const rtn = {};
  if (!orderData || !orderData.orders || orderData.orders.length === 0) {
    rtn.orders = [];
    return rtn;
  }
  const rtnArray = orderData.orders.map(res => {
    const data = res.requestedData;
    const order = {};
    order.orderNumber = res.orderNumber;
    order.tenantId = data.tenantId;
    order.shopperId = data.shopperId;
    // Remove any address metadata - clutters up display.
    if (data.billingAddress) {
      data.billingAddress.metadata = undefined;
    }
    if (data.shippingAddress) {
      data.shippingAddress.metadata = undefined;
    }
    order.billingAddress = data.billingAddress;
    order.shippingAddress = data.shippingAddress;
    order.orderCreationDate = res.orderCreationDate;
    order.currencyCode = data.currency;
    order.payments = data.paymentData?.payments || [];
    order.pricingData = data.pricingData?.totalPricing || [];
    order.emailAddress =
      data.emailAddress ||
      data.billingAddress?.email ||
      data.shippingAddress?.email;
    return order;
  });
  rtn.orders = rtnArray;
  rtn.page = orderData.page;
  rtn.totalPages = orderData.totalPages;
  rtn.totalOrders = orderData.totalMatches;
  return rtn;
};

/**
 * Create DALi post data for the search API.
 * The search criteria are mostly expressed as a data object posted to the API.
 * @param {*} searchTermObj
 * @returns
 */
const createQueryArray = searchTermObj => {
  const pnames = Object.getOwnPropertyNames(searchTermObj);
  const queryArray = [];

  for (let i = 0; i < pnames.length; i += 1) {
    const n = pnames[i];

    // Ignore these terms which are handled as query params.
    if (
      n === TENANT_SEARCH_TERM ||
      n === BEGIN_DATE_TERM ||
      n === END_DATE_TERM
    ) {
      // eslint-disable-next-line no-continue
      continue;
    }
    // The 'searchType' defaults to PARTIAL, but the search terms below must be EXACT.
    let sType = SEARCH_TYPE_PARTIAL;
    if (
      n === PAYMENT_TYPE_SEARCH_TERM ||
      n === TOTAL_AMOUNT_SEARCH_TERM ||
      n === SHOPPER_ID_SEARCH_TERM ||
      n === EMAIL_SEARCH_TERM ||
      n === ORDER_NUMBER_SEARCH_TERM
    ) {
      sType = SEARCH_TYPE_EXACT;
    }

    const v = searchTermObj[n];

    // First and last name terms check both billing and shipping addresses.
    let term;
    if (n === FIRST_NAME_SEARCH_TERM) {
      term = buildMultiPathSearchTerm(
        [BILLING_FIRST_NAME_SEARCH_TERM, SHIPPING_FIRST_NAME_SEARCH_TERM],
        v,
        sType
      );
    } else if (n === LAST_NAME_SEARCH_TERM) {
      term = buildMultiPathSearchTerm(
        [BILLING_LAST_NAME_SEARCH_TERM, SHIPPING_LAST_NAME_SEARCH_TERM],
        v,
        sType
      );
    } else {
      term = buildSearchTerm(n, v, sType);
    }
    queryArray.push(term);
  }
  return queryArray;
};

/**
 * Create a DALi query parameter string given
 * @param {*} searchTermObj
 * @returns
 */
const createQueryParams = (searchTermObj, page) => {
  let q = '';
  // Start with required field request params.
  for (let i = 0; i < FIELD_REQUESTS.length; i += 1) {
    if (i > 0) {
      q += '&';
    }

    const fr = FIELD_REQUESTS[i];
    q += `fieldRequests=${fr}`;
  }
  // Now pull out search terms that belong on the query param line.
  const pnames = Object.getOwnPropertyNames(searchTermObj);
  for (let i = 0; i < pnames.length; i += 1) {
    const n = pnames[i];
    let v = searchTermObj[n];
    if (n === BEGIN_DATE_TERM || n === END_DATE_TERM) {
      v = appendTimeToDateStr(n, v);
      const term = `&${n}=${v}`;
      q += term;
    } else if (n === TENANT_SEARCH_TERM || n === SHOPPER_ID_SEARCH_TERM) {
      const term = `&${n}=${v}`;
      q += term;
    }
    // ... all other search terms are handled in 'createQueryArray' as POST data.
  }
  // Finally, add params that are common to all.
  q += QUERY_PARAM_SUFFIX + page;

  return q;
};

/** TIME FORMAT: 2021-01-01T00:00:00.001Z */

/**
 * Common API call to DALi.
 * @param environment
 * @param {*} postData search criteria data.
 * @param {string} queryParamStr query parameter string.
 * @returns array of results
 */
const internalDaliCall = (environment, postData, queryParamStr) => {
  const daliConfig = fetchDALIConfigForEnvironment(environment);
  const { idToken } = getAuthorizationHeaderAndShopperId();

  const axiosInstance = axios.create({
    headers: buildStandardHeaders(idToken),
    baseURL: daliConfig.baseUrl,
  });

  console.log(
    `DALi search: params=${queryParamStr}; postData=${JSON.stringify(postData)}`
  );

  return axiosInstance
    .post(`/api/search?${queryParamStr}`, postData)
    .then(response => {
      ensureRequestWasSuccessfulOrThrow(response);
      return processOrders(response.data);
    })
    .catch(err => {
      if (err.response.data.error) {
        throw new Error(err.response.data.error);
      }
      throw err;
    });
};

/**
 * Perform a search based on a search term object.
 * The keys on the object MUST match the API search terms.
 * @param {*} environment prod or dev
 * @param {*} searchTermObj object with name/values
 * @param {*} page of results
 * @returns
 */
export const fetchOrdersForMultipleSearchTerms = (
  environment,
  searchTermObj,
  page
) => {
  const postData = createQueryArray(searchTermObj);
  const queryParams = createQueryParams(searchTermObj, page);

  return internalDaliCall(environment, postData, queryParams);
};

/**
 * Fetch order data
 * @param environment
 * @param orderNumber
 * @returns {Promise<AxiosResponse<any>>}
 */
export const orderDataFetch = async (environment, orderNumber) => {
  const daliConfig = fetchDALIConfigForEnvironment(environment);
  const { idToken } = getAuthorizationHeaderAndShopperId();

  const axiosInstance = axios.create({
    headers: buildStandardHeaders(idToken),
    baseURL: daliConfig.baseUrl,
  });

  const url = `/api/orders/${orderNumber}?`;
  return axiosInstance
    .get(url)
    .then(response => {
      ensureRequestWasSuccessfulOrThrow(response);
      return response.data;
    })
    .catch(err => {
      throw err;
    });
};
