import React from 'react';
import PropTypes from 'prop-types';
import {
  SwanProvider,
  SwanHead,
  SWAN_STYLE_KEY_MAP,
  getRootClassNames,
} from '@vp/swan';

export const SwanEnabler = props => {
  const { children } = props;
  const eagerlyLoadedSwanStyles = [
    SWAN_STYLE_KEY_MAP.accordion,
    SWAN_STYLE_KEY_MAP.alertBox,
    SWAN_STYLE_KEY_MAP.button,
    SWAN_STYLE_KEY_MAP.carousel,
    SWAN_STYLE_KEY_MAP.controlIcon,
    SWAN_STYLE_KEY_MAP.core,
    SWAN_STYLE_KEY_MAP.grid,
    SWAN_STYLE_KEY_MAP.icon,
    SWAN_STYLE_KEY_MAP.listbox,
    SWAN_STYLE_KEY_MAP.modalDialog,
    SWAN_STYLE_KEY_MAP.popover,
    SWAN_STYLE_KEY_MAP.spinner,
    SWAN_STYLE_KEY_MAP.selectionSet,
    SWAN_STYLE_KEY_MAP.standardTile,
    SWAN_STYLE_KEY_MAP.table,
    SWAN_STYLE_KEY_MAP.tabs,
    SWAN_STYLE_KEY_MAP.toggleSwitch,
    SWAN_STYLE_KEY_MAP.utility,
  ];
  const rootClassName = getRootClassNames();

  return (
    <SwanProvider>
      <SwanHead styleSheetKeys={eagerlyLoadedSwanStyles} />
      <div className={rootClassName}>{children}</div>
    </SwanProvider>
  );
};

SwanEnabler.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
