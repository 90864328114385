import React from 'react';
import PropTypes from 'prop-types';
import {DateFormatter, DateFormatTypes} from '@vp/om-date-formatter';

const Date = ({date, dateFormatType = DateFormatTypes.MEDIUM_FMT}) => {
  const locale = 'en-US';
  return (
      <DateFormatter date={date} locale={locale} formatType={dateFormatType}/>
  );
};

Date.propTypes = {
  date: PropTypes.string,
  dateFormatType: PropTypes.string,
};

export default Date;
