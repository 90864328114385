import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AddressDisplay } from '@vp/address-form/src/components/AddressDisplay';
import { Typography } from '@vp/swan';
import { OrderConsoleContext } from '../../../../../contexts/OrderConsoleContext';

export const PickupPointDisplay = ({ pickupPointAddressData }) => {
  const { order } = useContext(OrderConsoleContext);
  const { locale } = order;
  const pickupPointName = pickupPointAddressData.metadata?.pickupPointName;
  const {
    streetName,
    city,
    postalCode,
    state,
    country,
    firstName,
    lastName,
    phone,
  } = pickupPointAddressData;

  // To preserve PuP address display order, we need to keep the 'pickupPersonNameBlock' and 'pickupPersonPhoneBlock',
  // and have AddressDisplay from address-form handle the street name, state and country name etc.
  const pickupPersonNameBlock = `Pick up by: ${firstName} ${lastName}`;
  const pickupPersonPhoneBlock = `${phone}`;

  return (
    <div>
      <Typography weight="bold">Pickup Point</Typography>
      <Typography>{pickupPointName}</Typography>
      <AddressDisplay
        address={{
          streetName,
          city,
          state,
          postalCode,
          country,
        }}
        locale={locale}
      />
      <Typography>{pickupPersonNameBlock}</Typography>
      <Typography>{pickupPersonPhoneBlock}</Typography>
    </div>
  );
};

PickupPointDisplay.propTypes = {
  pickupPointAddressData: PropTypes.object.isRequired,
};
