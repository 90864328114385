import React, { useState } from 'react';
import {
  Popover,
  PopoverContent,
  PopoverLauncher,
  FlexBox,
  Box,
  Typography,
  Icon,
  Callout,
  Card,
  Button,
  Spinner,
} from '@vp/swan';
import useIdentityContext from '../../hooks/useIdentityContext';
import { logout } from './auth';

const AccountStatusCallout = ({ isSignedIn }) => (
  <Callout backgroundColor={isSignedIn ? 'light-blue' : 'light-gray'} ml={4}>
    {isSignedIn ? 'True' : 'False'}
  </Callout>
);

const AccountDetail = ({ label, value }) => (
  <FlexBox justifyContent="space-between" alignItems="center" my={2}>
    <Typography fontWeight="bold">{label}:</Typography>
    <Box ml={4}>{value}</Box>
  </FlexBox>
);

const AccountDetails = () => {
  const { identity } = useIdentityContext();
  const { cimpressADFSUserId: email, isInternalUser, isSignedIn } = identity;
  return (
    <Card bordered mb={2} style={{ width: '100%' }}>
      <Box>
        <AccountDetail
          label="Email"
          value={email || <Icon iconType="straight" size="20p" />}
        />

        <AccountDetail
          label="Signed in"
          value={<AccountStatusCallout isSignedIn={isSignedIn} />}
        />

        <AccountDetail
          label="Internal user"
          value={<AccountStatusCallout isSignedIn={isInternalUser} />}
        />
      </Box>
    </Card>
  );
};

export const IdentityPopover = () => {
  const { identity, auth } = useIdentityContext();
  const { cimpressADFSUserId: email, isInternalUser, isSignedIn } = identity;

  const iconType = isSignedIn && !isInternalUser ? 'error' : 'account';
  const iconSkin = isSignedIn && !isInternalUser ? 'error' : 'standard';

  const [authLoading, setAuthLoading] = useState(false);

  const launcherCopy = () => {
    if (isSignedIn && isInternalUser) {
      return <>Hello, {email}!</>;
    }
    if (isSignedIn && !isInternalUser) {
      return 'Unauthorized user';
    }
    if (!isSignedIn) {
      return 'Sign in';
    }
  };

  const dialogCopy = () => {
    if (isSignedIn && isInternalUser) {
      return 'You are signed in';
    }
    if (isSignedIn && !isInternalUser) {
      return <b>You are not signed in as an internal user.</b>;
    }
    if (!isSignedIn) {
      return 'You are not signed in';
    }
  };

  const dialogCta = () => {
    // If the user is signed in as an internal user, offer a sign out button
    if (isSignedIn && isInternalUser) {
      return (
        <Button
          size="mini"
          skin="primary"
          my={1}
          disabled={authLoading}
          onClick={() => {
            setAuthLoading(true);
            logout();
          }}
        >
          {authLoading && <Spinner size="tiny" />}
          Sign out
        </Button>
      );
    }

    // If the user is NOT signed in as an internal user, offer a sign in button
    // that logs them out to invoke a new sign-in with fresh credentials
    if (isSignedIn && !isInternalUser) {
      return (
        <Button
          size="mini"
          skin="primary"
          my={1}
          disabled={authLoading}
          onClick={() => {
            setAuthLoading(true);
            logout();
          }}
        >
          {authLoading && <Spinner size="tiny" />}
          Sign in
        </Button>
      );
    }

    // If the user is not signed in, offer a sign in button
    if (!isSignedIn) {
      return (
        <Button
          size="mini"
          skin="primary"
          my={1}
          disabled={authLoading}
          onClick={() => {
            setAuthLoading(true);
            auth.signIn();
          }}
        >
          {authLoading && <Spinner size="tiny" />}
          Sign in
        </Button>
      );
    }
    return null;
  };

  return (
    <Popover position="bottom">
      <PopoverLauncher>
        <FlexBox justifyContent="center" alignItems="center">
          <Icon iconType={iconType} skin={iconSkin} size="20p" />
          {launcherCopy()}
        </FlexBox>
      </PopoverLauncher>

      <PopoverContent dialog>
        <Box p={4}>
          <FlexBox
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Icon iconType={iconType} skin={iconSkin} size="32p" />
            <Typography fontWeight="bold" mb={2} align="center">
              {dialogCopy()}
            </Typography>
            <AccountDetails />
            {dialogCta()}
          </FlexBox>
        </Box>
      </PopoverContent>
    </Popover>
  );
};
