import React from 'react';
import {
  BasicPreloader,
  BoundedContent,
  Column,
  GridContainer,
  Row,
  Typography,
  Button,
} from '@vp/swan';
import { Link as RouterLink } from '@reach/router';
import useIdentityContext from '../../../hooks/useIdentityContext';
import TitlePanel from './TitlePanel';

const ResubmissionMain = () => {
  const { identity } = useIdentityContext();
  const { isSignedIn } = identity;

  return isSignedIn ? (
    <BoundedContent>
      <GridContainer>
        <Row mt={5} mb={5}>
          <TitlePanel />
        </Row>
        <Row>
          {' '}
          <Column>
            <Typography component="h4" mt={5} mb={5}>
              Please choose how to resubmit your orders
            </Typography>
          </Column>
        </Row>
        <Column mb={5}>
          <Button
            skin="primary"
            sizeVariant="standard"
            widthVariant="standard"
            hasIcon
            iconPosition="right"
            mr={2}
          >
            <RouterLink to="/orderResubmission">
              Resubmit Orders Via CSV File
            </RouterLink>
          </Button>
          <Button
            skin="primary"
            sizeVariant="standard"
            widthVariant="standard"
            hasIcon
            iconPosition="right"
          >
            <RouterLink to="/incidentResubmission">
              Resubmit Orders In an Incident
            </RouterLink>
          </Button>
        </Column>
      </GridContainer>
    </BoundedContent>
  ) : (
    <BasicPreloader sizeVariant="large" centered my={6} />
  );
};

export default ResubmissionMain;
