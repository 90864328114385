import React, { useContext, useEffect, useState } from 'react';
import {
  BasicCollapsible,
  Checkbox,
  Column,
  Divider,
  FormError,
  FormField,
  FormInputGroup,
  FormLabel,
  GridContainer,
  Row,
  SelectionSet,
  SelectionSetInput,
  SelectionSetLabel,
  StandardForm,
  Button,
  TextInput,
  Typography,
} from '@vp/swan';
import { navigate } from '@reach/router';
import { EnvironmentContext } from '../../../contexts/EnvironmentContext';
import {
  fetchMilestones,
  orderJourneyFetch,
} from '../../../api/backendOrderRemediationToolService';
import { OrderTimelineContext } from '../../../contexts/OrderTimelineContext';
import ItemFilter from './ItemFilter';
import MilestoneFilter from './MilestoneFilter';
import {
  trackVoyagerOrderSearch,
  trackVoyagerOrderSearchError,
} from '../../../api/newRelicTrackingApi';
import useIdentityContext from '../../../hooks/useIdentityContext';

/**
 * Home for all search filters for the order timeline.
 * This component is responsible for presenting the possible search filters and then making the
 * request to BORT to get the data based on the selected filters.
 * This component uses `setVisualizationData` from the `OrderTimelineContext` to
 * set the timeline viz data it gets back from BORT.
 * @returns {JSX.Element}
 * @constructor
 */
const TimelineFilters = () => {
  const { environment } = useContext(EnvironmentContext);
  const { identity } = useIdentityContext();
  const { cimpressADFSUserId } = identity;
  const [orderNumberMismatch, setOrderNumberMismatch] = useState(false);

  const {
    iconsOnly,
    setIconsOnly,
    searchOrderNumber,
    setSearchOrderNumber,
    setSearchObjectFilter,
    setMilestones,
    invalidOrderNumber,
    setInvalidOrderNumber,
    timelineItems,
    selectedTimelineItems,
    setTimelineItems,
    selectedMilestones,
    setIsLoading,
    searchObjectFilter,
    setVisualizationData,
    milestones,
    orderNumber,
  } = useContext(OrderTimelineContext);

  let displayString = `Search Criteria:`;
  if (searchOrderNumber) {
    displayString += ` ${searchOrderNumber}`;
  }

  useEffect(() => {
    if (environment) {
      fetchMilestones(environment).then(response => {
        setMilestones(response);
      });
    }
  }, [environment, setMilestones]);

  const search = async () => {
    if (!searchOrderNumber || searchOrderNumber === '') {
      setInvalidOrderNumber(true);
    } else {
      navigate(`/orderVoyager/${environment}/${searchOrderNumber}`, {
        replace: false,
      });
    }
  };

  useEffect(() => {
    setInvalidOrderNumber(false);
  }, [environment, setInvalidOrderNumber]);

  const validateSearchFilters = () => {
    if (!searchOrderNumber) {
      setOrderNumberMismatch(false);
      setInvalidOrderNumber(true);
    } else if (searchOrderNumber !== orderNumber) {
      setInvalidOrderNumber(false);
      setOrderNumberMismatch(true);
    } else {
      setInvalidOrderNumber(false);
      setOrderNumberMismatch(false);
    }
  };

  // each selected milestone has a property (filterParams) that dictates which
  // which milestones to send to BORT to limit the result set
  const identifyMilestoneFilters = () => {
    const milestoneFilters = [];
    if (selectedMilestones) {
      // loop through the object/map to determine if the milestone is selected.
      // if so, add its filterParams to the list
      Object.keys(selectedMilestones).map(selectedMilestone => {
        const isSelected = selectedMilestones[selectedMilestone];
        if (isSelected) {
          const matchedMilestone = milestones.find(
            m => m.name === selectedMilestone
          );
          if (matchedMilestone) {
            milestoneFilters.push(...matchedMilestone.filterParams);
          }
          return true;
        }
        return false;
      });
    }
    return milestoneFilters;
  };

  const filterItems = orderVizData => {
    const selectedItemsArray = [];
    if (selectedTimelineItems) {
      Object.keys(selectedTimelineItems).map(selectedLineItem => {
        const isSelected = selectedTimelineItems[selectedLineItem];
        if (isSelected) {
          selectedItemsArray.push(selectedLineItem);
          return true;
        }
        return false;
      });
    }
    if (selectedItemsArray.length > 0) {
      orderVizData.groups.forEach(group => {
        if (!selectedItemsArray.includes(group.id)) {
          group.visible = false;
        } else {
          group.visible = true;
        }
      });
    } else {
      orderVizData.groups.forEach(group => {
        group.visible = true;
      });
    }
  };

  const applyFilters = async () => {
    if (!orderNumber) {
      await search();
      return;
    }
    setIsLoading(true);
    validateSearchFilters();
    const milestoneFilters = identifyMilestoneFilters();
    try {
      const orderVizData = await orderJourneyFetch(
        environment,
        orderNumber,
        milestoneFilters,
        searchObjectFilter
      );
      setTimelineItems(orderVizData.groups);
      filterItems(orderVizData);
      setVisualizationData(orderVizData);
      setIconsOnly(iconsOnly);

      const voyagerSearchSuccessMetricData = {
        environment,
        orderNumber,
        milestoneFilters,
        searchObjectFilter,
      };
      trackVoyagerOrderSearch(
        voyagerSearchSuccessMetricData,
        cimpressADFSUserId
      );
      setIsLoading(false);
    } catch (err) {
      const voyagerSearchErrorMetricData = {
        environment,
        searchOrderNumber,
        milestoneFilters,
        searchObjectFilter,
      };
      trackVoyagerOrderSearchError(
        err,
        voyagerSearchErrorMetricData,
        cimpressADFSUserId
      );
      setInvalidOrderNumber(true);
      setIsLoading(false);
    }
  };

  return (
    <GridContainer>
      <Divider mt={8} mb={3} />
      <Typography weight="bold" textSize={5} mt={3}>
        {displayString}
      </Typography>
      <StandardForm variant="horizontal">
        <Row component={FormField} mt={5}>
          <Column span={3}>
            <FormLabel>Order Number:</FormLabel>
          </Column>
          <Column span={7}>
            <FormInputGroup>
              <TextInput
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    search();
                  }
                }}
                defaultValue={searchOrderNumber}
                onChange={event => {
                  if (event.target.value !== '') {
                    setInvalidOrderNumber(false);
                  }
                  setSearchOrderNumber(event.target.value);
                }}
              />
              {invalidOrderNumber && (
                <FormError>
                  An error occurred. Please verify order number is valid for{' '}
                  {environment}
                </FormError>
              )}
              {orderNumberMismatch && (
                <FormError>
                  Search criteria has changed. Click "Search" to view the
                  timeline for {searchOrderNumber}
                </FormError>
              )}
            </FormInputGroup>
          </Column>
          <Column>
            <Button
              skin="primary"
              ml={5}
              onClick={() => {
                search();
              }}
            >
              Search
            </Button>
          </Column>
        </Row>
      </StandardForm>
      <BasicCollapsible heading="Filters" mb={6} mt={6} defaultExpanded={false}>
        <ItemFilter timelineItems={timelineItems} />
        <Row component={FormField} mb={3}>
          <Column span={3}>
            <FormLabel>Display Options:</FormLabel>
          </Column>
          <Column span={9}>
            <SelectionSet
              variant="single-select"
              skin="buttons"
              defaultSelectedValue="justMilestones"
              onSelectedValueChange={newSelectedValue => {
                setSearchObjectFilter(newSelectedValue);
              }}
            >
              <SelectionSetInput value="justMilestones">
                <SelectionSetLabel>Milestones</SelectionSetLabel>
              </SelectionSetInput>
              <SelectionSetInput value="justEvents">
                <SelectionSetLabel>Events</SelectionSetLabel>
              </SelectionSetInput>
            </SelectionSet>
          </Column>
        </Row>
        <MilestoneFilter />
        <Row component={FormField} mb={3}>
          <Column span={3}>
            <FormLabel>Compact View (Icons Only, Hover for Details):</FormLabel>
          </Column>
          <Column span={9}>
            <Checkbox
              variant="multi-select"
              skin="buttons"
              onChange={() => {
                setIconsOnly(!iconsOnly);
              }}
            />
          </Column>
        </Row>
        <Row>
          <Column span={9} />
          <Column>
            <Button size="super" onClick={() => search()}>
              Reset Filters
            </Button>
            <Button skin="primary" onClick={() => applyFilters()}>
              Apply Filters
            </Button>
          </Column>
        </Row>
      </BasicCollapsible>
    </GridContainer>
  );
};

export default TimelineFilters;
