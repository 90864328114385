import {
  AlertBox,
  Card,
  GridContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableScrollContainer,
} from '@vp/swan';
import React from 'react';
import ReactJson from 'react-json-view';
import formatDate from '../../common/formatDate';

export const FulfillmentHolds = ({ lineItem }) => {
  const { fulfillmentHolds: v1Holds, fulfillmentHoldsV2: v2Holds } = lineItem;

  v1Holds.forEach(hold => {
    hold.version = 'v1';
  });

  v2Holds.forEach(hold => {
    hold.version = 'v2';
  });

  const holds = v2Holds.concat(v1Holds);

  return (
    <GridContainer>
      <Card evenHeight bordered>
        <TableScrollContainer>
          <Table skin="simple">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>
                  Id :
                  <span className="table-text-second-line"> Version</span>
                </TableCell>
                <TableCell>
                  Type :
                  <span className="table-text-second-line"> Owner</span>
                </TableCell>
                <TableCell>Additional Data</TableCell>
                <TableCell>Release Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {holds.map(hold => (
                <TableRow>
                  <TableCell>
                    <AlertBox skin={hold.active ? 'error' : 'positive'}>
                      {hold.active ? 'Active' : 'Released'}
                    </AlertBox>
                  </TableCell>
                  <TableCell>
                    <b>{hold.id} : </b>
                    <span className="table-text-second-line">
                      {hold.version}
                    </span>
                  </TableCell>
                  <TableCell>
                    <b>{hold.type} : </b>
                    <span className="table-text-second-line">{hold.owner}</span>
                  </TableCell>
                  <TableCell>
                    {hold.additionalData && (<ReactJson
                      src={hold.additionalData}
                      collapsed
                      name={false}
                      enableClipboard={false}
                      displayObjectSize={false}
                      displayDataTypes={false}
                    />
                        )}
                  </TableCell>
                  <TableCell>{formatDate(hold.releaseDate)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableScrollContainer>
      </Card>
    </GridContainer>
  );
};
