import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FlexBox, Typography } from '@vp/swan';
import CurrencyWrapper from '../currency/CurrencyWrapper';
import {
  TAXED,
  TOTAL_DISCOUNTED_PRICE,
  TOTAL_LIST_PRICE,
  UNTAXED,
} from './itemAccessors';
import { currencyLocaleFormatter } from '../currency/currencyLocaleFormatter';
import { OrderConsoleContext } from '../../../../contexts/OrderConsoleContext';

/*
 * For non-US tenants, item pricing uses the taxed price to display the gross of attributes and the total.
 * For US tenants, item pricing uses the untaxed price to display the net of attributes and the total.
 * Order level is tax inclusive which allows for an easy display of the difference between net and gross.
 * This may change in the future and item totals may be taxed while attributes are shown untaxed.
 * 'pricingBreakdown' is for a new platform ("V3") item and includes attribute pricing.
 * 'migratedPricing' is for a legacy item and has minimal pricing details.
 * Callers typically will pass only one of the arguments.
 * For a migrated order, we do not try to show cross out pricing. This is because we are missing a taxed list price
 * for a majority of migrated items.  For migrated items, we just default to always showing the discountedPrice alone
 */
export const ItemTotalSummary = ({ lineItem }) => {
  const { order, includeTax } = useContext(OrderConsoleContext);
  const { migrated: isMigratedOrder, locale } = order;
  const {
    totalListPrice,
    totalDiscountedPrice,
    pricingBreakdownV3: pricingBreakdown,
  } = lineItem;

  const currency = totalListPrice && totalListPrice.currencyCode;

  const totalListPriceField = includeTax
    ? pricingBreakdown[TOTAL_LIST_PRICE][TAXED]
    : pricingBreakdown[TOTAL_LIST_PRICE][UNTAXED];
  const totalDiscountedPriceField = includeTax
    ? pricingBreakdown[TOTAL_DISCOUNTED_PRICE][TAXED]
    : pricingBreakdown[TOTAL_DISCOUNTED_PRICE][UNTAXED];

  // In ODUI we render centAmount for migrated items, but they seem to have the totalListPrice field, so leaving this behavior as a fallback
  const itemListPrice = totalListPriceField || totalListPrice.centAmount;
  const itemDiscountPrice =
    totalDiscountedPriceField || totalDiscountedPrice.centAmount;

  const listCents = isMigratedOrder ? itemDiscountPrice : itemListPrice;

  return (
    <FlexBox justifyContent="space-between" marginY={4}>
      <Typography>Item Total</Typography>
      <Typography weight="bold">
        <CurrencyWrapper
          fractionDigits={totalListPrice.fractionDigits}
          typeSize={5}
          listCents={listCents}
          discountCents={itemDiscountPrice}
          currencyCode={currency}
          locale={currencyLocaleFormatter(locale)}
        />
      </Typography>
    </FlexBox>
  );
};

ItemTotalSummary.propTypes = {
  lineItem: PropTypes.object,
};
