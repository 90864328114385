import { Column, GridContainer, Link, Row, Button, Typography } from '@vp/swan';
import React, { useContext, useState } from 'react';
import { navigate } from '@reach/router';
import {
  EMAIL_SEARCH_TERM,
  PHONE_SEARCH_TERM,
  FIRST_NAME_SEARCH_TERM,
  LAST_NAME_SEARCH_TERM,
  TOTAL_AMOUNT_SEARCH_TERM,
  PAYMENT_AMOUNT_SEARCH_TERM,
  TENANT_SEARCH_TERM,
  BEGIN_DATE_TERM,
  END_DATE_TERM,
  PAYMENT_TYPE_SEARCH_TERM,
  PAYMENT_TYPES,
} from '../../../api/daliService';
import AdvancedLookupField from './AdvancedLookupField';
import AdvancedLookupDropdown from './AdvancedLookupDropdown';
import AdvancedLookupCheckbox from './AdvancedLookupCheckbox';
import { EnvironmentContext } from '../../../contexts/EnvironmentContext';

const ADDRESS_TYPE_BILLING = 'Billing';
const ADDRESS_TYPE_SHIPPING = 'Shipping';
const ADDRESS_TYPES = [ADDRESS_TYPE_BILLING, ADDRESS_TYPE_SHIPPING];

const AMOUNT_TYPE_PRICE = 'Total Price';
const AMOUNT_TYPE_PAYMENT = 'Single Payment';
const AMOUNT_TYPES = [AMOUNT_TYPE_PRICE, AMOUNT_TYPE_PAYMENT];

const NO_PAYMENT_TYPE = PAYMENT_TYPES[0];

const AdvancedLookup = ({ searchFn, profileSearchFn }) => {
  const [searchEmailAddress, setSearchEmailAddress] = useState(undefined);
  const [searchOrderNumber, setSearchOrderNumber] = useState(undefined);
  const [searchFirstName, setSearchFirstName] = useState(undefined);
  const [searchLastName, setSearchLastName] = useState(undefined);
  const [searchPhoneNumber, setSearchPhoneNumber] = useState(undefined);
  const [searchTenant, setSearchTenant] = useState(undefined);
  const [searchOrderAmount, setSearchOrderAmount] = useState(undefined);
  const [searchBeginDate, setSearchBeginDate] = useState(undefined);
  const [searchEndDate, setSearchEndDate] = useState(undefined);
  const [searchPaymentType, setSearchPaymentType] = useState(NO_PAYMENT_TYPE);
  const [isUsingProfiles, setIsUsingProfiles] = useState(false);
  const [searchAmountType, setSearchAmountType] = useState(AMOUNT_TYPE_PRICE);

  // Error state
  const [isEmailError, setEmailError] = useState(false);
  const [isPhoneError, setPhoneError] = useState(false);
  const [isOrderNumberError, setOrderNumberError] = useState(false);
  const [isAmountError, setAmountError] = useState(false);
  const [isTenantError, setTenantError] = useState(false);
  const [isBeginDateError, setBeginDateError] = useState(false);
  const [isEndDateError, setEndDateError] = useState(false);
  const { environment } = useContext(EnvironmentContext);

  /**
   * Validate for format: yyyy-mm-dd
   * @param {*} dateStr
   * @returns
   */
  const validateDate = dateStr => {
    const re = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateStr) {
      return false;
    }
    const ok = re.exec(dateStr);
    return ok;
  };

  const doSearch = async () => {
    const terms = {};
    if (searchFirstName) {
      terms[FIRST_NAME_SEARCH_TERM] = searchFirstName;
    }
    if (searchLastName) {
      terms[LAST_NAME_SEARCH_TERM] = searchLastName;
    }
    if (searchEmailAddress) {
      // No validation, since we may search on partial email addresses.
      terms[EMAIL_SEARCH_TERM] = searchEmailAddress;
    }
    if (searchPhoneNumber) {
      // Strip out any dashes or parentheses.
      const phoneTerm = searchPhoneNumber.replace(/-|\(|\)/g, '');
      // Should be just numbers now.
      if (Number.isNaN(phoneTerm)) {
        setPhoneError(true);
        return;
      }
      terms[PHONE_SEARCH_TERM] = phoneTerm;
    }
    if (searchOrderAmount) {
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(searchOrderAmount)) {
        setAmountError(true);
        return;
      }
      if (searchAmountType === AMOUNT_TYPE_PRICE) {
        terms[TOTAL_AMOUNT_SEARCH_TERM] = searchOrderAmount;
      } else {
        terms[PAYMENT_AMOUNT_SEARCH_TERM] = searchOrderAmount;
      }
    }
    if (searchTenant) {
      // Better would be a check with tenant config service, but for now...
      if (!searchTenant.includes('-')) {
        setTenantError(true);
        return;
      }
      terms[TENANT_SEARCH_TERM] = searchTenant;
    }
    if (searchBeginDate) {
      if (!validateDate(searchBeginDate)) {
        setBeginDateError(true);
        return;
      }
      terms[BEGIN_DATE_TERM] = searchBeginDate;
    }
    if (searchEndDate) {
      if (!validateDate(searchEndDate)) {
        setEndDateError(true);
        return;
      }
      terms[END_DATE_TERM] = searchEndDate;
    }
    if (searchPaymentType !== NO_PAYMENT_TYPE) {
      terms[PAYMENT_TYPE_SEARCH_TERM] = searchPaymentType;
    }

    searchFn(terms);

    if ((searchFirstName && searchLastName) || searchEmailAddress) {
      profileSearchFn(
        searchFirstName,
        searchLastName,
        searchEmailAddress,
        isUsingProfiles
      );
    }
  };

  const checkEnter = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      doSearch();
    }
  };

  const goToConsole = () => {
    if (searchOrderNumber) {
      // Order numbers may use underscores or dashes as separators.
      if (
        !searchOrderNumber.includes('_') &&
        !searchOrderNumber.includes('-')
      ) {
        setOrderNumberError(true);
        return;
      }
      navigate(`/orderConsole/${environment}/${searchOrderNumber}`, {
        replace: false,
      });
    }
  };

  const checkOrderEnter = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      goToConsole();
    }
  };

  return (
    <GridContainer>
      <Row mb={0} padding={2} span={12}>
        <Typography size={4}>
          Have an order number already? Enter it here to see it in the console.
        </Typography>
      </Row>
      <Row mb={3} ml={3} span={12}>
        <AdvancedLookupField
          label="Order Number"
          placeholderText="VP_ABC123"
          tooltipText="An Order Number search term MUST contain an underscore ('_') or a dash ('-').  The term must match exactly but is case-insensitive."
          errorText="A valid order number is required."
          span={2}
          checkEnterFn={checkOrderEnter}
          setErrFn={setOrderNumberError}
          setSearchFn={setSearchOrderNumber}
          isError={isOrderNumberError}
        />{' '}
        <Column offset={1}>
          <Button skin="primary" mt={4} onClick={() => goToConsole()}>
            Go
          </Button>
        </Column>
      </Row>
      <Row mb={0} padding={2} span={12}>
        <Typography size={4}>
          To search for orders that match multiple properties, enter values in
          any combination of the search fields below.
          <br />
          For more information on the underlying search mechanism used, see the{' '}
          {}
          <Link
            href="https://vistaprint.atlassian.net/wiki/spaces/OMT/pages/2590640462/Order+Search+-+2022+and+beyond"
            target="_blank"
          >
            DALi page.
          </Link>
        </Typography>
      </Row>
      <Row span={12} ml={3}>
        <Typography overline my="2">
          Customer
        </Typography>
      </Row>
      <Row mb={3} ml={3} span={12}>
        <AdvancedLookupField
          label="First Name"
          placeholderText="John"
          tooltipText="First name searches use a partial match, and are NOT case-sensitive."
          errorText=""
          span={2}
          checkEnterFn={checkEnter}
          setErrFn={setEmailError}
          setSearchFn={setSearchFirstName}
          isError={false}
        />
        <AdvancedLookupField
          label="Last Name"
          placeholderText="Smith"
          tooltipText="Last name searches use a partial match, and are NOT case-sensitive."
          errorText=""
          span={2}
          checkEnterFn={checkEnter}
          setErrFn={setEmailError}
          setSearchFn={setSearchLastName}
          isError={false}
        />
      </Row>
      <Row span={12} ml={3} mb={3}>
        <AdvancedLookupField
          label="Customer Email"
          placeholderText="customer@email.com"
          tooltipText="Customer Email searches use a partial match, and checks the order email field.  Searches are NOT case-sensitive."
          errorText="A valid (but at least partial) email address is required."
          span={2}
          checkEnterFn={checkEnter}
          setErrFn={setEmailError}
          setSearchFn={setSearchEmailAddress}
          isError={isEmailError}
        />
        <AdvancedLookupField
          label="Phone Number"
          placeholderText="6175551212"
          tooltipText="Phone Number searches will ignore dashes ('-') and parentheses, and will always use the shipping address. Partial phone numbers are accepted."
          errorText="A valid phone number is required."
          span={2}
          checkEnterFn={checkEnter}
          setErrFn={setPhoneError}
          setSearchFn={setSearchPhoneNumber}
          isError={isPhoneError}
        />
        <AdvancedLookupCheckbox
          label="Also search account profiles"
          setValueFn={setIsUsingProfiles}
          initialValue={isUsingProfiles}
          tooltipText="If the first and last name fields, and/or email fields are present, search the Profile Service for shoppers whose profiles match the query."
          span={3}
          offset={1}
        />
        <Column offset={1}>
          <Button skin="primary" mt={4} onClick={() => doSearch()}>
            Search
          </Button>
        </Column>
      </Row>
      <Row span={12} ml={3}>
        <Typography overline my="2">
          Order Details
        </Typography>
      </Row>
      <Row ml={3} span={12}>
        <AdvancedLookupField
          label="Tenant"
          placeholderText="VP-US-PROD"
          tooltipText="A Tenant search term MUST contain at least 1 dash ('-') and must match the Tenant ID exactly (but is case-insensitive). This term will filter results of any other query terms."
          errorText="A valid Order Management Tenant ID is required."
          span={2}
          checkEnterFn={checkEnter}
          setErrFn={setTenantError}
          setSearchFn={setSearchTenant}
          isError={isTenantError}
        />
        <AdvancedLookupField
          label="Order Amount (cents)"
          placeholderText="4132"
          tooltipText="Amount must be an integer, and must match exactly the order's cost in cents (that is, the total amount for the entire order including tax and any discounts, OR the amount for any specific payment)."
          errorText="A valid order amount (in cents) is required."
          span={3}
          checkEnterFn={checkEnter}
          setErrFn={setAmountError}
          setSearchFn={setSearchOrderAmount}
          isError={isAmountError}
        />
        <AdvancedLookupDropdown
          span={2}
          offset={0}
          label="Amount Type"
          setSearchValueFn={setSearchAmountType}
          initialValue={searchAmountType}
          valueArray={AMOUNT_TYPES}
          tooltipText="Select whether the queried amount is the total order price or a specific payment."
        />
        <AdvancedLookupDropdown
          span={2}
          offset={1}
          label="Payment Type"
          setSearchValueFn={setSearchPaymentType}
          initialValue={searchPaymentType}
          valueArray={PAYMENT_TYPES}
          tooltipText="Select payment type from list."
        />
      </Row>
      <Row span={12} ml={3}>
        <Column span={6}>
          <Typography overline my="2">
            Order Creation Date Range
          </Typography>
        </Column>
      </Row>
      <Row ml={3} span={12}>
        <AdvancedLookupField
          label="Begin Date"
          placeholderText="2020-04-12"
          tooltipText="Limit search to orders created on or after this date.  A Date search term must be of the form 'yyyy-mm-dd'."
          errorText="A valid date (yyyy-mm-dd) is required."
          span={2}
          checkEnterFn={checkEnter}
          setErrFn={setBeginDateError}
          setSearchFn={setSearchBeginDate}
          isError={isBeginDateError}
        />
        <AdvancedLookupField
          label="End Date"
          placeholderText="2020-04-12"
          tooltipText="Limit search to orders created on or before this date.  A Date search term must be of the form 'yyyy-mm-dd'."
          errorText="A valid date (yyyy-mm-dd) is required."
          span={2}
          checkEnterFn={checkEnter}
          setErrFn={setEndDateError}
          setSearchFn={setSearchEndDate}
          isError={isEndDateError}
        />
      </Row>
    </GridContainer>
  );
};

export default AdvancedLookup;
