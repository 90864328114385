import { GridContainer, Row, Button } from '@vp/swan';
import React, { useContext, useState } from 'react';
import ReactJson from 'react-json-view';

const CollapsibleOrderJson = ({ order }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleCopy = copy => {
    navigator.clipboard.writeText(JSON.stringify(copy.src, null, '\t'));
  };

  return (
    <GridContainer>
      <Row margin="auto" mb={4} mr={2}>
        {isCollapsed ? (
          <Button mb={4} mr={2} onClick={() => setIsCollapsed(false)}>
            Expand Order JSON
          </Button>
        ) : (
          <Button mb={4} mr={2} onClick={() => setIsCollapsed(true)}>
            Collapse Order JSON
          </Button>
        )}
      </Row>
      <Row ml={3} mt={3}>
        <ReactJson
          collapsed={isCollapsed}
          name={false}
          src={order}
          enableClipboard={handleCopy}
          displayObjectSize={false}
          displayDataTypes={false}
        />
      </Row>
    </GridContainer>
  );
};

export default CollapsibleOrderJson;
