import { AlertBox, AlertBoxDismissButton, Link, Typography } from '@vp/swan';
import React, { useContext } from 'react';
import { EnvironmentContext } from '../../../contexts/EnvironmentContext';

export const MISSING_PERMISSIONS = 'MISSING_PERMISSIONS';
export const ERROR = 'ERROR';

export const HydrationError = ({ ciaHydrationError, oehHydrationError }) => {
  const { environment } = useContext(EnvironmentContext);

  return (
    <>
      {oehHydrationError === ERROR && (
        <AlertBox skin="warning" mb={5}>
          Error fetching CIA Incidents
          <AlertBoxDismissButton visuallyHiddenLabel="Dismiss alert" />
        </AlertBox>
      )}
      {ciaHydrationError === ERROR && (
        <AlertBox skin="warning" mb={5}>
          Error fetching State Change Events
          <AlertBoxDismissButton visuallyHiddenLabel="Dismiss alert" />
        </AlertBox>
      )}
      {(ciaHydrationError === MISSING_PERMISSIONS ||
        oehHydrationError === MISSING_PERMISSIONS) && (
        <AlertBox skin="warning" mb={5}>
          {oehHydrationError === MISSING_PERMISSIONS && (
            <Typography>
              Unable to display state data because you are missing{' '}
              'order:read-event' permissions for the {environment} environment
            </Typography>
          )}
          {ciaHydrationError === MISSING_PERMISSIONS && (
            <Typography>
              Unable to display CIA Incident data because you are missing{' '}
              'read:order-remediation:failures' permissions for the{' '}
              {environment} environment
            </Typography>
          )}
          <Typography component="h4" mt={4}>
            Request access:
          </Typography>
          <Typography>
            Please contact{' '}
            <Link
              skin="cta"
              target="_blank"
              href="https://vistaprint.slack.com/archives/CNFFZMUD6"
            >
              LochNess Squad
            </Link>{' '}
          </Typography>
          <AlertBoxDismissButton visuallyHiddenLabel="Dismiss alert" />
        </AlertBox>
      )}
    </>
  );
};
