// react redux
import React from 'react';
import PropTypes from 'prop-types';
import CurrencyDisplay from './CurrencyDisplay';

/**
 * Component to display a single (possibly discounted) price.
 */
const CurrencyWrapper = ({
  fractionDigits,
  typeSize,
  typeStrong,
  listCents,
  discountCents,
  currencyCode,
  locale,
  label,
}) => {
  if (listCents !== null && listCents > 0 && listCents > discountCents) {
    return (
      <div>
        <CurrencyDisplay
          fractionDigits={fractionDigits}
          centPrice={listCents}
          currency={currencyCode}
          component="del"
          label={label}
          locale={locale}
          prependLabel
          typeSize={typeSize}
          typeStrong={typeStrong}
        />

        <CurrencyDisplay
          fractionDigits={fractionDigits}
          centPrice={discountCents}
          currency={currencyCode}
          discounted
          locale={locale}
          typeSize={typeSize}
          typeStrong={typeStrong}
        />
      </div>
    );
  }
  // there are instances for migrated orders where we do not have
  // a taxed listCents amount.  in that case, just fallback to discountCents
  let centsToDisplay;
  if (listCents !== null) {
    centsToDisplay = listCents;
  } else {
    centsToDisplay = discountCents !== null ? discountCents : 0;
  }

  return (
    <CurrencyDisplay
      fractionDigits={fractionDigits}
      centPrice={centsToDisplay}
      currency={currencyCode}
      label={label}
      locale={locale}
      typeSize={typeSize}
      typeStrong={typeStrong}
    />
  );
};

CurrencyWrapper.propTypes = {
  fractionDigits: PropTypes.number.isRequired,
  listCents: PropTypes.number,
  discountCents: PropTypes.number,
  currencyCode: PropTypes.string,
  label: PropTypes.string,
  locale: PropTypes.string,
  typeSize: PropTypes.number,
  typeStrong: PropTypes.bool,
};

export default CurrencyWrapper;
