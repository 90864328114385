/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { Router } from '@reach/router';
import { QueryClient, QueryClientProvider } from 'react-query';
import UILibrary from '../components/ui-library/ui-library';
import { IdentityProvider } from '../contexts/IdentityContext';
import ResubmissionMain from '../components/tools/resubmission/ResubmissionMain';
import { EnvironmentContext } from '../contexts/EnvironmentContext';
import NewRelic from '../components/newrelic/newrelic';
import NavigationBar from '../components/navigation/NavigationBar';
import RemediationHistory from '../components/history/RemediationHistory';
import Footer from '../components/navigation/Footer';
import OrderTimelineHome from '../components/tools/ordertimeline/OrderTimelineHome';
import PrivateRoute from '../components/navigation/PrivateRoute';
import OrderLookupHome from '../components/tools/lookup/OrderLookupHome';
import AgentVoyager from '../components/tools/ordertimeline/AgentVoyager';
import OrderResubmissionHome from '../components/tools/resubmission/OrderResubmissionHome';
import OrderConsole from '../components/tools/orderconsole/order/OrderConsole';
import OrderCopy from '../components/tools/ordercopy/OrderCopy';
import IncidentReport from '../components/tools/cia/IncidentReport';
import Main from '../components/Main';
import { SwanEnabler } from '../components/shared/SwanEnabler';

const Index = props => {
  const [environment, setEnvironment] = useState('vistaprint-production');
  // eslint-disable-next-line react/prop-types
  // eslint-disable-next-line react/destructuring-assignment
  const careAgent = props.location.pathname.includes('agentVoyager');

  /*
   * Create a react-query client
   * For more context about the react-query defaults please see their
   * documentation: https://react-query.tanstack.com/guides/important-defaults
   */
  const queryClient = new QueryClient();
  queryClient.setDefaultOptions({
    queries: {
      retry: false, // no silently retrying queries right now
      staleTime: 30 * 60 * 1000, // 30 minutes stale time
      refetchOnWindowFocus: false, // no unscheduled refetchs
      cacheTime: 60 * 60 * 1000, // 60 minutes cache time
    },
  });
  return (
    <>
      <Helmet>
        <title>Self Service Order Remediation</title>
      </Helmet>
      <NewRelic />
      <SwanEnabler>
        <UILibrary />
        <IdentityProvider>
          <QueryClientProvider client={queryClient}>
            <EnvironmentContext.Provider
              value={{
                environment,
                setEnvironment,
              }}
            >
              {!careAgent && <NavigationBar />}
              <Router>
                <PrivateRoute default path="/" component={Main} />
                <PrivateRoute
                  path="/remediationHistory"
                  component={RemediationHistory}
                />
                <PrivateRoute
                  path="/orderVoyager"
                  component={OrderTimelineHome}
                />
                <PrivateRoute
                  path="/orderVoyager/:environment/:orderNumber"
                  component={OrderTimelineHome}
                />
                <PrivateRoute
                  path="/orderVoyager"
                  component={OrderTimelineHome}
                />
                <PrivateRoute
                  path="/orderConsole/:environment/:orderNumber"
                  component={OrderConsole}
                />
                <PrivateRoute path="/orderCopy" component={OrderCopy} />
                <PrivateRoute
                  path="/orderCopy/:orderNumber"
                  component={OrderCopy}
                />
                <PrivateRoute
                  path="/orderCopy/:environment/:orderNumber"
                  component={OrderCopy}
                />
                <PrivateRoute path="/orderLookup" component={OrderLookupHome} />
                <PrivateRoute path="/agentVoyager" component={AgentVoyager} />
                <PrivateRoute
                  path="/agentVoyager/:orderNumber"
                  component={AgentVoyager}
                />
                <PrivateRoute
                  path="/orderResubmissionHome"
                  component={ResubmissionMain}
                />
                <PrivateRoute
                  path="/orderResubmission"
                  component={OrderResubmissionHome}
                />
                <PrivateRoute
                  path="/incidentResubmission"
                  component={IncidentReport}
                />
              </Router>
              <Footer />
            </EnvironmentContext.Provider>
          </QueryClientProvider>
        </IdentityProvider>
      </SwanEnabler>
    </>
  );
};

export default Index;
