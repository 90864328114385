import { GridContainer, Row, Button, Typography } from '@vp/swan';
import ReactJson from 'react-json-view';
import React, { useState } from 'react';
import formatDate from '../common/formatDate';

const MilestoneView = ({ milestone }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleCopy = copy => {
    navigator.clipboard.writeText(JSON.stringify(copy.src, null, '\t'));
  };

  return (
    <GridContainer>
      <Row>
        <Typography>{milestone.group}</Typography>
      </Row>
      {milestone.type === 'LONG_RUNNING' ? (
        <GridContainer>
          <Row>
            <Typography>Start: {formatDate(milestone.start)}</Typography>
          </Row>
          <Row>
            <Typography>End: {formatDate(milestone.end)}</Typography>
          </Row>
        </GridContainer>
      ) : (
        <GridContainer>
          <Row>
            <Typography>Date: {formatDate(milestone.start)}</Typography>
          </Row>{' '}
        </GridContainer>
      )}

      {milestone.events && milestone.events.length > 0 && (
        <Row>
          <GridContainer>
            <Row margin="auto">
              <Typography>Events: </Typography>
              {isCollapsed ? (
                <Button
                  ml={3}
                  mt={2}
                  sizeVariant="mini"
                  onClick={() => setIsCollapsed(false)}
                >
                  Expand Events
                </Button>
              ) : (
                <Button
                  ml={3}
                  mt={2}
                  sizeVariant="mini"
                  onClick={() => setIsCollapsed(true)}
                >
                  Collapse Events
                </Button>
              )}
            </Row>
            <Row ml={3} mt={3}>
              <ReactJson
                collapsed={isCollapsed}
                name={false}
                src={milestone.events}
                enableClipboard={handleCopy}
                displayObjectSize={false}
                displayDataTypes={false}
              />
            </Row>
          </GridContainer>
        </Row>
      )}
    </GridContainer>
  );
};

export default MilestoneView;
