import React, { useContext, useEffect, useState } from 'react';
import {
  BasicPreloader,
  BoundedContent,
  Collapsible,
  CollapsibleContent,
  CollapsibleSummary,
  CollapsibleSummaryButton,
  Column,
  GridContainer,
  Link,
  Row,
  Button,
  Typography,
} from '@vp/swan';
import { Link as NavLink } from '@reach/router';
import ResubmitPreviewTable from './preview/ResubmitPreviewTable';
import useIdentityContext from '../../../hooks/useIdentityContext';
import { EnvironmentContext } from '../../../contexts/EnvironmentContext';
import CSVUploader from '../common/CSVUploader';
import {
  removeOrderNumDuplicates,
  validateResubmissionRow,
} from '../../../utils/csvDataHelpers';
import {
  ERROR_CSV_DATA_ISSUE,
  ERROR_ORDER_LIMIT_EXCEEDED,
  RESUBMIT_ORDERS_LIMIT,
} from './resubmissionErrorTypes';
import ErrorPanel from './ErrorPanel';
import ResubmissionSuccessResultsTable from './ResubmissionSuccessResultsTable';
import ResubmissionFailureResultsTable from './ResubmissionFailureResultsTable';
import { buildMexUrl } from '../common/mcpMexUrlBuilder';
import {
  trackResubmissionComplete,
  trackResubmissionCsvIssue,
  trackResubmissionStarted,
} from '../../../api/newRelicTrackingApi';
import {
  initiateResubmit,
  refresh,
  resubmit,
} from '../../../api/backendOrderRemediationToolService';
import partitionResubmittableOrders from './partitionResubmittableOrders';
import CSVExporter from '../common/CSVExporter';

const OrderResubmissionHome = () => {
  const lookerDashboardUrl = 'https://cimpress.eu.looker.com/dashboards/5394';

  const [resubmissionRequested, setResubmissionRequested] = useState(false);
  const [csvData, setCsvData] = useState(null);
  const [resubmissionErrorType, setResubmissionErrorType] = useState(null);
  const [resubmissionErrorData, setResubmissionErrorData] = useState(null);
  const [transactionId, setTransactionId] = useState(null);

  const { identity } = useIdentityContext();
  const { cimpressADFSUserId } = identity;
  const { environment } = useContext(EnvironmentContext);

  const [successes, setSuccesses] = useState([]);
  const [failures, setFailures] = useState([]);
  const [disableResubmitButton, setDisableResubmitButton] = useState(true);

  const [resubmissionOrderData, setResubmissionOrderData] = useState(null);
  const [mcpMexLink, setMcpMexLink] = useState(null);
  const [isResubmissionComplete, setIsResubmissionComplete] = useState(false);

  const [refreshRequested, setRefreshRequested] = useState(false);
  const [isRefreshComplete, setIsRefreshComplete] = useState(false);

  const currDate = new Date().toLocaleDateString(undefined, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  const addToFailures = failedOrder => {
    failures.push(failedOrder);
    setFailures(failures);
  };
  const addToSuccesses = successOrder => {
    successes.push(successOrder);
    setSuccesses(successes);
  };

  function overwriteSuccesses(orderData) {
    const successfulRefreshes = [];
    for (const order of orderData) {
      if (order.resubmittableOrder && !order.error) {
        successfulRefreshes.push(order);
      }
    }
    setSuccesses(successfulRefreshes);
  }

  const refreshHandler = async () => {
    setIsRefreshComplete(false);
    setRefreshRequested(true);
    setSuccesses([]);
    refresh(environment, transactionId).then(response => {
      overwriteSuccesses(response.orderData);
      setIsRefreshComplete(true);
      setRefreshRequested(false);
    });
  };

  const resubmitDisplay = resubmitOrders => {
    const { resubmittable } = partitionResubmittableOrders(resubmitOrders);
    for (const order of resubmittable) {
      if (!order.error) {
        addToSuccesses(order);
      } else {
        addToFailures(order);
      }
    }

    if (successes && successes.length > 0) {
      const mexUrl = buildMexUrl(environment, successes);
      setMcpMexLink(mexUrl);
    }

    const resubmissionCompletedMetricData = {
      environment,
      transactionId,
      numOrders: resubmitOrders.length,
      numSuccesses: successes.length,
      numFailures: failures.length,
    };
    trackResubmissionComplete(
      resubmissionCompletedMetricData,
      cimpressADFSUserId
    );
    setIsResubmissionComplete(true);
  };

  const resubmissionHandler = async () => {
    setResubmissionRequested(true);
    const { resubmittable, notResubmittable } = partitionResubmittableOrders(
      resubmissionOrderData
    );
    const numOrdersBeingResubmit = resubmittable ? resubmittable.length : 0;
    const numOrdersNotBeingResubmit = notResubmittable
      ? notResubmittable.length
      : 0;
    const resubmissionStartedMetricData = {
      environment,
      transactionId,
      numOrders: numOrdersBeingResubmit,
      numOrdersNotBeingResubmit,
    };
    trackResubmissionStarted(resubmissionStartedMetricData, cimpressADFSUserId);
    resubmit(environment, transactionId).then(response =>
      resubmitDisplay(response.orderData)
    );
  };

  const clearCsvData = () => {
    setCsvData(null);
    setResubmissionRequested(false);
    setResubmissionOrderData(null);
    setTransactionId(null);
    setIsResubmissionComplete(false);
    setRefreshRequested(false);
    setIsRefreshComplete(false);
    setSuccesses([]);
    setFailures([]);
    setResubmissionErrorData(null);
    setResubmissionErrorType(null);
    setMcpMexLink(null);
  };

  const csvDataImportHandler = resubmitCsvDataArray => {
    clearCsvData();
    if (resubmitCsvDataArray) {
      const validationErrors = validateResubmissionRow(resubmitCsvDataArray);
      if (validationErrors && validationErrors.length > 0) {
        clearCsvData();
        setResubmissionErrorData(validationErrors);
        setResubmissionErrorType(ERROR_CSV_DATA_ISSUE);
        const metricData = {
          validationErrors,
          errorType: ERROR_CSV_DATA_ISSUE,
        };
        trackResubmissionCsvIssue(metricData, cimpressADFSUserId);
        return;
      }

      const uniqueOrdersToProcess = removeOrderNumDuplicates(
        resubmitCsvDataArray
      );
      if (
        uniqueOrdersToProcess &&
        uniqueOrdersToProcess.length >= RESUBMIT_ORDERS_LIMIT
      ) {
        clearCsvData();
        setResubmissionErrorType(ERROR_ORDER_LIMIT_EXCEEDED);
        const metricData = {
          errorType: ERROR_ORDER_LIMIT_EXCEEDED,
        };
        trackResubmissionCsvIssue(metricData, cimpressADFSUserId);
        return;
      }

      // all things look good and valid, lets process these orders!
      setCsvData(uniqueOrdersToProcess);
    }
  };

  const csvReaderOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
  };

  // initiate transaction in Mongo and fetch orderData once csvData has been provided
  useEffect(() => {
    if (csvData && environment) {
      initiateResubmit(environment, csvData).then(orderResubmissionData => {
        setResubmissionOrderData(orderResubmissionData.orderData);
        setTransactionId(orderResubmissionData.transactionId);
      });
    }
  }, [csvData, environment]);

  useEffect(() => {
    if (resubmissionOrderData) {
      const { resubmittable } = partitionResubmittableOrders(
        resubmissionOrderData
      );
      setDisableResubmitButton(resubmittable.length === 0);
    }
  }, [resubmissionOrderData]);

  return (
    <BoundedContent>
      <GridContainer>
        <Row>
          <Column>
            <Typography component="h4" mt={8}>
              Order Resubmission CSV file format
            </Typography>
            <Typography>
              The first line of your CSV file must include headers "orderNumber"
              and "remediationReason." Please note that headers are case
              sensitive fields. Later lines in the file should contain data for
              the orders that need to be resubmitted.
            </Typography>
            <Link
              href="/resubmissionCsvExample.csv"
              target="_blank"
              download
              skin="cta"
            >
              Click here to download CSV example
            </Link>
          </Column>
          <Column>
            <Typography component="h4" mt={8}>
              Looking for orders that need resubmission?
            </Typography>
            <Typography>
              Need help identifying exactly which orders need to be resubmitted?
              Take a look at this dashboard which aggregates all orders that are
              stuck/failed/rejected:
            </Typography>
            <Link href={lookerDashboardUrl} target="_blank" skin="cta">
              Looker dashboard
            </Link>
          </Column>
        </Row>
        {csvData ? (
          <>
            <Row mt={8} mb={8}>
              {!resubmissionRequested ? (
                <>
                  {' '}
                  {resubmissionOrderData && (
                    <Column>
                      <Button
                        skin="primary"
                        disabled={disableResubmitButton}
                        onClick={() => resubmissionHandler()}
                      >
                        Resubmit all orders
                      </Button>
                    </Column>
                  )}
                </>
              ) : (
                <Column>
                  {!refreshRequested ? (
                    <Button
                      skin="primary"
                      onClick={() => refreshHandler(successes)}
                    >
                      Refresh Lists
                    </Button>
                  ) : (
                    <div>
                      {!isRefreshComplete && (
                        <GridContainer>
                          <Row mt={6} mr={5}>
                            <Typography component="h4" margin="auto">
                              Refreshing statuses ... This could take a few
                              minutes
                            </Typography>
                          </Row>
                          <Row>
                            <BasicPreloader
                              sizeVariant="large"
                              centered
                              my={6}
                            />
                          </Row>
                        </GridContainer>
                      )}
                    </div>
                  )}

                  {failures && failures.length > 0 && (
                    <CSVExporter
                      headers={['orderNumber', 'remediationReason']}
                      data={failures}
                      exportFile={`${transactionId}_${currDate}.csv`}
                    />
                  )}
                </Column>
              )}
              <Column>
                <Button skin="secondary" onClick={() => clearCsvData()}>
                  Clear all
                </Button>
              </Column>
            </Row>
            {resubmissionRequested ? (
              <>
                {!isResubmissionComplete ? (
                  <GridContainer>
                    <Row mt={6}>
                      <Typography component="h4" margin="auto">
                        Resubmitting Orders ... This could take a few minutes
                      </Typography>
                    </Row>
                    <Row>
                      <BasicPreloader sizeVariant="large" centered my={6} />
                    </Row>
                  </GridContainer>
                ) : (
                  <GridContainer>
                    <Row>
                      <Column>
                        <Typography component="h4">
                          Transaction ID: {transactionId}
                        </Typography>
                        <Typography>
                          We record the results of each resubmission attempt as
                          a transaction.{' '}
                          <NavLink to="/remediationHistory">
                            <Link skin="cta">View past transactions here</Link>
                          </NavLink>
                        </Typography>
                        <Typography>
                          Having trouble? Please contact{' '}
                          <Link
                            href="https://vistaprint.slack.com/archives/CNFFZMUD6"
                            target="_blank"
                          >
                            LochNess Squad
                          </Link>{' '}
                          and provide this transaction ID.
                        </Typography>
                      </Column>
                    </Row>
                  </GridContainer>
                )}
                <Row mt={8}>
                  <Column span={6}>
                    <Typography component="h4">Results:</Typography>
                  </Column>
                  <Column span={6}>
                    <Link
                      href={mcpMexLink}
                      target="_blank"
                      textColor="brand-blue"
                      skin="cta"
                    >
                      View the status of all successfully resubmitted orders in
                      MCP.
                    </Link>
                  </Column>
                </Row>
                <Row mt={1} mb={6}>
                  <Column span={6}>
                    <Collapsible defaultExpanded>
                      <CollapsibleSummary>
                        <CollapsibleSummaryButton>
                          <Typography textColor="error">
                            Failed Resubmit Requests ({failures.length})
                          </Typography>
                        </CollapsibleSummaryButton>
                      </CollapsibleSummary>
                      <CollapsibleContent>
                        <ResubmissionFailureResultsTable
                          failedToResubmitOrders={failures}
                        />
                      </CollapsibleContent>
                    </Collapsible>
                  </Column>
                  <Column span={6}>
                    <Collapsible defaultExpanded>
                      <CollapsibleSummary>
                        <CollapsibleSummaryButton>
                          <Typography textColor="brand-blue">
                            Successful Resubmit Requests ({successes.length})
                          </Typography>
                        </CollapsibleSummaryButton>
                      </CollapsibleSummary>
                      <CollapsibleContent>
                        <ResubmissionSuccessResultsTable
                          successfullyResubmittedOrders={successes}
                        />
                      </CollapsibleContent>
                    </Collapsible>
                  </Column>
                </Row>
              </>
            ) : (
              <Row mb={10}>
                {resubmissionOrderData ? (
                  <ResubmitPreviewTable orders={resubmissionOrderData} />
                ) : (
                  <GridContainer>
                    <Row>
                      <Typography component="h4" margin="auto">
                        Fetching Orders For Resubmission... This could take a
                        few minutes
                      </Typography>
                    </Row>
                    <Row>
                      <BasicPreloader sizeVariant="large" centered my={6} />
                    </Row>
                  </GridContainer>
                )}
              </Row>
            )}
          </>
        ) : (
          <CSVUploader
            csvReaderOptions={csvReaderOptions}
            onFileUpload={csvDataImportHandler}
          />
        )}
        {resubmissionErrorType && (
          <Row mt={5} mb={5}>
            <ErrorPanel
              errorType={resubmissionErrorType}
              errorData={resubmissionErrorData}
            />
          </Row>
        )}
      </GridContainer>
    </BoundedContent>
  );
};

export default OrderResubmissionHome;
