import React, { useContext } from 'react';
import { FlexBox, H2, Icon, Typography } from '@vp/swan';
import { OrderCopyContext } from '../../../../contexts/OrderCopyContext';
import SourceOrderSearch from './SourceOrderSearch';
import SourceOrderCatalog from './SourceOrderCatalog';

const SourceOrderForm = () => {
  const { resetSourceOrder } = useContext(OrderCopyContext);

  const handleReset = () => {
    resetSourceOrder();
  };

  return (
    <>
      <FlexBox justifyContent="space-between" mb={2}>
        <H2 fontSize={3}>Select a source order</H2>
        <Icon
          iconType="reset"
          size="32p"
          skin="standard"
          alt="Reset source order"
          onClick={handleReset}
        />
      </FlexBox>

      <SourceOrderSearch />

      <Typography mt={2} align="center">
        or
      </Typography>

      <SourceOrderCatalog />
    </>
  );
};

export default SourceOrderForm;
