import axios from 'axios';
import { buildStandardHeaders } from '../../../utils/serviceHelpers';

export default async function getPermissions(auth) {
  const orderManagementResourceType = 'order-management';
  const McpResourceType = 'merchants/vistaprint';
  const baseUrl = 'https://api.cimpress.io/auth/access-management/v1';
  const canonicalId = auth.getCanonicalId();
  const authorizationHeader = auth.getAuthorizationHeader();
  const permissions = {};

  await axios
    .get(
      `${baseUrl}/principals/${canonicalId}/permissions/${orderManagementResourceType}`,
      {
        headers: buildStandardHeaders(authorizationHeader),
      }
    )
    .then(response => (permissions.orderManagement = response.data));

  await axios
    .get(
      `${baseUrl}/principals/${canonicalId}/permissions/${McpResourceType}`,
      {
        headers: buildStandardHeaders(authorizationHeader),
      }
    )
    .then(response => (permissions.mcp = response.data.permissions));

  return permissions;
}
