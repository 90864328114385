import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import VistaprintAuth from '@vp/auth';
import getPermissions from '../components/auth/permissions/getPermissions';

const IdentityContext = React.createContext();
const DEFAULT_LOCALE = 'en-US';

// https://gitlab.com/vistaprint-org/technology-operations/web-platform/warhol/identity-product-line/vistaprint-auth-js/-/tree/master/auth
const redirectToOriginLocationAfterSignout = () =>
  window.location.href.toString();

export const AUTH_CONFIG = {
  culture: DEFAULT_LOCALE,
  options: {
    connection: 'vistaprintcom-waad',
    redirectAfterSignOutCallback: redirectToOriginLocationAfterSignout,
  },
  developmentMode: { clientID: 'WlovdIQc4auVgTkaFmxtgM9mVo5MvJsI' },
};

const IdentityProvider = props => {
  const { children } = props;
  const [auth, setAuthState] = useState(undefined);
  const [isIdentityInitialized, setIdentityInitialized] = useState(false);
  const [identity, setIdentity] = useState(undefined);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    VistaprintAuth.init(AUTH_CONFIG, () => {
      const authLibrary = new VistaprintAuth.WebAuth();
      setAuthState(authLibrary);
    });
  }, []);

  /**
   * Only attach this event handler once the auth library becomes available.
   * Since auth library is set in a run-once effect (above) this will only run a maximum once.
   */
  useEffect(() => {
    if (!auth) return;

    setIdentity(auth.getIdentity());

    auth.onUserIdentityUpdate(async newIdentity => {
      if (!newIdentity) {
        return;
      }

      // Once identity is initialized, fetch the permissions for that identity
      let fetchedPermissions = [];
      try {
        fetchedPermissions = await getPermissions(auth);
      } catch (err) {
        console.error(err);
      }

      setPermissions(fetchedPermissions);
      setIdentity(newIdentity);
      setIdentityInitialized(true);
    });
  }, [auth]);

  const identityContextValues = useMemo(
    () => ({
      isIdentityInitialized,
      identity,
      auth,
      permissions,
    }),
    [auth, identity, isIdentityInitialized, permissions]
  );

  return (
    <IdentityContext.Provider value={identityContextValues}>
      {children}
    </IdentityContext.Provider>
  );
};

IdentityProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export { IdentityContext, IdentityProvider };
