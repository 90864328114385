/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useContext, useEffect, useState } from 'react';
import {
  AlertBox,
  BasicPreloader,
  BoundedContent,
  Card,
  Column,
  ControlIcon,
  Divider,
  GridContainer,
  Row,
  TabContent,
  TabHeader,
  Tabs,
  TabsContents,
  TabsHeaders,
  Typography,
} from '@vp/swan';
import { Button, Icon } from '@vp/swan';
import { Link as RouterLink } from '@reach/router';
import useIdentityContext from '../../../../hooks/useIdentityContext';
import { EnvironmentContext } from '../../../../contexts/EnvironmentContext';
import { OrderConsoleContext } from '../../../../contexts/OrderConsoleContext';

import { orderDataFetch } from '../../../../api/daliService';
import {
  fetchIncidentsByOrderNumber,
  fetchFailuresByOrderNumber,
} from '../../../../api/ciaService';
import { lineItemStateChangeEventsFetch } from '../../../../api/orderEventHistoryService';
import LineItemDisplayCard from '../item/LineItemDisplayCard';
import IncidentsDisplayCard from '../IncidentsDisplayCard';
import { OrderOverview } from './OrderOverview';
import CustomerDetails from './customerdetails/CustomerDetails';
import OrderPricingDisplay from './OrderPricingDisplay';
import hasIncidentReadPermissions from '../../../auth/permissions/hasIncidentReadPermissions';
import { ERROR, HydrationError, MISSING_PERMISSIONS } from '../HydrationError';
import hasEventReadPermissions from '../../../auth/permissions/hasEventReadPermissions';

const OrderConsole = props => {
  const { identity } = useIdentityContext();
  const { isSignedIn, permissions } = identity;
  const { environment } = useContext(EnvironmentContext);
  const [includeTax, setIncludeTax] = useState(true);
  // Flag so order is only loaded once
  const [orderLoadInitiated, setOrderLoadInitiated] = useState(false);
  const [orderNumber] = useState(props.orderNumber);
  const [order, setOrder] = useState(undefined);
  const [incidents, setIncidents] = useState(undefined);
  const [failures, setFailures] = useState(undefined);
  const [stateChangeEvents, setStateChangeEvents] = useState(undefined);
  const [orderFetchError, setOrderFetchError] = useState(false);
  const [ciaHydrationError, setCiaHydrationError] = useState(undefined);
  const [oehHydrationError, setOehHydrationError] = useState(undefined);

  useEffect(() => {
    const loadOrderData = async () => {
      try {
        const orderData = await orderDataFetch(environment, orderNumber);
        setOrder(orderData);
        setOrderFetchError(false);
      } catch (err) {
        setOrderFetchError(true);
        console.log('[Order Data Fetch Error] ', err);
      }
    };

    const loadFailures = async () => {
      if (hasIncidentReadPermissions(permissions, environment)) {
        try {
          const failureData = await fetchFailuresByOrderNumber(
            environment,
            orderNumber
          );
          setFailures(failureData);
          const incidentsData = await fetchIncidentsByOrderNumber(
            environment,
            orderNumber
          );
          setIncidents(incidentsData);
          setCiaHydrationError(undefined);
        } catch (err) {
          setCiaHydrationError(ERROR);
          console.log('[CIA hydration Error] ', err);
        }
      } else {
        setCiaHydrationError(MISSING_PERMISSIONS);
        console.log('[CIA Missing Permissions] ', permissions);
      }
    };
    const loadLineItemStateChangeEvents = async () => {
      if (hasEventReadPermissions(permissions, environment)) {
        try {
          const events = await lineItemStateChangeEventsFetch(
            environment,
            orderNumber
          );
          setStateChangeEvents(events);
          setOehHydrationError(undefined);
        } catch (err) {
          setOehHydrationError(ERROR);
          console.log('[OEH State Change Events Fetch] ', err);
        }
      } else {
        setOehHydrationError(MISSING_PERMISSIONS);
        console.log('[OEH Missing Permissions] ', permissions);
      }
    };

    if (isSignedIn && orderNumber && !orderLoadInitiated) {
      setOrderLoadInitiated(true);
      loadOrderData();
      loadFailures();
      loadLineItemStateChangeEvents();
    }
  }, [environment, isSignedIn, orderLoadInitiated, orderNumber, permissions]);

  const incidentsExist = incidents && incidents.length > 0;
  const hydrationError = !!(oehHydrationError || ciaHydrationError);

  return (
    <>
      {!orderFetchError &&
        (isSignedIn && order ? (
          <OrderConsoleContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
              order,
              setOrder,
              includeTax,
              setIncludeTax,
              incidents,
              setIncidents,
              failures,
              setFailures,
              stateChangeEvents,
              setStateChangeEvents,
            }}
          >
            <BoundedContent>
              <GridContainer>
                <Row mt={7} mb={5}>
                  <Column span={10}>
                    <Typography component="h2">{order.orderNumber}</Typography>
                  </Column>
                  <Column span={2}>
                    <RouterLink
                      to={`/orderCopy/${environment}/${orderNumber}`}
                      style={{ width: '75%', textDecoration: 'none' }}
                    >
                      <Button skin="primary">
                        Copy order
                        <Icon
                          iconType="saveAsCopy"
                          size="32p"
                          skin="standard"
                        />
                      </Button>
                    </RouterLink>
                  </Column>
                </Row>
                {hydrationError && (
                  <HydrationError
                    oehHydrationError={oehHydrationError}
                    ciaHydrationError={ciaHydrationError}
                  />
                )}
                <Typography textSize="3">Order</Typography>
                <Divider mb={4} mt={2} />
                <Tabs defaultSelectedTabId="orderOverview">
                  <TabsHeaders>
                    <TabHeader tabId="orderOverview"> Overview</TabHeader>
                    <TabHeader tabId="customerDetails">
                      {' '}
                      Shipping & Billing
                    </TabHeader>
                    <TabHeader tabId="paymentDetails">Pricing</TabHeader>
                    {incidentsExist && (
                      <TabHeader tabId="ciaIncidents">
                        <Typography textColor="error">
                          CIA Incidents
                          <ControlIcon iconType="error" />
                        </Typography>
                      </TabHeader>
                    )}
                  </TabsHeaders>
                  <TabsContents>
                    <TabContent tabId="orderOverview">
                      <OrderOverview />
                    </TabContent>
                    <TabContent tabId="customerDetails">
                      <CustomerDetails />
                    </TabContent>
                    <TabContent tabId="paymentDetails">
                      <OrderPricingDisplay />
                    </TabContent>
                    <TabContent tabId="ciaIncidents">
                      <IncidentsDisplayCard />
                    </TabContent>
                  </TabsContents>
                </Tabs>
                <Typography textSize="3">Items</Typography>
                <Divider mb={4} mt={2} />
                {order.lineItems.map(item => (
                  <LineItemDisplayCard lineItem={item} />
                ))}
              </GridContainer>
            </BoundedContent>
          </OrderConsoleContext.Provider>
        ) : (
          <BasicPreloader sizeVariant="large" centered my={6} />
        ))}
      {orderFetchError && (
        <Card align="center">
          <Typography textSize={2} my={5}>
            Unable to fetch {orderNumber}
          </Typography>
          <img
            src="https://cms.cloudinary.vpsvc.com/image/upload/v1652889525/order_management/sort-ui/load_error.svg"
            alt="icon"
          />
          <AlertBox skin="error">
            <Typography>
              An error occurred. Please verify order number is valid for
              environment: {environment}
            </Typography>
          </AlertBox>
        </Card>
      )}
    </>
  );
};

export default OrderConsole;
