/* eslint-disable no-unused-vars */
import React from 'react';

export const OrderTimelineContext = React.createContext({
  orderNumber: undefined,
  setOrderNumber: orderNumber => {},
  visualizationData: undefined,
  setVisualizationData: visualizationData => {},
  isLoading: false,
  setIsLoading: isLoading => {},
  selectedTimelineItems: undefined,
  setSelectedTimelineItems: selectedTimelineItems => {},
  iconsOnly: false,
  setIconsOnly: iconsOnly => {},
  searchOrderNumber: undefined,
  setSearchOrderNumber: searchOrderNumber => {},
  selectedMilestones: [],
  setSelectedMilestones: selectedMilestones => {},
  searchObjectFilter: 'justMilestones',
  setSearchObjectFilter: searchObjectFilter => {},
  milestones: [],
  setMilestones: milestones => {},
  invalidOrderNumber: false,
  setInvalidOrderNumber: invalidOrderNumber => {},
  searchInProgress: false,
  setSearchInProgress: searchInProgress => {},
  timelineItems: [],
  setTimelineItems: timelineItems => {},
});
