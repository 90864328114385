import axios from 'axios';
import { buildStandardHeaders } from '../utils/serviceHelpers';
import { getAuthorizationHeaderAndShopperId } from '../components/auth/auth';

const SHOPPER_POOL_ID = '4HVsAccqLzE6BPbmvrDaKw';
const ACCOUNT_ID = 'ozoDdrmewShEcbUDWX8J3V';

const PROFILE_SVC_BASE_URL = 'https://profile.cimpress.io';
const PROFILE_QUERY = `/v1/${ACCOUNT_ID}/${SHOPPER_POOL_ID}/profile/search?`;

/**
 * Extract useful fields from API profile objects.
 * @param {*} data API data from profile service query.
 * @returns
 */
const processProfiles = data => {
  if (!data || !data._embedded || !data._embedded.profiles) {
    return [];
  }
  const rtnArray = data._embedded.profiles.map(p => {
    const miniProfile = {};
    miniProfile.shopperId = p.canonicalId;
    miniProfile.email = p.email;
    miniProfile.firstName = p.firstName;
    miniProfile.lastName = p.lastName;
    miniProfile.locale = p.lastLoginLocale;
    return miniProfile;
  });

  return rtnArray;
};

const ensureRequestWasSuccessfulOrThrow = response => {
  if (response && response.status === 200) {
    // noice!
  } else {
    throw new Error('request failed');
  }
};

/**
 * Common API call to Profile.
 * @param {*} queryArray array of name/value objects
 * @returns array of results
 */
const internalProfileCall = queryString => {
  const { idToken } = getAuthorizationHeaderAndShopperId();
  const baseUrl = PROFILE_SVC_BASE_URL;

  const axiosInstance = axios.create({
    headers: buildStandardHeaders(idToken),
    baseURL: baseUrl,
  });

  const url = PROFILE_QUERY + queryString;
  console.log(`Profile search: ${JSON.stringify(url)}`);

  return axiosInstance
    .get(url)
    .then(response => {
      ensureRequestWasSuccessfulOrThrow(response);
      const arr = processProfiles(response.data);
      return arr;
    })
    .catch(err => {
      throw err;
    });
};

/**
 * Public method.
 * Get any profile entries based on first AND last name, AND/OR email.
 * Both name parameters must be present to be used.
 *
 * Example query string for profile service:
 *
 *    q=lastName%3Asmith&offset=0&limit=20&fuzzySearch=false
 *
 * @param {string} firstName first name on account.
 * @param {string} lastName last name on account.
 * @param {string} email email on the account.
 * @returns Array of 'mini' profiles.
 */
export const getProfiles = (firstName, lastName, email) => {
  // Build the query pieces.
  if (!firstName || !lastName) {
    if (!email) {
      return [];
    }
  }

  let nameQuery = '';
  let emailQuery = '';
  const querySuffix = '&offset=0&limit=20&fuzzySearch=false';

  // Note: '%2A' adds wild card to the end. '%20' are space characters required by syntax.
  if (firstName && lastName) {
    nameQuery = `firstName%3A${firstName}%2A%20AND%20lastName%3A${lastName}%2A`;
  }
  if (email) {
    emailQuery = `email%3A%2A${email}%2A`;
  }

  // Build the full query string.  3 possible choices: name, email, or name+email.
  let queryString = nameQuery;
  if (emailQuery) {
    if (nameQuery) {
      queryString = `${nameQuery}%20AND%20${emailQuery}`;
    } else {
      queryString = emailQuery;
    }
  }
  queryString = `q=${queryString}${querySuffix}`;

  return internalProfileCall(queryString);
};
