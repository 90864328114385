import dayjs from 'dayjs';

/**
 * Returns a Day.js object so it can be properly compared by sort()
 * Returns undefined if the given date is empty/undefined or in a format incompatible with Day.js
 * @param {(string|number)} date - a date string returned on an order ('2021-08-05') or a date number in milliseconds (1628136000000)
 */
const convertDate = date => {
  if (date === null || date === '' || date === undefined) {
    return undefined;
  }

  // Convert given date to Day.js object
  const dayjsDate = dayjs(date);

  // Return undefined if the date is invalid, which happens when Day.js is given an incompatible value (i.e. "doggo", "thefirstofaugust")
  if (!dayjsDate.isValid()) {
    return undefined;
  }

  return dayjsDate;
};

/**
 * Returns true if the given date occurs in the past
 * @param {Object} date - a Day.js object
 */
const isDateInPast = date => {
  const today = dayjs();
  if (date.isBefore(today, 'day')) {
    return true;
  }

  return false;
};

/**
 * Returns the promised arrival date of the arg
 * @param { Object[] } arg - an item container, line item, or item group
 */
const getPromisedArrivalDate = arg => {
  if (!arg) {
    return undefined;
  }

  // If the arg is an item container with an item group, return the latest promised arrival date of all the line items in the item group
  if (arg.groupId && arg.lineItems && arg.lineItems.length > 0) {
    // Math.max() will return the date in milliseconds, so we wrap it with convertDate() to get a Date object
    const latestArrivalDate = convertDate(
      Math.max(...arg.lineItems.map(e => convertDate(e.promisedArrivalDate)))
    );
    return latestArrivalDate;
  }

  // If the arg is an item container with a line item, return its promised arrival date
  if (arg.lineItems) {
    return convertDate(arg.lineItems[0].promisedArrivalDate);
  }

  // If the arg is a line item, return its promised arrival date
  if (arg.promisedArrivalDate) {
    return convertDate(arg.promisedArrivalDate);
  }
  // If we can't find the promised arrival date, return undefined
  return undefined;
};

/**
 * Sorts args by earliest to latest promised arrival date, placing args with unknown arrival dates at the end of the list
 * @param { Object[] } args - a list of item containers, line items, and/or item groups
 * @param { boolean } filterOutPastArrivalDates - if true, places line items with arrival dates in the past at the end of the list
 */
const sortByArrivalDate = (args, filterOutPastArrivalDates) => {
  if (!args || args.length === 0) {
    return [];
  }

  // The sort() function below will not run if the list has only a single element.
  // This is problematic if that single element contains nested line items that need sorting, such as an item group!
  // So, if the single element contains an item group, recursively run this function to sort those nested line items.
  if (args.length === 1 && args[0].groupId) {
    sortByArrivalDate(args[0].lineItems);
  }

  return [
    ...args.sort((a, b) => {
      // Recursively run this function to sort nested line items inside item groups
      if (a.groupId) {
        sortByArrivalDate(a.lineItems);
      }
      // Recursively run this function to sort nested line items inside item groups
      if (b.groupId) {
        sortByArrivalDate(b.lineItems);
      }

      const dateA = getPromisedArrivalDate(a);
      const dateB = getPromisedArrivalDate(b);

      // Place elements with undefined/missing dates at the end of the list
      // If enabled, also place elements with dates in the past at the end of the list
      if (
        dateA === undefined ||
        (filterOutPastArrivalDates && isDateInPast(dateA))
      ) {
        return 1;
      }
      if (
        dateB === undefined ||
        (filterOutPastArrivalDates && isDateInPast(dateB))
      ) {
        return -1;
      }
      return dateA - dateB;
    }),
  ];
};

/**
 * Returns the earliest and latest known promised arrival date among the given line items as Day.js objects
 * If no items contain a promised arrival date, returns undefined
 * @param { Object[] } lineItems - the list of line item objects as returned on an order by vistacart
 */
export const getPromisedArrivalDateRange = lineItems => {
  if (!lineItems || lineItems.length === 0) {
    return undefined;
  }

  const sortedLineItems = sortByArrivalDate(lineItems);

  const earliestArrivingLineItem = sortedLineItems.find(
    item => getPromisedArrivalDate(item) !== undefined
  );

  const reverseSortedLineItems = sortedLineItems.reverse();
  const latestArrivingLineItem = reverseSortedLineItems.find(
    item => getPromisedArrivalDate(item) !== undefined
  );

  const earliestArrivalDate = getPromisedArrivalDate(earliestArrivingLineItem);
  const latestArrivalDate = getPromisedArrivalDate(latestArrivingLineItem);

  if (!earliestArrivalDate || !latestArrivalDate) {
    return undefined;
  }

  return { earliestArrivalDate, latestArrivalDate };
};
