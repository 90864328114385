import {
  Column,
  FormField,
  FormLabel,
  Row,
  SelectionSet,
  SelectionSetInput,
  SelectionSetLabel,
} from '@vp/swan';
import React, { useContext } from 'react';
import { OrderTimelineContext } from '../../../contexts/OrderTimelineContext';

const MilestoneFilter = () => {
  const { setSelectedMilestones, searchObjectFilter, milestones } = useContext(
    OrderTimelineContext
  );

  return (
    <div>
      {searchObjectFilter === 'justMilestones' && (
        <Row component={FormField} mb={3}>
          <Column span={3}>
            <FormLabel>Milestones to filter:</FormLabel>
          </Column>
          <Column span={9}>
            <SelectionSet
              variant="multi-select"
              skin="buttons"
              onSelectedValuesChange={newSelectedValue => {
                setSelectedMilestones(newSelectedValue);
              }}
            >
              {milestones.map(milestone => (
                <SelectionSetInput value={milestone.name}>
                  <SelectionSetLabel>{milestone.displayName}</SelectionSetLabel>
                </SelectionSetInput>
              ))}
            </SelectionSet>
          </Column>
        </Row>
      )}
    </div>
  );
};

export default MilestoneFilter;
