import React, { useContext, useEffect, useState } from 'react';
import {
  BasicPreloader,
  BoundedContent,
  Column,
  GridContainer,
  Row,
  Button,
  Typography,
} from '@vp/swan';
import { Link as RouterLink } from '@reach/router';
import TimelineFilters from './TimelineFilters';
import { OrderTimelineContext } from '../../../contexts/OrderTimelineContext';
import useIdentityContext from '../../../hooks/useIdentityContext';
import OrderTimelineViz from './OrderTimelineViz';
import { orderJourneyFetch } from '../../../api/backendOrderRemediationToolService';
import { EnvironmentContext } from '../../../contexts/EnvironmentContext';
import {
  trackVoyagerOrderSearch,
  trackVoyagerOrderSearchError,
} from '../../../api/newRelicTrackingApi';
import SimpleTimeline from './SimpleTimeline';

const OrderTimelineHome = props => {
  const { identity } = useIdentityContext();
  const { cimpressADFSUserId, isSignedIn } = identity;
  const { environment } = useContext(EnvironmentContext);
  // Flag so order is only loaded once
  const [orderLoadInitiated, setOrderLoadInitiated] = useState(false);

  // eslint-disable-next-line react/destructuring-assignment
  const [orderNumber, setOrderNumber] = useState(props.orderNumber);
  const [visualizationData, setVisualizationData] = useState(undefined);
  const [isLoading, setIsLoading] = useState(undefined);
  const [selectedTimelineItems, setSelectedTimelineItems] = useState([]);
  const [iconsOnly, setIconsOnly] = useState(false);
  const [searchOrderNumber, setSearchOrderNumber] = useState(undefined);
  const [selectedMilestones, setSelectedMilestones] = useState([]);
  const [searchObjectFilter, setSearchObjectFilter] = useState(
    'justMilestones'
  );
  const [orderMilestones, setOrderMilestones] = useState([]);
  const [milestones, setMilestones] = useState([]);
  const [invalidOrderNumber, setInvalidOrderNumber] = useState(false);
  const [timelineItems, setTimelineItems] = useState([]);

  useEffect(() => {
    const loadOrderData = async () => {
      setInvalidOrderNumber(false);
      setIsLoading(true);
      setSearchOrderNumber(orderNumber);

      try {
        const orderVizData = await orderJourneyFetch(
          environment,
          orderNumber,
          [],
          searchObjectFilter
        );

        setTimelineItems(orderVizData.groups);
        setOrderMilestones(orderVizData.milestones);
        setIconsOnly(iconsOnly);
        setVisualizationData(orderVizData);

        // New Relic Tracking
        const voyagerSearchSuccessMetricData = {
          environment,
          searchOrderNumber,
          searchObjectFilter,
        };
        trackVoyagerOrderSearch(
          voyagerSearchSuccessMetricData,
          cimpressADFSUserId
        );
      } catch (err) {
        setInvalidOrderNumber(true);
        setTimelineItems([]);
        setOrderMilestones([]);
        setIconsOnly(iconsOnly);
        setVisualizationData(undefined);

        // New Relic Tracking
        const voyagerSearchErrorMetricData = {
          environment,
          searchOrderNumber,
          searchObjectFilter,
        };
        trackVoyagerOrderSearchError(
          err,
          voyagerSearchErrorMetricData,
          cimpressADFSUserId
        );
      }
      setIsLoading(false);
    };
    if (isSignedIn && orderNumber && !orderLoadInitiated) {
      setOrderLoadInitiated(true);
      loadOrderData();
    }
  }, [
    isSignedIn,
    orderNumber,
    orderLoadInitiated,
    environment,
    searchObjectFilter,
    iconsOnly,
    searchOrderNumber,
    cimpressADFSUserId,
  ]);

  return isSignedIn ? (
    <OrderTimelineContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        orderNumber,
        setOrderNumber,
        visualizationData,
        setVisualizationData,
        isLoading,
        setIsLoading,
        selectedTimelineItems,
        setSelectedTimelineItems,
        iconsOnly,
        setIconsOnly,
        searchOrderNumber,
        setSearchOrderNumber,
        selectedMilestones,
        setSelectedMilestones,
        searchObjectFilter,
        setSearchObjectFilter,
        orderMilestones,
        setOrderMilestones,
        milestones,
        setMilestones,
        invalidOrderNumber,
        setInvalidOrderNumber,
        timelineItems,
        setTimelineItems,
      }}
    >
      <BoundedContent>
        <GridContainer>
          <Row>
            <Column>
              <Typography component="h2" mt={4}>
                Order Voyager {orderNumber && <> - {orderNumber}</>}
              </Typography>
            </Column>
          </Row>
          {orderNumber && (
            <Row mt={5}>
              <Column>
                <Button widthVariant="full-width">
                  <RouterLink
                    component="button"
                    to={`/orderConsole/${environment}/${orderNumber}`}
                  >
                    View Order Details Console
                  </RouterLink>
                </Button>
              </Column>
            </Row>
          )}
          <Row>
            <Column span={12}>
              <TimelineFilters />
            </Column>
          </Row>
        </GridContainer>
      </BoundedContent>
      <GridContainer>
        <Row mb={6} mt={6}>
          <Column span={10} offset={1}>
            <OrderTimelineViz
              visualizationData={visualizationData}
              iconsOnly={iconsOnly}
            />
          </Column>
        </Row>
        <Row mb={6} mt={6}>
          <Column span={10} offset={1}>
            <SimpleTimeline visualizationData={visualizationData} />
          </Column>
        </Row>
      </GridContainer>
    </OrderTimelineContext.Provider>
  ) : (
    <BasicPreloader sizeVariant="large" centered my={6} />
  );
};
export default OrderTimelineHome;
